/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Loader from 'src/assets/imgs/loader.gif';
import localStorageService from '../../services/local-storage/localStorage.service';
import ContainerBox from '../../components/ui/containerBox';
import Calendar from '../../components/ui/calendar/calendar';
import AdminCalendarBoard from '../../components/calendar/admin-calendar-board/adminCalendarBoard';
import AdminRecordEditor from '../../components/calendar/admin-record-editor/adminRecordEditor';
import dictionary from '../../utils/dictionary/dictionary';
import {
  useGetStaffQuery,
  useGetUserByIdQuery,
} from 'src/services/users/users.services';
import { useAppSelector } from 'src/hooks/api';
import { DAYS_CATEGORY } from 'src/components/calendar/admin-calendar-board/constants';
import { transformDate } from 'src/utils/date/transformDate';
import { Filter } from '../../components/calendar/filter/filter';
import { User } from 'src/services/users/types';
import Modal from 'src/components/calendar/common/modal/modal';
import UserModalContent from 'src/components/calendar/user-modal-content/userModalContent';
import { useLazyGetRecordsQuery } from 'src/services/records/records.service';

const AdminCalendarPage: React.FC = () => {
  const navigate = useNavigate();
  const userId = localStorageService.getUserId();
  const [triggerForRecords] = useLazyGetRecordsQuery();

  if (!userId) {
    navigate('/crm/login');
  }
  const selectedLanguage = useAppSelector((state) => state.lang.lang);
  const [calendarDate, setCalendarDate] = useState(
    new Date(localStorageService.getCalendarDate()),
  );
  const [users, setUsers] = useState<User[]>([]);
  const [isUserModalVisible, setIsUserModalVisible] = useState(false);
  const [displayedUserId, setDisplayedUserId] = useState<number>();
  const { data: usersData, isLoading: isUsersLoading } = useGetStaffQuery();
  const { selectedService } = useAppSelector((state) => state.services);

  useEffect(() => {
    if (usersData) {
      const displayedUsersIds = localStorageService.getDisplayedUserIds();

      const filteredUsers = displayedUsersIds.length
        ? usersData.filter((user) => displayedUsersIds.includes(user.id))
        : usersData;

      if (displayedUsersIds.length) {
        filteredUsers.sort((userA, userB) => {
          return (
            displayedUsersIds.indexOf(userA.id) -
            displayedUsersIds.indexOf(userB.id)
          );
        });
      }

      setUsers(
        selectedService
          ? filteredUsers.filter(
            (user) =>
              user.services.includes(selectedService.id) ||
                DAYS_CATEGORY.includes(selectedService.en),
          )
          : filteredUsers,
      );
    }
  }, [usersData, selectedService]);

  const { data: userData } = useGetUserByIdQuery(Number(userId));
  const user = useMemo(() => {
    if (userData) {
      return userData;
    }
  }, [userData]);

  const setDate = (date: Date) => {
    setCalendarDate(date);
    localStorageService.setCalendarDate(transformDate(date));
  };

  useEffect(() => {
    if (user?.isAdmin) {
      const timeoutId = setTimeout(() => {
        triggerForRecords();
      }, 9000);

      return () => clearTimeout(timeoutId);
    }
  }, [triggerForRecords, user]);

  if (isUsersLoading) {
    return (
      <div className="flex w-full items-center justify-center pt-20">
        <img src={Loader} width={70} height={70} />
      </div>
    );
  }

  if (user) {
    if (user?.isStaff || user?.isAdmin) {
      return (
        <>
          <div className="relative mx-auto flex h-[calc(100vh-252px)] items-start justify-center bg-cream text-lightBrown max-md:text-sm">
            <ContainerBox>
              <h2 className="text-xl">
                {dictionary[selectedLanguage].calendar}
              </h2>

              <div className="flex items-center gap-x-4">
                <Calendar currentDate={calendarDate} onSelectDate={setDate} />

                <Filter setUsers={(users: User[]) => setUsers(users)} />
              </div>

              <div className="mt-[10px] max-md:flex  max-md:flex-col md:grid md:grid-cols-5">
                <div className="col-span-4 mr-[6px] flex rounded-lg border border-gray">
                  <AdminCalendarBoard
                    date={calendarDate}
                    users={users}
                    openUserModal={(id: number) => {
                      setDisplayedUserId(id);
                      setIsUserModalVisible(true);
                    }}
                  />
                </div>
                <div className="relative flex w-full flex-col max-md:-order-1 max-md:mb-[10px]">
                  <AdminRecordEditor currentUser={user} />
                </div>
              </div>
            </ContainerBox>
            <ToastContainer
              position="top-right"
              autoClose={2000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
          </div>
          <Modal
            isVisible={isUserModalVisible && user.isAdmin}
            onClose={() => setIsUserModalVisible(false)}
          >
            <UserModalContent
              id={displayedUserId}
              onClose={() => setIsUserModalVisible(false)}
            />
          </Modal>
        </>
      );
    } else {
      navigate('/crm/login');
    }
  }
};

export default AdminCalendarPage;
