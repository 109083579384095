import React from 'react';
import { Client } from 'src/services/clients/types';
import { User } from 'src/services/users/types';
import telegramLogo from 'src/assets/imgs/telegram.png';
import phoneLogo from 'src/assets/imgs/Vector.png';

interface Props {
  buttonTitle: string;
  user: User;
  client?: Client;
  onButtonPress: () => void;
}

const ClientInfo: React.FC<Props> = ({
  buttonTitle,
  user,
  client,
  onButtonPress,
}) => (
  <div className="flex">
    <img
      className="mr-[10px] size-[200px] rounded-full max-md:size-[100px]"
      src={client?.image}
      alt="avatar"
    />
    <div className="flex flex-col">
      <p className="text-3xl">{client?.name ?? ''}</p>
      {user.isAdmin
        ? (
        <>
          <div>
            <a
              className="flex items-center"
              href={`http://www.t.me/${client?.telegram}`}
            >
              <img className="mr-[6px] size-[16px]" src={telegramLogo} alt="" />
              <p>{client?.telegram ?? ''}</p>
            </a>
          </div>
          <div className="mb-[8px] flex items-center">
            <img className="mr-[6px] size-[16px]" src={phoneLogo} alt="" />
            <p>
              <a href={`tel:${client?.phone}`}>{client?.phone ?? ''}</a>
            </p>
          </div>
          <button
            className="max-h-[32px] max-w-[100px] rounded-lg border border-lightBrown p-[2px] text-sm"
            onClick={onButtonPress}
          >
            {buttonTitle}
          </button>
        </>
          )
        : null}
    </div>
  </div>
);

export default ClientInfo;
