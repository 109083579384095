/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import apiEndpoint from '../constants';
import { AvailableRecord, NewComplex, NewComplexWithRegister, NewRecord, NewRecordWithRegister, RecordApi, UpdateImage, UpdateRecord } from './types';

export const recordsApi = createApi({
  reducerPath: 'recordsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiEndpoint}/api/records`,
  }),
  tagTypes: ['AvailableCrm'],
  endpoints: (builder) => ({
    getRecords: builder.query<RecordApi[], void>({
      query: () => ''
    }),
    getRecordsByDate: builder.query<RecordApi[], string>({
      query: (date) => `by-date/${date}`
    }),
    getAvailableCrm: builder.query<AvailableRecord[], { userId: number, date: string }>({
      query: ({ userId, date }) => `get-available-crm/${userId}/${userId}/${date}`,
      providesTags: (result, error, { userId, date }) => [{ type: 'AvailableCrm', userId, date }]
    }),
    deleteBreak: builder.query<null, { recordId: number }>({
      query: ({ recordId }) => `delete-break/${recordId}`
    }),
    createNewRecord: builder.mutation<null, NewRecord>({
      query: (record) => ({
        url: '',
        method: 'POST',
        body: record
      }),
      invalidatesTags: (result, error, { userId, date }) => [{ type: 'AvailableCrm', userId, date }],
    }),
    createNewRecordWithRegister: builder.mutation<null, NewRecordWithRegister>({
      query: (record) => ({
        url: 'register-and-record',
        method: 'POST',
        body: record
      }),
      invalidatesTags: (result, error, { userId, date }) => [{ type: 'AvailableCrm', userId, date }],
    }),
    createNewComplex: builder.mutation<null, { data: NewComplex, serviceInfo: { userId: number | undefined, date: string }[] }>({
      query: (complex) => ({
        url: 'complex',
        method: 'POST',
        body: complex.data
      }),
      invalidatesTags: (result, error, { serviceInfo }) => serviceInfo.map(({ userId, date }) => ({ type: 'AvailableCrm', userId, date })),
    }),
    createNewComplexWithRegister: builder.mutation<null, { data: NewComplexWithRegister, serviceInfo: { userId: number | undefined, date: string }[] } >({
      query: (complex) => ({
        url: 'complex-with-register',
        method: 'POST',
        body: complex.data
      }),
      invalidatesTags: (result, error, { serviceInfo }) => serviceInfo.map(({ userId, date }) => ({ type: 'AvailableCrm', userId, date })),
    }),
    updateRecord: builder.mutation<null, UpdateRecord>({
      query: (record) => ({
        url: `record/${record.recordId}`,
        method: 'PATCH',
        body: record
      }),
      invalidatesTags: (result, error, { userId, date }) => [{ type: 'AvailableCrm', userId, date }],
    }),
    uploadRecordImage: builder.mutation<null, UpdateImage>({
      query: (data) => ({
        url: `image/${data.recordId}`,
        method: 'PATCH',
        body: data.formData
      })
    })
  })
});

export const {
  useGetRecordsQuery,
  useLazyGetRecordsQuery,
  useGetRecordsByDateQuery,
  useGetAvailableCrmQuery,
  useDeleteBreakQuery,
  useCreateNewRecordMutation,
  useCreateNewRecordWithRegisterMutation,
  useCreateNewComplexMutation,
  useCreateNewComplexWithRegisterMutation,
  useUpdateRecordMutation,
  useUploadRecordImageMutation,
} = recordsApi;
