import { Columns } from './types';

export const getColumnClasses = (key: Columns) => {
  const classes = {
    [Columns.MASTER]: 'max-md:col-span-2 max-md:p-[6px]',
    [Columns.SERVICE]: 'max-md:col-span-2 max-md:p-[6px]',
    [Columns.PRICE]: 'max-md:col-span-3 max-md:p-[6px] max-md:text-center',
    [Columns.TIME]: 'max-md:col-span-2 max-md:p-[6px]',
    [Columns.PHOTO]: 'max-md:p-[6px]',
  };

  return classes[key] || 'max-md:p-[6px]';
};
