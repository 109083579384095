import React, { useState } from 'react';
import { useAppSelector } from 'src/hooks/api';
import { useGetClientsHistoryByIdQuery } from 'src/services/clients/clients.service';
import { Client } from 'src/services/clients/types';
import localStorageService from 'src/services/local-storage/localStorage.service';
import dictionary from 'src/utils/dictionary/dictionary';

interface Props {
  client?: Client;
}

const History: React.FC<Props> = ({ client }) => {
  const userId = localStorageService.getUserId();
  const selectedLanguage = useAppSelector((state) => state.lang.lang);
  const { data: clientsHistory } = useGetClientsHistoryByIdQuery(
    Number(userId),
  );

  const [showFullHistory, setShowFullHistory] = useState(false);

  const getFullHistory = () => clientsHistory?.map((monthHistory) =>
    Object.keys(monthHistory.history)
      .reverse()
      .map((key) => (
        <p key={key} className="mb-[2px]">
          <b>{key}:</b> {monthHistory?.history[key]}
        </p>
      )),
  ).reverse();

  const getHistoryList = () => client?.history
    ? Object.keys(client?.history)
      .reverse()
      .map((key) => (
        <p key={key} className="mb-[2px]">
          <b>{key}:</b> {client?.history[key]}
        </p>
      ))
    : null;

  return (
    <div className="w-full">
    <div className="mb-[8px] flex justify-center">
      <button
        className="max-h-[32px] max-w-[200px] rounded-lg border border-lightBrown p-[2px] text-sm"
        onClick={() => setShowFullHistory(!showFullHistory)}
      >
        {showFullHistory
          ? dictionary[selectedLanguage].fullHistory
          : dictionary[selectedLanguage].monthHistory}
      </button>
    </div>
    <div className="max-h-[240px] w-full flex-col overflow-y-scroll rounded-lg border border-lightBrown p-[8px]">
      {showFullHistory
        ? getFullHistory()
        : getHistoryList()}
    </div>
  </div>
  );
};

export default History;
