import React, { useEffect, useState } from 'react';
import ContainerBox from '../components/ui/containerBox';
import Calendar from '../components/ui/calendar/calendar';
import '../index.css';
import { useSelector } from 'react-redux';
import localStorageService from '../services/local-storage/localStorage.service';
import RecordsList from '../components/home/recordsList';
import { useNavigate } from 'react-router-dom';
import recordService from '../services/record.service';
import dictionary from '../utils/dictionary/dictionary';

const ClientMainPage = () => {
  const selectedLanguage = useSelector((state) => state.lang.lang);
  const navigate = useNavigate();
  const [calendarDate, setCalendarDate] = useState(new Date());
  const clientId = localStorageService.getClientId();

  if (!clientId) {
    navigate('/client/login');
  }
  const [records, setRecords] = useState(null);
  const loadData = async () => {
    setRecords(await recordService.getRecords());
  };
  let date = useSelector((state) => state.date.date).split('.');

  date = `${date[2]}-${Number(date[1]) > 9 ? date[1] : '0' + Number(date[1])}-${
    Number(date[0]) > 9 ? date[0] : '0' + Number(date[0])
  }`;

  useEffect(() => {
    loadData();
  }, []);
  const clientRecords = records
    ? records
      .filter(
        (record) =>
          record.clientId === Number(localStorageService.getClientId())
      )
      .filter((r) => r.date === date)
    : null;

  return (
    <div className="container-fluid relative mx-auto flex h-[calc(100vh-252px)] items-start justify-center bg-cream">
      <ContainerBox>
        <h2 className="text-2xl text-brown max-md:text-lg">
          {dictionary[selectedLanguage].serviceList}
        </h2>
        <Calendar
            currentDate={calendarDate}
            onSelectDate={setCalendarDate}
          />
        <RecordsList
          filteredRecords={clientRecords}
          pageType={'client'}
        ></RecordsList>
      </ContainerBox>
    </div>
  );
};

export default ClientMainPage;
