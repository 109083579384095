import React from 'react';
import PropTypes from 'prop-types';
import ClientElement from './clientElement';
import localStorageService from '../../services/local-storage/localStorage.service';
import dictionary from '../../utils/dictionary/dictionary';
import { useSelector } from 'react-redux';

const ClientsList = ({ clients, records, comments }) => {
  const selectedLanguage = useSelector((state) => state.lang.lang);
  const userId = localStorageService.getUserId();
  const userRecords = records?.filter(
    (record) => record.userId === Number(userId)
  );
  const userClientsIndex = userRecords?.map((record) => record.clientId);
  const userClients = clients.filter((client) =>
    userClientsIndex.includes(client.id)
  );
  const clientElements = userClients
    ? userClients.map((client) => (
        <ClientElement
          key={client.id}
          client={client}
          comments={comments}
          records={userRecords}
          lastEl={client === userClients.at(-1)}
        ></ClientElement>
    ))
    : null;

  return (
    <div className="pt-[32px] text-darkBrown">
      <div className="grid grid-cols-3 rounded-t-lg border border-gray bg-lightGray  font-bold max-md:grid-cols-5">
        <div className="px-[20px] py-[12px] max-md:col-span-2 max-md:px-[10px] max-md:py-[6px]">
          {dictionary[selectedLanguage].userName}
        </div>
        <div className="px-[20px max-md:px-[10px]] py-[12px] max-md:py-[6px]">
          {dictionary[selectedLanguage].rating}
        </div>
        <div className="px-[20px] py-[12px] max-md:col-span-2 max-md:px-[10px] max-md:py-[6px]">
          {dictionary[selectedLanguage].feedbackClient}
        </div>
      </div>
      {clientElements}
    </div>
  );
};

ClientsList.propTypes = {
  clients: PropTypes.array,
  records: PropTypes.array,
  comments: PropTypes.array
};

export default ClientsList;
