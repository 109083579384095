import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { User } from 'src/services/users/types';

interface UsersState {
  users: User[];
  selectedUser?: User;
}

const initialState: UsersState = {
  users: [],
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsers: (state, action: PayloadAction<User[]>) => {
      state.users = action.payload;
    },
    setSelectedUser: (state, action: PayloadAction<number>) => {
      state.selectedUser = state.users.find((user) => user.id === action.payload);
    }
  }
});

export const {
  setUsers,
  setSelectedUser,
} = usersSlice.actions;

export default usersSlice.reducer;
