import React, { useState } from 'react';
import classNames from 'classnames';
import { User as UserT } from 'src/services/users/types';
import { transformUserName } from 'src/components/calendar/admin-calendar-board/components/board-day/components/user-miniature/helpers';

interface Props {
  isFirst: boolean;
  isLast: boolean;
  user: UserT;
  onRemoveUser: (id: number) => void
}

const User: React.FC<Props> = ({ isFirst, isLast, user, onRemoveUser }) => {
  const [onMouse, setOnMouse] = useState(false);

  return (
    <div
    className={classNames(
      'mx-4 flex flex-col items-center justify-center text-sm',
      {
        'ml-8': isFirst,
        'mr-8': isLast,
      },
    )}
  >
    <div className='relative cursor-pointer' onMouseEnter={() => setOnMouse(true)} onMouseLeave={() => setOnMouse(false)} onClick={() => onRemoveUser(user.id)}>
      <span className={classNames('absolute right-0 top-0 px-1 text-md', {
        'opacity-0': !onMouse,
        'opacity-100': onMouse
      })}>x</span>
      <div className='flex flex-col items-center justify-center hover:opacity-50'>
      {user.name && user.image
        ? (
        <img
          className="size-[38px] rounded-full"
          src={user.image}
          alt=""
        />
          )
        : null}
    <p className='mx-5 mt-4'>{transformUserName(user.name)}</p>
      </div>

    </div>
  </div>
  );
};

export default User;
