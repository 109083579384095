import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import ContainerBox from '../components/ui/containerBox';
import Calendar from '../components/ui/calendar/calendar';
import CrmRecordEditor from '../components/calendar/crmRecordEditor';
import UserEditor from '../components/calendar/userEditor';
import CrmCalendarBoard from '../components/calendar/crmCalendarBoard';
import localStorageService from '../services/local-storage/localStorage.service';
import serviceService from '../services/service.service';
import userService from '../services/user.service';

import dictionary from '../utils/dictionary/dictionary';
import brownTriangle from '../assets/imgs/brownTriangle.png';
import AdminCalendarPage from './admin-calendar-page/adminCalendarPage';

const CrmCalendarPage = () => {
  const selectedLanguage = useSelector((state) => state.lang.lang);
  const navigate = useNavigate();
  const userId = localStorageService.getUserId();

  if (!userId) {
    navigate('/crm/login');
  }
  const dispatch = useDispatch();
  const [calendarDate, setCalendarDate] = useState(new Date());
  const successNotify = () =>
    toast.success(dictionary[selectedLanguage].success);
  const errorNotify = () => toast.error(dictionary[selectedLanguage].error);

  const handleSetDate = (date) => {
    setCalendarDate(date);
  };
  const selectedMaster = useSelector((state) => state.user.selectedMaster);
  const [reset, setReset] = useState(1);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [selectedUser, setSelectedUser] = useState(selectedMaster);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedComplex, setSelectedComplex] = useState(null);
  const [slotForChange, setSlotForChange] = useState(null);
  const [showUserDropdown, setShowUserDropdown] = useState(false);
  const [showServiceDropdown, setShowServiceDropdown] = useState(false);
  const [user, setUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [services, setServices] = useState([]);
  const [complexes, setComplexes] = useState([]);
  const [recordAdded, setRecordAdded] = useState(false);
  const complex = !!selectedComplex;
  const [complexNumber, setComplexNumber] = useState(0);
  const [admin, setAdmin] = useState(null);

  const loadData = useCallback(async (userId) => {
    const userData = await userService.getUserById(userId);

    setUser(userData);
    if (userData.isAdmin) {
      setAdmin(true);
    } else {
      const allUsers = await userService.getUsers();

      setUsers(
        selectedService
          ? allUsers.filter(
            (user) =>
              user.isStaff &&
                      (user.services.includes(selectedService.id) ||
                          [
                            'Day off',
                            'Odmar 1',
                            'Odmar 2',
                            'Odmar 4',
                            'Odmar 0.5'
                          ].includes(selectedService.en))
          )
          : allUsers.filter((user) => user.isStaff)
      );
      setServices(await serviceService.getServices());
      setComplexes(await serviceService.getComplexes());
    }
  }, [selectedService]);

  useEffect(() => {
    loadData(userId);
  }, [userId, loadData]);

  const handleShowUserDropdown = () => {
    setShowUserDropdown(!showUserDropdown);
  };

  const handleSelectUser = (user) => {
    setSelectedUser(user);
    setShowUserDropdown(!showUserDropdown);
    setSelectedSlot(null);
  };
  const handleSelectSlot = (slot) => {
    setSelectedSlot(slot);
    setSlotForChange(null);
  };

  const handleSelectSlots = (slot) => {
    slot.serviceId = selectedService?.id;
    if (selectedComplex && selectedSlots) {
      setSelectedSlots((previousState) => [...previousState, slot]);
      setComplexNumber((previousState) => previousState + 1);
    }
    setSlotForChange(null);
  };

  useEffect(() => {
    if (selectedComplex) {
      setSelectedService(selectedComplex?.services[complexNumber]);
    }
  }, [selectedSlots, selectedComplex, selectedService, complexNumber]);

  const handleShowServiceDropdown = () => {
    setShowServiceDropdown(!showServiceDropdown);
  };
  const handleSelectService = (service) => {
    setSelectedComplex(null);
    setSelectedService(service);
    setShowServiceDropdown(!showServiceDropdown);
    setSelectedSlot();
    setSelectedSlots([]);
  };

  const handleSelectComplex = (complex) => {
    setComplexNumber(0);
    setSelectedComplex(complex);
    setSelectedService(complex?.services[complexNumber]);
    setShowServiceDropdown(!showServiceDropdown);
    setSelectedSlot();
    setSelectedSlots([]);
  };

  const handleAddRecord = () => {
    setRecordAdded(!recordAdded);
  };

  const handleMassAddRecord = () => {
    handleAddRecord();
    setSelectedComplex(null);
    setSelectedService(null);
    setSelectedSlots([]);
    setSelectedSlot(null);
    setComplexNumber(0);
  };

  useEffect(() => {
    if (users && !selectedMaster) {
      const currentUserInUsers = users.find(
        (user) => user.id === Number(userId)
      );

      if (currentUserInUsers) {
        setSelectedUser(currentUserInUsers);
      } else {
        setSelectedUser(users[0]);
      }
    }
  }, [users, selectedMaster, userId]);
  if (admin) {
    return <AdminCalendarPage/>;
  }
  if (user) {
    if (user.isStaff || user.isAdmin) {
      return (
        <div className="relative mx-auto flex h-[calc(100vh-252px)] items-start justify-center bg-cream text-lightBrown max-md:text-sm">
          <ContainerBox>
            <h2 className="text-xl max-md:text-lg">
              {dictionary[selectedLanguage].calendar}
            </h2>

              <Calendar currentDate={calendarDate} onSelectDate={setCalendarDate} />

            <div className="mt-[10px] max-md:flex  max-md:flex-col md:grid md:grid-cols-5">
              <div className="col-span-4 mr-[6px] flex rounded-lg border border-gray">
                <CrmCalendarBoard
                  key={reset}
                  firstDay={calendarDate}
                  selectedService={selectedService}
                  selectedUser={selectedUser}
                  selectedSlot={selectedSlot}
                  selectedSlots={selectedSlots}
                  handleSelectedSlot={handleSelectSlot}
                  handleSelectSlots={handleSelectSlots}
                  complex={complex}
                  setSlotForChange={setSlotForChange}
                />
              </div>
              <div className="relative flex w-full flex-col max-md:-order-1 max-md:mb-[10px]">
                <UserEditor
                  show={showUserDropdown}
                  selectedUser={selectedUser}
                  handleShow={handleShowUserDropdown}
                  handleSelectUser={handleSelectUser}
                  users={users}
                ></UserEditor>
                <CrmRecordEditor
                  services={services.filter((service) => service.active)}
                  complexes={complexes}
                  selectedService={selectedService}
                  selectedComplex={selectedComplex}
                  show={showServiceDropdown}
                  handleSelectService={handleSelectService}
                  handleSelectComplex={handleSelectComplex}
                  handleMassAddRecord={handleMassAddRecord}
                  handleShow={handleShowServiceDropdown}
                  handleAddRecord={handleAddRecord}
                  selectedUser={selectedUser}
                  selectedSlot={selectedSlot}
                  selectedSlots={selectedSlots}
                  handleSelectedSlot={handleSelectSlot}
                  slotForChange={slotForChange}
                  successNotify={successNotify}
                  errorNotify={errorNotify}
                  reset={setReset}
                ></CrmRecordEditor>
              </div>
            </div>
          </ContainerBox>
          <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </div>
      );
    } else {
      navigate('/crm/login');
    }
  }
};

export default CrmCalendarPage;
