import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import clientService from '../../services/client.service';
import dictionary from '../../utils/dictionary/dictionary';

const MasterPageRecordElement = ({ record, lastEl, services, clients }) => {
  const selectedLanguage = useSelector((state) => state.lang.lang);
  const [client, setClient] = useState();
  const loadData = async (clientId) => {
    setClient(await clientService.getClientById(clientId));
  };

  useEffect(() => {
    loadData(record.clientId);
  }, [record]);

  return (
    <>
      <div
        className={
          lastEl
            ? 'grid grid-cols-6 rounded-b-lg border border-gray max-md:grid-cols-12'
            : 'grid grid-cols-6 border border-gray max-md:grid-cols-12'
        }
      >
        <div className="px-[20px] py-[12px] max-md:col-span-2 max-md:p-[6px] max-md:text-start">
          {client?.name}
        </div>
        <div className="px-[20px] py-[12px] max-md:p-[6px] max-md:text-start">{`${record.id}`}</div>
        <div className="px-[20px] py-[12px] max-md:col-span-3 max-md:p-[6px] max-md:text-start">
          {record?.service?.name}
        </div>
        <div className="px-[20px] py-[12px] max-md:col-span-2 max-md:p-[6px] max-md:text-start">
          {record?.service?.price}
          {record.status === 'completed'
            ? (
            <div>
              <b>{record?.price}</b>
            </div>
              )
            : null}
        </div>
        <div className="px-[20px] py-[12px] max-md:col-span-2 max-md:p-[6px] max-md:text-start">
          {record.time.slice(0, 5)}
        </div>
        <div className="flex items-center justify-start px-[20px] py-[12px] max-md:col-span-2 max-md:items-start max-md:p-[6px] max-md:text-start">
          <a href={record.image}>{dictionary[selectedLanguage].photo}</a>
        </div>
      </div>
    </>
  );
};

MasterPageRecordElement.propTypes = {
  record: PropTypes.object.isRequired,
  lastEl: PropTypes.bool,
  services: PropTypes.array,
  users: PropTypes.array
};

export default MasterPageRecordElement;
