import React, { useMemo } from 'react';
import dictionary from 'src/utils/dictionary/dictionary';
import { useAppSelector } from 'src/hooks/api';
import { useGetRecordsQuery } from 'src/services/records/records.service';
import { Columns } from './types';
import { getColumnClasses } from './helpers';
import RecordElement from './components/record-element/recordElement';

interface Props {
  clientId?: number;
}

const ClientPageRecords: React.FC<Props> = ({ clientId }) => {
  const selectedLanguage = useAppSelector((state) => state.lang.lang);
  const { data: recordsData } = useGetRecordsQuery();

  const clientRecords = useMemo(() => {
    return recordsData?.filter(
      (record) => record.clientId === clientId,
    ).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  }, [clientId, recordsData]);

  const dictionaryLang = dictionary[selectedLanguage];

  if (!clientRecords?.length) {
    return null;
  }

  return (
    <>
      <h2 className="mt-[32px] text-2xl text-brown max-md:mt-[5px] max-md:text-lg">
        {dictionaryLang.recordHistory}
      </h2>
      <div className="pt-[32px] text-darkBrown max-md:text-xs">
        {(() => (
          <div className="grid grid-cols-6 rounded-t-lg border border-gray bg-lightGray font-bold max-md:grid-cols-12">
            {['master', '#', 'service', 'price', 'time_date', 'photo'].map(
              (key, index) => (
                <div
                  key={index}
                  className={`px-[20px] py-[12px] ${getColumnClasses(key as Columns)}`}
                >
                  {key === '#' ? key : dictionaryLang[key]}
                </div>
              ),
            )}
          </div>
        ))()}
        {clientRecords.map((record, index) => (
          <RecordElement
            key={record.id}
            isLast={index === clientRecords.length - 1}
            record={record}
          />
        ))}
      </div>
    </>
  );
};

export default ClientPageRecords;
