/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useState } from 'react';
import Close from 'src/assets/imgs/plus-circle.png';
import Rating from 'react-rating';
import starFull from 'src/assets/imgs/star-full.png';
import starEmpty from 'src/assets/imgs/star-empty.png';
import localStorageService from 'src/services/local-storage/localStorage.service';
import { toast } from 'react-toastify';
import dictionary from 'src/utils/dictionary/dictionary';
import { useAppSelector } from 'src/hooks/api';
import { RecordApi } from 'src/services/records/types';
import { Form, FormFields } from './types';
import { useForm } from 'react-hook-form';
import { DEFAULT_FORM_VALUES } from './constants';
import { yupResolver } from '@hookform/resolvers/yup';
import yupSchema from './schema';
import { useCreateCommentMutation, useUploadCommentImageMutation } from 'src/services/comments/comments.service';

interface Props {
  record: RecordApi;
  isLast: boolean;
}

const ClientRecordElement: React.FC<Props> = ({ record, isLast }) => {
  const selectedLanguage = useAppSelector((state) => state.lang.lang);
  const [selectedPreview, setSelectedPreview] = useState<string | null>(null);
  const [selectedRating, setSelectedRating] = useState<number>();
  const [selectedImage, setSelectedImage] = useState<File>();
  const [createComment, { isSuccess: isCreateCommentSuccess, data }] = useCreateCommentMutation();
  const [updateCommentImage] = useUploadCommentImageMutation();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const windowWidth = window.innerWidth;
  const notify = () => toast.success('Сохранено');

  const { register, getValues } = useForm<Form>({
    mode: 'onChange',
    defaultValues: DEFAULT_FORM_VALUES,
    resolver: yupResolver(yupSchema),
  });

  useEffect(() => {
    const updateComment = async () => {
      if (isCreateCommentSuccess && selectedImage && data) {
        const formData = new FormData();

        formData.append('image', selectedImage);
        await updateCommentImage({ commentId: data.commentId, formData });
        notify();
        setIsFormOpen(!isFormOpen);
      }
    };

    updateComment();
  }, [data, isCreateCommentSuccess, selectedImage]);

  useEffect(() => {
    if (selectedImage) {
      const url = URL.createObjectURL(selectedImage);

      setSelectedPreview(url);
    }
  }, [selectedImage]);

  const handleSubmit = async () => {
    const clientId = localStorageService.getClientId();
    const formValues = getValues();

    await createComment(
      {
        clientId,
        userId: null,
        recordId: record.id,
        content: formValues[FormFields.COMMENT] ?? '',
        rating: selectedRating,
      }
    );
  };

  return (
    <>
      <div
        onClick={() => setIsFormOpen(!isFormOpen)}
        className={
          isLast
            ? 'grid cursor-pointer grid-cols-4 rounded-b-lg border border-gray hover:opacity-70'
            : 'grid cursor-pointer grid-cols-4 border border-gray hover:opacity-70'
        }
      >
        <div className="px-[20px] py-[12px] max-md:px-[10px] max-md:py-[6px]">
          {record?.user?.name}
        </div>
        <div className="px-[20px] py-[12px] max-md:px-[10px] max-md:py-[6px]">
          {record?.service?.[selectedLanguage]}
        </div>
        <div className="px-[20px] py-[12px] max-md:px-[10px] max-md:py-[6px]">
          {record?.service?.price}
        </div>
        <div className="px-[20px] py-[12px] max-md:px-[10px] max-md:py-[6px]">
          {record.time.slice(0, 5)}
        </div>
      </div>

      <span
        className={
          isFormOpen
            ? 'backdrop-isFormOpen-lg absolute left-0 top-[-252px] z-[100] flex h-screen w-screen items-center justify-center bg-transparent max-md:top-[-278px]'
            : 'backdrop-isFormOpen-lg collapse absolute left-0 top-[-252px] z-[100] flex h-screen w-screen items-center justify-center bg-transparent max-md:top-[-270px]'
        }
      >
        <div className="w-[600px]">
          <div className="mt-[16px] flex w-full justify-between">
            <h3 className="text-base text-white">
              {dictionary[selectedLanguage].leaveFeedback}
            </h3>
            <img
              className="cursor-pointer"
              onClick={() => setIsFormOpen(!isFormOpen)}
              src={Close}
              alt="close"
            />
          </div>
          <textarea
            className="mb-[16px] w-full rounded-lg bg-white p-[10px] text-black outline-none"
            {...register(FormFields.COMMENT)}
          />
          <input
            className="mb-[16px] block w-full cursor-pointer rounded-lg border border-lightBrown bg-white px-3 py-2 text-sm text-lightBrown file:cursor-pointer file:rounded-lg file:border file:border-lightBrown file:bg-cream file:text-brown file:outline-none placeholder:text-lightBrown file:hover:opacity-80 focus:outline-none"
            id="file"
            accept={windowWidth < 800 ? 'image/*, file/*' : 'image/*'}
            type="file"
            placeholder={''}
            onChange={(e) => {
              if (e.target.files) {
                setSelectedImage(e.target.files[0]);
              }
            }}
          />
          {selectedPreview
            ? (
            <img
              src={selectedPreview}
              alt=""
              className="mx-auto my-[16px] w-full"
            />
              )
            : null}

          <div className="flex justify-between">
            <Rating
              initialRating={selectedRating}
              onClick={(value: number) => setSelectedRating(value)}
              emptySymbol={<img src={starEmpty} alt="empty star" />}
              fullSymbol={<img src={starFull} alt="filled star" />}
            />

            <button
              className="rounded-lg bg-black px-[12px] py-[10px] text-white hover:opacity-80"
              onClick={handleSubmit}
              disabled={!selectedRating}
            >
              Отправить
            </button>
          </div>
        </div>
      </span>
    </>
  );
};

export default ClientRecordElement;
