import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import TextField from '../ui/text-field/textField';
import localStorageService from '../../services/local-storage/localStorage.service';
import authService from '../../services/auth.service';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../store/authSlice';
import dictionary from '../../utils/dictionary/dictionary';
import userService from '../../services/user.service';
import ReCAPTCHA from 'react-google-recaptcha';

const ClientLoginForm = () => {
  const userId = localStorageService.getUserId();
  const [user, setUser] = useState(null);
  const loadData = async (userId) => {
    setUser(await userService.getUserById(userId));
  };

  useEffect(() => {
    if (userId) {
      loadData(userId);
    }
  }, [userId]);

  const selectedLanguage = useSelector((state) => state.lang.lang);
  const navigate = useNavigate();
  const [data, setData] = useState({
    phone: '+',
    password: ''
  });
  const dispatch = useDispatch();

  let phoneError;

  if (
    data.phone.startsWith('+3') &&
    !(/^\+3\d{10}$/.test(data.phone) || /^\+3\d{11}$/.test(data.phone))
  ) {
    phoneError = 'phone must contain 11-12 digits';
  }
  if (data.phone.startsWith('+7') && !/^\+7\d{10}$/.test(data.phone)) {
    phoneError = 'phone must contain 11 digits';
  }
  if (
    !data.phone.startsWith('+3') &&
    !data.phone.startsWith('+7') &&
    data.phone.length > 1
  ) {
    phoneError = "phone must start  with '7' or '3'";
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const authData = await authService.userLogin(data.phone, data.password);

    if (authData) {
      localStorageService.setUserTokens({ ...authData });
      dispatch(loginUser());
      navigate('/crm');
    }
  };

  const handleChange = (target) => {
    if (target.name === 'phone') {
      if (data.phone.startsWith('+')) {
        setData((previousState) => ({
          ...previousState,
          [target.name]: target.value
        }));
      } else {
        setData((previousState) => ({
          ...previousState,
          [target.name]: '+' + target.value
        }));
      }
    } else {
      setData((previousState) => ({
        ...previousState,
        [target.name]: target.value
      }));
    }
  };
  const [reCaptcha, setReCaptcha] = useState(null);
  const recaptchaReference = React.createRef();
  const handleReCaptcha = () => {
    const recaptchaValue = recaptchaReference.current.getValue();

    setReCaptcha(recaptchaValue);
  };
  const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_WEB_KEY;

  return (
    <form onSubmit={handleSubmit}>
      <p className="mb-[4px] text-2xl text-brown max-md:text-lg">
        {dictionary[selectedLanguage].login}
      </p>
      {localStorageService.getUserAccessToken() && !user?.isStaff
        ? (
        <p className="text-red">Contact admin to get access</p>
          )
        : (
        <p className="mb-[24px] text-sm text-lightBrown drop-shadow-2xl">
          {dictionary[selectedLanguage].phoneNumberWithPassword}
        </p>
          )}
      <TextField
        name="phone"
        label={dictionary[selectedLanguage].phoneNumber}
        placeholder="+1234567890"
        type="text"
        value={data.phone}
        onChange={handleChange}
        error={phoneError}
      />
      <TextField
        name="password"
        value={data.password}
        label={dictionary[selectedLanguage].password}
        placeholder={dictionary[selectedLanguage].enterPassword}
        type="password"
        onChange={handleChange}
      />
      <div className="mt-[10px] flex w-full items-center justify-center">
        <ReCAPTCHA
          ref={recaptchaReference}
          sitekey={reCaptchaKey}
          onChange={handleReCaptcha}
          size="compact"
        />
      </div>
      <button
        className="mt-[24px] w-full rounded-lg bg-brown py-[8px] text-center text-white hover:opacity-80"
        disabled={
          !!(phoneError || data.phone.length < 2 || !reCaptcha)
        }
      >
        {dictionary[selectedLanguage].login}
      </button>
      <Link to={'/crm/password-recovery'}>
        {dictionary[selectedLanguage].passwordRecovery}
      </Link>
    </form>
  );
};

export default ClientLoginForm;
