/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/promise-function-async */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useMemo, useState } from 'react';
import { ButtonStatus } from '../status-button/constants';
import dropdownArrow from 'src/assets/imgs/dropdown-arrow.png';
import { useAppSelector } from 'src/hooks/api';
import classNames from 'classnames';
import dictionary from 'src/utils/dictionary/dictionary';
import { User } from 'src/services/users/types';
import { Form, FormFields, Master } from './types';
import { useForm } from 'react-hook-form';
import yupSchema from './schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { RecordApi } from 'src/services/records/types';
import { Client } from 'src/services/clients/types';
import { useUpdateRecordMutation } from 'src/services/records/records.service';
import { toast } from 'react-toastify';

interface Props {
  status: ButtonStatus;
  currentUser: User;
  users: User[] | null;
  isComeFromFieldVisible: boolean;
  record?: RecordApi;
  client?: Client;
  onSubmit: () => void;
}

const DetailsForm: React.FC<Props> = ({
  status,
  currentUser,
  users,
  isComeFromFieldVisible,
  record,
  client,
  onSubmit,
}) => {
  const selectedLanguage = useAppSelector((state) => state.lang.lang);
  const [showMaster, setShowMaster] = useState(false);
  const [selectedMaster, setSelectedMaster] = useState<Master>();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const successNotify = () =>
    toast.success(dictionary[selectedLanguage].success);
  const errorNotify = () =>
    toast.error(dictionary[selectedLanguage].error);

  const user = useMemo(() => {
    return users?.find((user) => record?.userId === user.id);
  }, [record?.userId, users]);

  const [
    updateRecord,
    { isSuccess, isError },
  ] = useUpdateRecordMutation();

  const { register, getValues, setValue } = useForm<Form>({
    mode: 'onChange',
    defaultValues: {
      [FormFields.PRICE]: undefined,
      [FormFields.DAY]: '',
      [FormFields.TIME]: '',
      [FormFields.CAME_FROM]: null,
      [FormFields.COMMENT]: '',
    },
    resolver: yupResolver(yupSchema),
  });

  useEffect(() => {
    if (isSuccess) {
      successNotify();
    }
    if (isError) {
      errorNotify();
    }
  }, [isSuccess, isError]);

  useEffect(() => {
    if (record) {
      setValue(FormFields.DAY, record.date);
      setValue(FormFields.TIME, record.time);
      const user = users?.find((user) => user.id === record.userId);

      if (user) {
        setSelectedMaster({ id: record.userId, name: user.name });
      }
    }
  }, [record, users]);

  useEffect(() => {
    checkButtonDisabling();
  }, [client?.cameFrom, status]);

  const checkButtonDisabling = () => {
    if (((!getValues().price || (!getValues().cameFrom && !client?.cameFrom)) &&
    status === ButtonStatus.COMPLETED)) {
      setIsButtonDisabled(true);
    } else setIsButtonDisabled(false);
  };

  const selectMaster = (master: User) => {
    setSelectedMaster({ id: master.id, name: master.name });
    setShowMaster(!showMaster);
  };

  const submit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const formValues = getValues();

    await updateRecord({
      recordId: record?.id,
      price: formValues.price || 0,
      comment: formValues.comment ?? '',
      status,
      time: formValues.time,
      date: formValues.day,
      userId: currentUser?.id,
      masterId: selectedMaster?.id,
      cameFrom: formValues.cameFrom ?? null,
    });

    onSubmit();
  };

  return (
    <form onChange={checkButtonDisabling}>
      {status === ButtonStatus.COMPLETED && (
        <input
          id="price"
          type="number"
          className="w-full rounded-lg border border-lightBrown px-3 py-2 text-lightBrown placeholder:text-lightBrown focus:outline-brown"
          placeholder={
            dictionary[selectedLanguage].price as string
          }
          {...register(FormFields.PRICE)}
        />
      )}

      <input
        type="date"
        disabled={record?.status === ButtonStatus.COMPLETED}
        className="my-[8px] w-full rounded-lg border border-lightBrown px-[8px] py-[7px] text-lightBrown"
        {...register(FormFields.DAY)}
      />

      <input
        type="time"
        disabled={record?.status === ButtonStatus.COMPLETED}
        className="w-full rounded-lg border border-lightBrown px-[8px] py-[7px] text-lightBrown"
        {...register(FormFields.TIME)}
      />
      {isComeFromFieldVisible && (
        <>
          <label className="text-lightBrown" htmlFor="cameFrom">
            {dictionary[selectedLanguage].cameFrom}
          </label>
          <input
            id="cameFrom"
            type="text"
            className="w-full rounded-lg border border-lightBrown px-3 py-2 text-lightBrown placeholder:text-lightBrown focus:outline-brown"
            {...register(FormFields.CAME_FROM)}
          />
        </>
      )}

      <p className="mb-[4px] font-thin">
        {dictionary[selectedLanguage].master}
      </p>

      {currentUser?.isAdmin
        ? (
        <div className="relative mb-[8px] flex cursor-pointer items-center justify-between rounded-lg border border-lightBrown px-[8px] py-[7px] text-lightBrown">
          <div
            className={'flex w-full items-center justify-between'}
            onClick={() =>
              record?.status === ButtonStatus.CREATED &&
              setShowMaster(!showMaster)
            }
          >
            <span className="hover:opacity-80">
              {selectedMaster?.name ?? user?.name}
            </span>
            <img
              className={showMaster ? 'size-[16px] rotate-180' : 'size-[16px]'}
              src={dropdownArrow}
              alt="dropdown arrow"
            />
          </div>
          {showMaster && (
            <>
              <div className="absolute left-0 top-full w-full rounded-lg border border-gray opacity-100">
                {users
                  ?.filter((user) => user.isStaff)
                  .filter((user) => user?.services.includes(record?.serviceId as number))
                  .map((master) => (
                    <div
                      key={master.id}
                      className="cursor-pointer border-b border-gray bg-white px-[16px] py-[7px] text-brown first:rounded-t-lg last:rounded-b-lg last:border-none hover:text-lightBrown"
                      onClick={() => selectMaster(master)}
                    >
                      {master?.name}
                    </div>
                  ))}
              </div>
            </>
          )}
        </div>
          )
        : users
          ? (
        <p className="mb-[4px] text-darkBrown">
          {
            Object.values(users).find((user) => user.id === record?.userId)
              ?.name
          }
        </p>
            )
          : null}

      <p className="mb-[4px] font-thin">
        {dictionary[selectedLanguage].feedback}
      </p>

      <textarea
        id="comment"
        className="w-full rounded-lg border border-lightBrown px-3 py-2 text-lightBrown placeholder:text-lightBrown focus:outline-brown"
        disabled={record?.status === 'completed'}
        {...register(FormFields.COMMENT)}
      />
      <button
        type='submit'
        className={classNames('mb-[8px] items-end rounded-lg border border-darkBrown bg-cream px-[12px] py-[10px] text-brown hover:opacity-80', {
          'opacity-50': isButtonDisabled
        })}
        disabled={isButtonDisabled}
        onClick={(e) => submit(e)}
      >
        {dictionary[selectedLanguage].confirm}
      </button>
    </form>
  );
};

export default DetailsForm;
