import React, { useState } from 'react';
import dictionary from 'src/utils/dictionary/dictionary';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Client } from 'src/services/clients/types';
import { User } from 'src/services/users/types';
import { useAppSelector } from 'src/hooks/api';
import ClientInfo from './components/client-info/clientInfo';
import History from './components/history/history';
import AdmitForm from './components/admit-form/admitForm';

interface Props {
  user: User;
  client?: Client;
  refetch: () => void;
}

const ClientPageHeader: React.FC<Props> = ({ user, client, refetch }) => {
  const selectedLanguage = useAppSelector((state) => state.lang.lang);
  const [showHistory, setShowHistory] = useState(false);

  return (
    <>
      <div className="flex w-full items-center justify-between py-[20px]">
        <div className="w-full md:flex md:justify-between">
          <ClientInfo
            buttonTitle={
              showHistory
                ? (dictionary[selectedLanguage].info as string)
                : (dictionary[selectedLanguage]
                    .history as string)
            }
            user={user}
            client={client}
            onButtonPress={() => setShowHistory(!showHistory)}
          />
          <div>
            <div className="mx-auto flex w-full flex-col">
              {user.isAdmin && !user.isStaff
                ? (
                    showHistory
                      ? (
                  <History client={client} />
                        )
                      : (
                  <AdmitForm client={client} refetch={refetch} />
                        )
                  )
                : null}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default ClientPageHeader;
