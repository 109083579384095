/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import starFull from 'src/assets/imgs/Star 6.png';
import _ from 'lodash';
import dictionary from 'src/utils/dictionary/dictionary';
import { Comment } from 'src/services/comments/types';
import { useAppSelector } from 'src/hooks/api';

interface Props {
  comment: Comment;
}

const CommentElement: React.FC<Props> = ({ comment }) => {
  const selectedLanguage = useAppSelector((state) => state.lang.lang);

  return (
    <>
      <div className="mb-[16px] flex w-full flex-col rounded-lg border border-gray px-[16px] py-[12px] text-brown">
        <p className="mb-[16px] text-darkBrown">{`Номер - №${comment.recordId}`}</p>
        <div className="flex">
          {comment.authorImage
            ? (
            <img
              className="mr-[6px] size-[50px] rounded-full"
              src={comment.authorImage}
              alt=""
            />
              )
            : null}
          <div>
            <p className="mb-[6px] text-darkBrown">
              {comment.authorName ?? dictionary[selectedLanguage].noData}
              {comment.authorType === 'user'
                ? ` (${dictionary[selectedLanguage].staff})`
                : ` (${dictionary[selectedLanguage].client})`}
            </p>
            <p className="mb-[16px]">{comment.content}</p>
          </div>
        </div>
        {comment.image
          ? (
          <a href={comment.image} target="_blank" rel="noopener noreferrer">
            {dictionary[selectedLanguage].photo}
          </a>
            )
          : null}
        <div className="flex items-center justify-end ">
          {_.range(Number(comment.rating)).map((x) => (
            <img key={x} className="mb-[3px] size-[20px]" src={starFull} alt="rating" />
          ))}
          <p className="ml-[16px] text-xs">{comment.createdAt}</p>
        </div>
      </div>
    </>
  );
};

export default CommentElement;
