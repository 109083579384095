import React, { useEffect, useState } from 'react';
import ContainerBox from '../components/ui/containerBox';
import TextField from '../components/ui/text-field/textField';
import clientService from '../services/client.service';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import localStorageService from '../services/local-storage/localStorage.service';
import { useNavigate } from 'react-router-dom';
import dictionary from '../utils/dictionary/dictionary';
import { useSelector } from 'react-redux';

const ClientProfilePage = () => {
  const windowWidth = window.innerWidth;
  const selectedLanguage = useSelector((state) => state.lang.lang);
  const notify = () => toast.success('Сохранено');
  const navigate = useNavigate();
  const clientId = localStorageService.getClientId();

  if (!clientId) {
    navigate('/client/login');
  }
  const [client, setClient] = useState(null);
  const [selectedImage, setSelectedImage] = useState();
  const [selectedPreview, setSelectedPreview] = useState(client?.image);

  const loadData = async (clientId) => {
    setClient(await clientService.getClientById(clientId));
  };

  useEffect(() => {
    loadData(clientId);
  }, [clientId]);

  useEffect(() => {
    setSelectedPreview(client?.image);
  }, [client]);

  const [data, setData] = useState({
    name: client ? client.name : '',
    email: client ? client.email : '',
    telegram: client ? client.telegram : '',
    instagram: client ? client.instagram : '',
    birthday: client ? client.birthdate : null
  });

  useEffect(() => {
    setData({
      name: client?.name,
      email: client?.email,
      telegram: client?.telegram,
      instagram: client?.instagram,
      birthday: client?.birthday
    });
  }, [client]);

  const handleChange = (target) => {
    setData((previousState) => ({
      ...previousState,
      [target.name]: target.value
    }));
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);

    setSelectedPreview(url);
    setSelectedImage(file);
  };

  const handleSubmit = async () => {
    const clientId = client.id;

    await clientService.UpdateCurrentClient({
      clientId,
      name: data.name,
      email: data.email,
      telegram: data.telegram,
      instagram: data.instagram,
      birthday: data.birthday
    });
    if (selectedImage) {
      const formData = new FormData();

      formData.append('image', selectedImage);
      await clientService.UploadClientImage(clientId, formData);
    }
    notify();
  };

  return (
    <div className="container-fluid relative mx-auto flex h-[calc(100vh-252px)] items-start justify-center bg-cream max-md:text-sm">
      <ContainerBox>
        <div className="flex justify-between">
          <div className="max-md:flex max-md:w-full max-md:flex-row-reverse max-md:items-center max-md:justify-between">
            <h2 className="text-2xl text-darkBrown max-md:mr-[6px] max-md:text-lg">
              {dictionary[selectedLanguage].yourProfile}
            </h2>
            <p className="mb-[24px] text-brown max-md:mb-0 max-md:text-lg max-md:text-darkBrown">
              {client ? client.phone : ''}
            </p>
          </div>
        </div>

        {client
          ? (
          <div className="mx-auto w-full md:flex md:flex-col">
            <p className="mb-[24px] text-xl text-brown max-md:mb-0 max-md:text-lg">
              {dictionary[selectedLanguage].profileEdit}
            </p>
            <div className="md:grid md:grid-cols-3">
              <div className="p-[10px] max-md:py-0">
                <TextField
                  name={'name'}
                  type={'text'}
                  placeholder={dictionary[selectedLanguage].yourName}
                  label={dictionary[selectedLanguage].userName}
                  value={data.name}
                  onChange={handleChange}
                ></TextField>
                <label
                  className="mb-[8px] block text-lg text-brown max-md:text-sm"
                  htmlFor={'birthday'}
                >
                  {dictionary[selectedLanguage].birthday}
                </label>
                <input
                  type="date"
                  name="birthday"
                  value={data.birthday ?? client?.birthdate}
                  onChange={(e) => {
                    setData((previousState) => ({
                      ...previousState,
                      birthday: e.target.value
                    }));
                  }
                  }
                  className="mb-[8px] w-full rounded-lg border border-lightBrown px-[8px] py-[7px] text-lightBrown"
                />
              </div>
              <div className="p-[10px]">
                <TextField
                  name={'telegram'}
                  type={'text'}
                  placeholder={'@telegram'}
                  label={'Telegram'}
                  value={data.telegram}
                  onChange={handleChange}
                ></TextField>
                <TextField
                  name={'instagram'}
                  type={'text'}
                  placeholder={'@instagram'}
                  label={'Instagram'}
                  value={data.instagram}
                  onChange={handleChange}
                ></TextField>
                <TextField
                  name={'email'}
                  type={'text'}
                  placeholder={'email@email.com'}
                  label={'E-mail'}
                  value={data.email}
                  onChange={handleChange}
                ></TextField>
              </div>
              <div className="p-[10px]">
                <img
                  src={selectedPreview}
                  alt=""
                  className="mx-auto size-[155px] rounded-full"
                />
                <label
                  className="mb-[8px] block text-lg text-brown"
                  htmlFor={'file'}
                >
                  {'Фото'}
                </label>
                <input
                  className="block w-full cursor-pointer rounded-lg border border-lightBrown bg-white px-3 py-2 text-sm text-lightBrown file:cursor-pointer file:rounded-lg file:border file:border-lightBrown file:bg-cream file:text-brown file:outline-none placeholder:text-lightBrown file:hover:opacity-80 focus:outline-none"
                  id={'file'}
                  name={'file'}
                  type={'file'}
                  accept={windowWidth < 800 ? 'image/*, file/*' : 'image/*'}
                  placeholder={''}
                  onChange={handleImageChange}
                />
              </div>
            </div>

            <button
              className="mx-auto mt-[24px] w-[25%] rounded-lg bg-brown py-[8px] text-center text-white hover:opacity-80"
              onClick={handleSubmit}
            >
              {dictionary[selectedLanguage].confirm}
            </button>
          </div>
            )
          : (
              'loading'
            )}
      </ContainerBox>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default ClientProfilePage;
