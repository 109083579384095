export enum FormFields {
  NAME = 'name',
  EMAIL = 'email',
  TELEGRAM = 'telegram',
  INSTAGRAM = 'instagram',
  BIRTHDAY = 'birthday',
  COMMUNICATION = 'communication',
}

export interface Form {
  [FormFields.NAME]?: string;
  [FormFields.EMAIL]?: string | null;
  [FormFields.TELEGRAM]?: string;
  [FormFields.INSTAGRAM]?: string;
  [FormFields.BIRTHDAY]?: string | null;
  [FormFields.COMMUNICATION]?: boolean;
}
