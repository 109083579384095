import React from 'react';
import PropTypes from 'prop-types';
import star from '../../assets/imgs/Star 6.png';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedMaster } from '../../store/userSlice';
import dictionary from '../../utils/dictionary/dictionary';

const MasterElement = ({ user, lastEl }) => {
  const selectedLanguage = useSelector((state) => state.lang.lang);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClick = (user) => {
    dispatch(setSelectedMaster(user));
    navigate('/client/record');
  };

  return (
    <>
      <div
        className={
          lastEl
            ? 'grid grid-cols-5 rounded-b-lg border border-gray'
            : 'grid grid-cols-5 border border-gray'
        }
        key={user.id}
      >
        <Link to={`/client/master/${user.id}`}>
          <div className="flex px-[20px] py-[12px] max-md:px-[10px] max-md:py-[6px]">
            <img
              className="mr-[5px] size-[26px] rounded-full max-md:mr-[2px] max-md:hidden max-md:size-[15px]"
              src={user.image}
              alt=""
            />
            {user.name}
          </div>
        </Link>
        <div className="px-[20px] py-[12px] max-md:px-[10px] max-md:py-[6px]">
          {user.experience}
        </div>
        <div className="px-[20px] py-[12px] max-md:px-[10px] max-md:py-[6px]">
          {user.position}
        </div>
        <div className="max-md:items:center flex px-[20px] py-[12px] max-md:justify-center max-md:px-[10px] max-md:py-[6px]">
          {user.image
            ? (
            <img
              className="mr-[5px] size-[20px] items-center max-md:size-[15px]"
              src={star}
              alt=""
            />
              )
            : null}
          {user.rating}
        </div>
        <button
          className="px-[20px] py-[12px] text-start hover:opacity-80 max-md:px-[10px] max-md:py-[6px]"
          onClick={() => { handleClick(user); }}
        >
          {dictionary[selectedLanguage].record}
        </button>
      </div>
    </>
  );
};

MasterElement.propTypes = {
  user: PropTypes.object.isRequired,
  lastEl: PropTypes.bool
};

export default MasterElement;
