import React from 'react';
import brownTriangle from 'src/assets/imgs/brownTriangle.png';

interface Props {
  isLeftErrorHidden: boolean;
  isRightErrorHidden: boolean;
  onChangeDate: (value: number) => void;
}

const Header: React.FC<Props> = ({
  isLeftErrorHidden,
  isRightErrorHidden,
  onChangeDate,
}) => (
  <>
    <img
      className={`absolute left-[5px] top-[18px] z-50 h-[20px] w-[15px] -rotate-90 ${isLeftErrorHidden ? 'hidden' : ''}`}
      src={brownTriangle}
      alt="arrow-left"
      onClick={() => onChangeDate(-1)}
    />
    <img
      className={`absolute right-[5px] top-[18px] z-50 h-[20px] w-[15px] rotate-90 ${
        isRightErrorHidden ? 'hidden' : ''
      }`}
      src={brownTriangle}
      alt="arrow-right"
      onClick={() => onChangeDate(1)}
    />
  </>
);

export default Header;
