import classNames from 'classnames';
import Completed from 'src/assets/imgs/completed.svg';
import Created from 'src/assets/imgs/created.svg';
import Canceled from 'src/assets/imgs/canceled.svg';
import { ButtonStatus } from './constants';

interface Props {
  type: ButtonStatus;
  selectedStatus?: ButtonStatus;
  onSelect: (type: ButtonStatus) => void;
}

const StatusButton: React.FC<Props> = ({ type, selectedStatus, onSelect }) => (
  <span
    className={classNames('size-[25px] p-[2px] mr-[10px] hover:opacity-100', {
      'opacity-100': selectedStatus === type,
      'opacity-50': selectedStatus !== type,
    })}
    onClick={() => onSelect(type)}
  >
    {(() => {
      switch (type) {
        case ButtonStatus.COMPLETED: {
          return <img src={String(Completed)} alt="completed" />;
        }
        case ButtonStatus.CANCELED: {
          return <img src={String(Canceled)} alt="canceled" />;
        }
        case ButtonStatus.CREATED: {
          return <img src={String(Created)} alt="created" />;
        }
        default:
      }
    })()}
  </span>
);

export default StatusButton;
