import React, { useState } from 'react';
import AuthBackground from '../components/auth/authBackground';
import AuthCard from '../components/auth/authCard';
import TextField from '../components/ui/text-field/textField';
import dictionary from '../utils/dictionary/dictionary';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import userService from '../services/user.service';
import ReCAPTCHA from 'react-google-recaptcha';

const CrmPasswordRecoveryPage = () => {
  const selectedLanguage = useSelector((state) => state.lang.lang);
  const navigate = useNavigate();
  const [data, setData] = useState({
    phone: '+'
  });

  let phoneError;

  if (data.phone.startsWith('+3') && data.phone.length !== 13) {
    phoneError = 'phone must contain 12 digits';
  }
  if (data.phone.startsWith('+7') && data.phone.length !== 12) {
    phoneError = 'phone must contain 11 digits';
  }
  if (
    !data.phone.startsWith('+3') &&
    !data.phone.startsWith('+7') &&
    data.phone.length > 1
  ) {
    phoneError = "phone must start  with '7' or '3'";
  }

  const handleChange = (target) => {
    if (data.phone.startsWith('+')) {
      setData((previousState) => ({
        ...previousState,
        [target.name]: target.value
      }));
    } else {
      setData((previousState) => ({
        ...previousState,
        [target.name]: '+' + target.value
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await userService.passwordRecovery(data.phone);
    navigate('/crm/login');
  };

  const [reCaptcha, setReCaptcha] = useState(null);
  const recaptchaReference = React.createRef();
  const handleReCaptcha = () => {
    const recaptchaValue = recaptchaReference.current.getValue();

    setReCaptcha(recaptchaValue);
  };
  const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_WEB_KEY;

  return (
    <div className="container-fluid relative mx-auto flex h-screen items-center justify-center bg-cream max-md:text-sm">
      <AuthCard>
        <form onSubmit={handleSubmit}>
          <p className="mb-[4px] text-2xl text-brown max-md:text-lg">
            {dictionary[selectedLanguage].passwordRecovery}
          </p>
          <p className="mb-[4px] text-sm text-brown">
            {dictionary[selectedLanguage].passwordRecoveryDescrcription}
          </p>
          <TextField
            name="phone"
            label={dictionary[selectedLanguage].phoneNumber}
            placeholder="+1234567890"
            type="text"
            value={data.phone}
            onChange={handleChange}
            error={phoneError}
          />
          <div className="mt-[10px] flex w-full items-center justify-center">
            <ReCAPTCHA
              ref={recaptchaReference}
              sitekey={reCaptchaKey}
              onChange={handleReCaptcha}
              size="compact"
            />
          </div>
          <button
            className="mt-[24px] w-full rounded-lg bg-brown py-[8px] text-center text-white hover:opacity-80"
            disabled={
              !!(phoneError || data.phone.length < 2 || !reCaptcha)
            }
          >
            {dictionary[selectedLanguage].recover}
          </button>
        </form>
      </AuthCard>

      <AuthBackground></AuthBackground>
    </div>
  );
};

export default CrmPasswordRecoveryPage;
