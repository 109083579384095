import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MasterPageRecordElement from './masterPageRecordElement';
import clientService from '../../services/client.service';
import recordService from '../../services/record.service';
import serviceService from '../../services/service.service';
import dictionary from '../../utils/dictionary/dictionary';
import { useSelector } from 'react-redux';

const MasterPageRecords = ({ userId, date }) => {
  const selectedLanguage = useSelector((state) => state.lang.lang);
  const [clients, setClients] = useState(null);
  const [records, setRecords] = useState(null);
  const [services, setServices] = useState(null);

  const loadData = async () => {
    setClients(await clientService.getClients());
    setRecords(await recordService.getRecords());
    setServices(await serviceService.getServices());
  };

  useEffect(() => {
    loadData();
  }, []);

  const userRecords = records
    ? records
      .filter((record) => record.user.id === userId)
      .filter((record) => record.date === date)
    : null;

  const recordsElements =
    userRecords && userRecords.length > 0
      ? userRecords.map((record) => (
          <MasterPageRecordElement
            record={record}
            lastEl={userRecords.at(-1) === record}
            services={services}
            clients={clients}
            key={record.id}
          ></MasterPageRecordElement>
      ))
      : dictionary[selectedLanguage].noData;

  return userRecords
    ? (
    <>
      <h2 className="mt-[32px] text-2xl text-brown max-md:mt-[5px] max-md:text-lg">
        {dictionary[selectedLanguage].recordHistory}
      </h2>
      <div className="pt-[32px] text-darkBrown max-md:pt-[5px] max-md:text-xs">
        <div className="grid grid-cols-6 rounded-t-lg border border-gray bg-lightGray font-bold max-md:grid-cols-12">
          <div className="px-[20px] py-[12px] max-md:col-span-2 max-md:p-[6px]">
            {dictionary[selectedLanguage].client}
          </div>
          <div className="px-[20px] py-[12px] max-md:p-[6px]">
            #
          </div>
          <div className="px-[20px] py-[12px] max-md:col-span-3 max-md:p-[6px]">
            {dictionary[selectedLanguage].service}
          </div>
          <div className="px-[20px] py-[12px] max-md:col-span-2 max-md:p-[6px]">
            {dictionary[selectedLanguage].price}
          </div>
          <div className="px-[20px] py-[12px] max-md:col-span-2 max-md:p-[6px]">
            {dictionary[selectedLanguage].time}
          </div>
          <div className="px-[20px] py-[12px] max-md:col-span-2 max-md:p-[6px]">
            {dictionary[selectedLanguage].photo}
          </div>
        </div>
        {recordsElements}
      </div>
    </>
      )
    : null;
};

MasterPageRecords.propTypes = {
  userId: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired
};

export default MasterPageRecords;
