import { FormFields } from './types';

export const getFields = () => [
  {
    name: FormFields.PHONE,
    labelKey: 'clientsPhone',
    placeholder: '+71234567890',
  },
  { name: FormFields.INSTAGRAM, labelKey: 'clientsInstagram' },
  { name: FormFields.TELEGRAM, labelKey: 'clientsTelegram' },
  { name: FormFields.NAME, labelKey: 'clientsName' }
];
