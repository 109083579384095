import * as Yup from 'yup';
import { FormFields } from './types';

const yupSchema = Yup.object().shape({
  [FormFields.NAME]: Yup.string(),
  [FormFields.EMAIL]: Yup.string().nullable(),
  [FormFields.TELEGRAM]: Yup.string(),
  [FormFields.INSTAGRAM]: Yup.string(),
  [FormFields.BIRTHDAY]: Yup.string().nullable(),
  [FormFields.COMMUNICATION]: Yup.bool(),
});

export default yupSchema;
