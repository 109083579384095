/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { ToastContainer } from 'react-toastify';
import React, { useEffect, useMemo, useState } from 'react';
import DetailedRecordInfo from '../common/detailed-record-info/detailedRecordInfo';
import dictionary from '../../../utils/dictionary/dictionary';
import { useAppDispatch, useAppSelector } from 'src/hooks/api';
import {
  useGetComplexesQuery,
  useGetServicesQuery,
} from 'src/services/services/services.service';
import { Complex, Service } from 'src/services/services/types';
import {
  removeSelectedSlots,
  selectComplex,
  selectService,
  selectSlot,
  setComplexNumber,
} from 'src/reducers/services/servicesSlice';
import { User } from 'src/services/users/types';
import EditorForm from './components/editor-form/editorForm';
import { ServiceSelectionList } from './components/service-selection-list/serviceSelectionList';
import {
  useGetClientByInstagramQuery,
  useGetClientByPhoneQuery,
  useGetClientByTelegramQuery,
} from 'src/services/clients/clients.service';
import { Client } from 'src/services/clients/types';
import { DEFAULT_FORM_VALUES } from './components/editor-form/constants';
import { FormState } from './components/editor-form/types';
import { Link } from 'react-router-dom';

interface Props {
  currentUser: User;
}

const AdminRecordEditor: React.FC<Props> = ({
  currentUser,
}) => {
  const dispatch = useAppDispatch();
  const selectedLanguage = useAppSelector((state) => state.lang.lang);
  const {
    selectedService,
    selectedSlots,
    slotNumberForChanges,
    selectedComplex,
    complexNumber,
  } = useAppSelector((state) => state.services);

  const [client, setClient] = useState<Client | null>(null);
  const [search, setSearch] = useState('');
  const [isServiceTypeSelect, setIsServiceTypeSelect] = useState(true);
  const [isNumberValid, setIsNumberValid] = useState(false);
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [formData, setFormData] = useState(DEFAULT_FORM_VALUES);
  const [dropdownServices, setDropdownServices] = useState<
  Service[] | Complex[]
  >();
  const { data: complexesData } = useGetComplexesQuery();
  const { data: servicesData } = useGetServicesQuery();
  const { data: clientByPhone } = useGetClientByPhoneQuery(formData.phone, {
    skip: formData.phone.length < 11 || !isNumberValid,
  });
  const { data: clientByInstagram } = useGetClientByInstagramQuery(
    formData.instagram,
    {
      skip: !formData.instagram || !!client,
    },
  );
  const { data: clientByTelegram } = useGetClientByTelegramQuery(
    formData.telegram,
    {
      skip: !formData.telegram || !!client,
    },
  );

  useEffect(() => {
    clientByPhone
      ? setClient(clientByPhone)
      : clientByInstagram
        ? setClient(clientByInstagram)
        : clientByTelegram
          ? setClient(clientByTelegram)
          : null;
  }, [clientByPhone, clientByInstagram, clientByTelegram]);

  const services = useMemo(() => {
    if (servicesData) {
      return servicesData.filter((service) => service.active);
    }

    return [];
  }, [servicesData]);

  useEffect(() => {
    if (isServiceTypeSelect) {
      if (search.length) {
        setDropdownServices(
          services.filter((service) =>
            String(service?.[selectedLanguage as keyof Service])
              .toLowerCase()
              .includes(search.toLowerCase()),
          ),
        );
      } else {
        setDropdownServices(
          services.filter((service) => service !== selectedService),
        );
      }
    } else {
      if (search.length > 0) {
        setDropdownServices(
          complexesData?.filter((complex) =>
            String(complex?.[selectedLanguage as keyof Complex])
              .toLowerCase()
              .includes(search.toLowerCase()),
          ),
        );
      } else {
        setDropdownServices(
          complexesData?.filter(
            (complex) => complex?.id !== selectedComplex?.id,
          ),
        );
      }
    }
  }, [
    complexesData,
    search,
    selectedComplex?.id,
    selectedLanguage,
    selectedService,
    isServiceTypeSelect,
    services,
  ]);

  const handleSelect = (item?: Complex | Service) => {
    const isComplex = 'services' in (item ?? {});
    const complex = isComplex ? (item as Complex) : null;
    const service = isComplex ? null : (item as Service);

    dispatch(setComplexNumber(0));
    dispatch(selectComplex(complex));
    dispatch(
      selectService(
        isComplex ? complex?.services[complexNumber] ?? null : service ?? null,
      ),
    );
    dispatch(selectSlot(null));
    dispatch(removeSelectedSlots());
    setSearch('');
    setIsSearchVisible(false);
  };

  const dropdownList = dropdownServices?.map((service) => (
    <div
      key={service.id}
      className="relative z-10 cursor-pointer border-b border-gray bg-white px-[16px] py-[7px] text-brown first:rounded-t-lg last:rounded-b-lg last:border-none hover:text-lightBrown"
      onClick={() => handleSelect(service)}
    >
      {service[selectedLanguage]}
    </div>
  ));

  const getSelectedText = () => {
    if (!selectedComplex) return null;

    const { confirmComplex, currentSelection } = dictionary[selectedLanguage];

    return selectedSlots.length === selectedComplex.services.length
      ? confirmComplex
      : `${currentSelection as string} "${selectedService?.[selectedLanguage]}"`;
  };

  return (
    <div className="h-full rounded-lg border border-gray px-[16px] py-[12px] text-darkBrown">
      {slotNumberForChanges
        ? (
        <DetailedRecordInfo
          recordId={slotNumberForChanges}
          currentUser={currentUser}
        />
          )
        : (
        <>
          <h3 className="mb-[20px] font-bold">
            {dictionary[selectedLanguage].editBooking}
          </h3>
          <p>{getSelectedText()}</p>
          <p className="mb-[16px] font-thin">
            {dictionary[selectedLanguage].service}
          </p>

          <ServiceSelectionList
            search={search}
            isServiceTypeSelect={isServiceTypeSelect}
            isSearchVisible={isSearchVisible}
            setIsSearchVisible={setIsSearchVisible}
            onChevronBarClick={() =>
              setIsServiceTypeSelect(!isServiceTypeSelect)
            }
            onChange={(value: string) => setSearch(value)}
          >
            {dropdownList}
          </ServiceSelectionList>
          {client
            ? (
            <>
              <p className="mb-[8px] font-thin">
                {dictionary[selectedLanguage].clientFound}
              </p>
              <div className="relative flex items-center">
                <Link
                  className="absolute right-2"
                  to={'/crm/client/' + client.id}
                >
                  ⓘ
                </Link>
                <div className="w-full rounded-lg border border-darkGreen px-3 py-2 text-darkGreen">
                  {client?.name ?? client?.phone}
                </div>
              </div>
            </>
              )
            : null}
          <EditorForm
            client={client}
            setIfFormValid={setIsNumberValid}
            onChange={(data: FormState) => setFormData(data)}
            onFormSubmit={() => {
              setClient(null);
            }}
          />

          <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </>
          )}
    </div>
  );
};

export default AdminRecordEditor;
