import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import localStorageService from '../../services/local-storage/localStorage.service';
import { LanguageState } from './types';
import { LanguageKeys } from 'src/utils/dictionary/types';

const selectedLanguage = localStorageService.getLanguage() as LanguageKeys;

const initialState: LanguageState = {
  lang: selectedLanguage ?? LanguageKeys.EN,
};

const languageSlice = createSlice({
  name: 'lang',
  initialState,
  reducers: {
    setStoreLanguage: (state, action: PayloadAction<LanguageKeys>) => {
      state.lang = action.payload;
    },
  },
});

export const { setStoreLanguage } = languageSlice.actions;

export default languageSlice.reducer;
