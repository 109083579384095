export const transformUserName = (name?: string) => {
  if (name) {
    const nameArray = name.split(' ');

    return nameArray.length > 1
      ? nameArray[0] + ' ' + nameArray[1][0] + '.'
      : name;
  }

  return name;
};
