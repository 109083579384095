import React, { useMemo } from 'react';
import classNames from 'classnames';
import { transformUserName } from './helpers';
import localStorageService from 'src/services/local-storage/localStorage.service';
import { useGetUserByIdQuery } from 'src/services/users/users.services';

interface Props {
  name?: string;
  imageUrl?: string;
  openUserModal: () => void;
}

export const UserMiniature: React.FC<Props> = ({
  name,
  imageUrl,
  openUserModal,
}) => {
  const userId = localStorageService.getUserId();
  const { data: userData } = useGetUserByIdQuery(Number(userId));

  const appUser = useMemo(() => {
    if (userData) {
      return userData;
    }
  }, [userData]);

  return (
    <div
      className={classNames(
        'flex h-[60px] items-center justify-center text-sm',
        {
          'cursor-pointer hover:opacity-50': appUser?.isAdmin,
        },
      )}
      onClick={openUserModal}
    >
      {name && imageUrl
        ? (
        <img
          className="mr-[6px] size-[38px] rounded-full"
          src={imageUrl}
          alt=""
        />
          )
        : null}
      <p>{transformUserName(name)}</p>
    </div>
  );
};
