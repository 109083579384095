import React from 'react';
import { RecordApi } from 'src/services/records/types';

interface Props {
  record?: RecordApi;
}

const HistoryList: React.FC<Props> = ({ record }) => {
  if (!record) {
    return null;
  }

  return (
    <div className="max-h-[800px] w-full overflow-y-scroll">
      {Object.keys(record?.history)
        .reverse()
        .map((key) => (
          <p key={key} className="mb-[2px]">
            <b>{key}:</b> {record?.history[key]}
          </p>
        ))}
    </div>
  );
};

export default HistoryList;
