import React from 'react';
import { useDispatch } from 'react-redux';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker, { Locale } from '@hassanmojab/react-modern-calendar-datepicker';
import dictionary from '../../../utils/dictionary/dictionary';
import brownTriangle from '../../../assets/imgs/brownTriangle.png';
import { setDate } from 'src/reducers/date/dateSlice';
import { getCustomLocale, transformDate } from './helpers';
import { Day } from './types';
import { MS_ON_DAY } from './constants';
import { useAppSelector } from 'src/hooks/api';

interface Props {
  currentDate: Date
  onSelectDate: (date: Date) => void
}

const Calendar: React.FC<Props> = ({ currentDate, onSelectDate }) => {
  const dispatch = useDispatch();
  const selectedLanguage = useAppSelector((state) => state.lang.lang);
  const months = dictionary[selectedLanguage].firstMonth;

  const renderCustomInput = ({ ref }: { ref: React.RefObject<HTMLElement> }) => (
    <input
      readOnly
      ref={ref as React.RefObject<HTMLInputElement>}
      value={`${currentDate.getDate()} ${months[currentDate.getMonth()]} ${
        currentDate.getFullYear()
        }`}
      style={{
        fontSize: '16px',
        color: 'rgba(158, 117, 49, 0.7)',
        outline: 'none',
        cursor: 'pointer',
        textDecoration: 'underline',
        textAlign: 'center'
      }}
    />
  );

  const handleDateSelect = (date?: Day) => {
    if (date) {
      onSelectDate(new Date(date.year, date.month - 1, date.day));
      const dateStore = `${date.day}.${date.month}.${date.year}`;

      dispatch(setDate(dateStore));
    }
  };

  return (
    <div className="flex items-center">
    <img
      className="h-[15px] w-[12px] -rotate-90"
      src={brownTriangle}
      alt="arrow-left"
      onClick={() => {
        handleDateSelect(
          transformDate(new Date(currentDate.getTime() - MS_ON_DAY))
        );
      }
      }
    />
    <DatePicker
      value={transformDate(currentDate)}
      onChange={handleDateSelect}
      colorPrimary="#9E7531"
      colorPrimaryLight="#9E7531"
      renderInput={renderCustomInput}
      shouldHighlightWeekends
      locale={getCustomLocale(selectedLanguage) as Locale}
    />
    <img
      className="h-[15px] w-[12px] rotate-90"
      src={brownTriangle}
      alt="arrow-right"
      onClick={() => {
        handleDateSelect(
          transformDate(new Date(currentDate.getTime() + MS_ON_DAY))
        );
      }
      }
    />
  </div>

  );
};

export default Calendar;
