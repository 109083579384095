import React from 'react';
import PropTypes from 'prop-types';
import MasterElement from './masterElement';
import dictionary from '../../utils/dictionary/dictionary';
import { useSelector } from 'react-redux';

const MastersList = ({ users }) => {
  const selectedLanguage = useSelector((state) => state.lang.lang);
  const userElements = users
    ? users.map((user) => (
        <MasterElement
          user={user}
          lastEl={user === users.at(-1)}
        ></MasterElement>
    ))
    : null;

  return (
    <div className="pt-[32px] text-darkBrown">
      <div className="grid grid-cols-5 rounded-t-lg border border-gray bg-lightGray  font-bold">
        <div className="px-[20px] py-[12px] max-md:px-[10px] max-md:py-[6px]">
          {dictionary[selectedLanguage].userName}
        </div>
        <div className="px-[20px] py-[12px] max-md:px-[10px] max-md:py-[6px]">
          {dictionary[selectedLanguage].expShort}
        </div>
        <div className="px-[20px] py-[12px] max-md:px-[10px] max-md:py-[6px]">
          {dictionary[selectedLanguage].position}
        </div>
        <div className="px-[20px] py-[12px] max-md:px-[10px] max-md:py-[6px]">
          {dictionary[selectedLanguage].rating}
        </div>
        <div className="px-[20px] py-[12px] max-md:px-[10px] max-md:py-[6px]">
          {dictionary[selectedLanguage].record}
        </div>
      </div>
      {userElements}
    </div>
  );
};

MastersList.propTypes = {
  users: PropTypes.array
};

export default MastersList;
