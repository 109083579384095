import React, { useState } from 'react';
import PropTypes from 'prop-types';
import star from '../../assets/imgs/Star 6.png';
import { Link } from 'react-router-dom';
import Close from '../../assets/imgs/plus-circle.png';
import Rating from 'react-rating';
import starEmpty from '../../assets/imgs/star-empty.png';
import starFull from '../../assets/imgs/star-full.png';
import localStorageService from '../../services/local-storage/localStorage.service';
import commentService from '../../services/comment.service';
import dictionary from '../../utils/dictionary/dictionary';
import { useSelector } from 'react-redux';

const ClientElement = ({ client, records, comments, lastEl }) => {
  const selectedLanguage = useSelector((state) => state.lang.lang);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const recordIndexes = new Set(records.map((record) => record.id));
  const recordIndexesWithComment = [];

  for (const comment of comments) {
    if (recordIndexes.has(comment.recordId)) {
      recordIndexesWithComment.push(comment.recordId);
    }
  }
  const recordsWithoutComment = records.filter(
    (record) =>
      !recordIndexesWithComment.includes(record.id) &&
      !['Day off', 'Odmar 1', 'Odmar 2', 'Odmar 4', 'Odmar 0.5'].includes(
        record?.service?.en
      ) &&
      record.clientId === client.id
  );
  const recordsElements = recordsWithoutComment.map((record) => (
    <div
      className=" mb-[4px] grid cursor-pointer grid-cols-4 opacity-70 hover:opacity-100"
      onClick={() => { setSelectedRecord(record); }}
    >
      <p className="col-span-2">{record?.service[selectedLanguage]}</p>
      <p className="">{record.date}</p>
      <p className="">{record.time.slice(0, 5)}</p>
    </div>
  ));

  const [blur, setBlur] = useState(false);
  const [selectedRating, setSelectedRating] = useState();
  const [comment, setComment] = useState('');
  const onRatingSelected = (selectedValue) => {
    setSelectedRating(selectedValue);
  };

  const handleClick = () => {
    setBlur(!blur);
    setSelectedRecord(null);
  };

  const handleSubmit = async () => {
    const userId = localStorageService.getUserId();

    await commentService.createComment(
      null,
      userId,
      selectedRecord.id,
      comment,
      selectedRating
    );
    setBlur(!blur);
  };

  return (
    <>
      <div
        className={
          lastEl
            ? 'grid grid-cols-3 rounded-b-lg border border-gray text-darkBrown  max-md:grid-cols-5'
            : 'grid grid-cols-3 border border-gray text-darkBrown  max-md:grid-cols-5'
        }
        key={client.id}
      >
        <Link to={'/crm/client/' + client.id} className="max-md:col-span-2">
          <div className="flex px-[20px] py-[12px] max-md:px-[10px] max-md:py-[6px]">
            {client.image
              ? (
              <img
                className="mr-[5px] size-[26px] max-md:hidden"
                src={client.image}
                alt=""
              />
                )
              : null}
            {client.name}
          </div>
        </Link>
        <div className="flex px-[20px] py-[12px] max-md:px-[10px] max-md:py-[6px]">
          <img
            className="mr-[5px] size-[20px] items-center"
            src={star}
            alt=""
          />
          {client.rating}
        </div>
        <button
          className="px-[20px] py-[12px] text-start hover:opacity-80 max-md:col-span-2 max-md:px-[10px] max-md:py-[6px]"
          onClick={handleClick}
        >
          {dictionary[selectedLanguage].feedbackClient}
        </button>
        <span
          className={
            blur
              ? 'absolute left-0 top-[-252px] z-[100] flex h-screen w-screen items-center justify-center bg-transparent backdrop-blur-lg max-md:top-[-278px]'
              : 'collapse absolute left-0 top-[-252px] z-[100] flex h-screen w-screen items-center justify-center bg-transparent backdrop-blur-lg max-md:top-[-278px]'
          }
        >
          <div className="w-[600px]">
            <div className="mb-[16px] flex w-full justify-between">
              <h3 className="text-base text-white">
                {selectedRecord
                  ? dictionary[selectedLanguage].giveFeedback
                  : dictionary[selectedLanguage].selectRecord}
              </h3>
              <img
                className="cursor-pointer"
                onClick={(event_) => { handleClick(event_); }}
                src={Close}
                alt=""
              />
            </div>
            {selectedRecord
              ? (
              <p
                className="mb-[10px] cursor-pointer text-white"
                onClick={() => { setSelectedRecord(null); }}
              >
                {dictionary[selectedLanguage].back}
              </p>
                )
              : null}
            {selectedRecord
              ? (
              <>
                <textarea
                  onChange={(event) => { setComment(event.target.value); }}
                  className="mb-[16px] w-full rounded-lg bg-white p-[10px] text-black outline-none"
                ></textarea>
                <div className="flex justify-between">
                  <Rating
                    initialRating={selectedRating}
                    onClick={onRatingSelected}
                    emptySymbol={
                      <img src={starEmpty} alt="empty star" className="icon" />
                    }
                    fullSymbol={
                      <img
                        src={starFull}
                        alt="fullfilled star"
                        className="icon"
                      />
                    }
                  />
                  <button
                    className="rounded-lg bg-black px-[12px] py-[10px] text-white hover:opacity-80"
                    onClick={handleSubmit}
                  >
                    {dictionary[selectedLanguage].confirm}
                  </button>
                </div>
              </>
                )
              : (
              <div className="rounded-lg bg-white p-[12px]">
                <div className=" mb-[6px] grid grid-cols-4">
                  <p className="col-span-2 font-bold">
                    {dictionary[selectedLanguage].theRecord}
                  </p>
                  <p className="font-bold">
                    {dictionary[selectedLanguage].date}
                  </p>
                  <p className="font-bold">
                    {dictionary[selectedLanguage].time}
                  </p>
                </div>
                {recordsElements}
              </div>
                )}
          </div>
        </span>
      </div>
    </>
  );
};

ClientElement.propTypes = {
  client: PropTypes.object.isRequired,
  records: PropTypes.array,
  comments: PropTypes.array,
  lastEl: PropTypes.bool
};

export default ClientElement;
