import axios from 'axios';
import apiEndpoint from './constants';

const httpService = axios.create({
  baseURL: `${apiEndpoint}/api/services/`,
});

const serviceService = {
  getServices: async () => {
    const { data } = await httpService.get('');

    return data;
  },

  getComplexes: async () => {
    const { data } = await httpService.get('complexes');

    return data;
  },
};

export default serviceService;
