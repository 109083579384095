import { useSelector } from 'react-redux';
import dictionary from '../../utils/dictionary/dictionary';
import Image from '../../assets/imgs/base/Image.png';

const Home = () => {
  const selectedLanguage = useSelector((state) => state.lang.lang);

  return (
    <section
      id="Home"
      className="relative bg-[#F7F4EA] pb-[24px] pt-[250px] font-serif max-[400px]:pt-[175px]"
    >
      <div className="container mx-auto items-center justify-center gap-2 max-[400px]:p-2 sm:mx-auto md:mx-auto">
        <h1 className="text-center text-5xl font-bold text-[#9C783E] max-[400px]:text-2xl">
          {dictionary[selectedLanguage].unlock}
        </h1>
        <p className="mx-auto mb-8 mt-4 max-w-4xl text-center text-base font-normal italic text-[#9C783E]">
          {dictionary[selectedLanguage].unlockLg}
        </p>
        <div className="mx-auto flex max-w-6xl justify-center overflow-hidden rounded-3xl bg-cover bg-no-repeat">
          <img
            className="max-w-6xl justify-center rounded-3xl transition duration-300 ease-in-out hover:scale-110 hover:rounded-3xl max-[400px]:max-w-3xl"
            src={Image}
            alt=""
          />
        </div>
      </div>
    </section>
  );
};

export default Home;
