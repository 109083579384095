import { Link } from 'react-router-dom';
import {
  Link as SLink,
  Events,
  scrollSpy
} from 'react-scroll';

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import dictionary from '../../utils/dictionary/dictionary';
import Logo from '../../assets/imgs/base/logo.svg';
import LanguageSelector from '../ui/languageSelector';

const Header = () => {
  useEffect(() => {
    scrollSpy.update();

    return () => {
      Events.scrollEvent.remove('begin');
      Events.scrollEvent.remove('end');
    };
  }, []);

  const selectedLanguage = useSelector((state) => state.lang.lang);

  return (
    <header
      id="main-header"
      className="fixed inset-x-0 top-0 z-[999] mt-0 w-screen border-b border-[#9C783E] bg-[#F7F4EA] pt-[4px] max-[400px]:pt-[16px]"
    >
      <div className="container mx-auto flex items-center  justify-between px-8 max-[740px]:p-4 min-[420px]:flex-wrap">
        <LanguageSelector></LanguageSelector>

        <div className="max-[400px]:hidden"></div>

        <div
          id="logo"
          className="mb-[6px] flex justify-center max-[400px]:mb-0"
        >
          <img src={Logo} alt="Logo" className="w-full max-[400px]:w-[175px]" />
        </div>

        <div>
          <Link
            to="/client/login"
            className="rounded-md bg-brown px-8 py-4 font-serif text-white transition hover:bg-lightBrown max-[400px]:p-2 max-[400px]:text-xs"
          >
            {dictionary[selectedLanguage].login}
          </Link>
        </div>
      </div>

      <div className="m-[8px] mx-auto flex justify-center">
        <nav>
          <ul className="flex flex-wrap justify-center gap-5 font-serif text-brown max-[740px]:gap-2 max-[400px]:gap-0">
            <li>
              <SLink
                activeClass="active"
                to="Home"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
                className="max-[740px] scroll-smooth rounded-md px-8 py-2 transition hover:bg-[#FFF8E1]  max-[400px]:text-sm"
              >
                {dictionary[selectedLanguage].home}
              </SLink>
            </li>
            <li>
              <SLink
                activeClass="active"
                to="Portfolio"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
                className="max-[740px] rounded-md px-8 py-2 transition hover:bg-[#FFF8E1] max-[400px]:text-sm"
              >
                {dictionary[selectedLanguage].portfolio}
              </SLink>
            </li>
            <li>
              <SLink
                activeClass="active"
                to="About-us"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
                className="max-[740px] rounded-md px-8 py-2 transition hover:bg-[#FFF8E1] max-[400px]:text-sm"
              >
                {dictionary[selectedLanguage].about}
              </SLink>
            </li>
            <li>
              <SLink
                activeClass="active"
                to="Team"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
                className="max-[740px] rounded-md px-8 py-2 transition hover:bg-[#FFF8E1] max-[400px]:text-sm"
              >
                {dictionary[selectedLanguage].team}
              </SLink>
            </li>
            <li>
              <SLink
                activeClass="active"
                to="Contact"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
                className="max-[740px] rounded-md px-8 py-2 transition hover:bg-[#FFF8E1] max-[400px]:text-sm"
              >
                {dictionary[selectedLanguage].contacts}
              </SLink>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
