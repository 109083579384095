import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import apiEndpoint from '../constants';
import { User } from './types';

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiEndpoint}/api/users`,
  }),
  endpoints: (builder) => ({
    getUsers: builder.query<User[], void>({
      query: () => ''
    }),
    getStaff: builder.query<User[], void>({
      query: () => '',
      transformResponse: (users: User[]) => users.filter((user) => user.isStaff)
    }),
    getUserById: builder.query<User, number>({
      query: (id: number) => `user/${id}`
    })
    // TODO: add new endpoints
  })
});

export const { useGetUsersQuery, useGetStaffQuery, useGetUserByIdQuery } = usersApi;
