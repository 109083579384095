import React, { useMemo } from 'react';
import CrossIcon from 'src/assets/imgs/close-cross.png';
import { useAppSelector } from 'src/hooks/api';
import { useGetRecordsQuery } from 'src/services/records/records.service';
import { useGetUserByIdQuery } from 'src/services/users/users.services';
import Loader from 'src/assets/imgs/loader.gif';
import dictionary from 'src/utils/dictionary/dictionary';
import { DAY_OFF_LIST } from 'src/constants/variables';

interface Props {
  id?: number;
  onClose: () => void;
}

const UserModalContent: React.FC<Props> = ({ id, onClose }) => {
  const selectedLanguage = useAppSelector((state) => state.lang.lang);
  const { data: userData, isLoading: isUserLoading } = useGetUserByIdQuery(
    Number(id),
    { skip: !id },
  );
  const { data: recordsData, isLoading: isRecordsLoading } =
    useGetRecordsQuery();

  const user = useMemo(() => {
    if (userData) {
      return userData;
    }
  }, [userData]);

  const records = useMemo(() => {
    if (recordsData) {
      return recordsData.filter(
        (record) => record.userId === id && !DAY_OFF_LIST.includes(record.en),
      );
    }
  }, [id, recordsData]);

  console.log(JSON.stringify(records, null, 2));

  return (
    <div className="flex flex-col items-center justify-center phone:min-w-[200px] md:min-w-[400px]">
      <div
        className="mb-4 cursor-pointer self-end hover:opacity-50"
        onClick={onClose}
      >
        <img src={CrossIcon} alt="close" width="20" height="20" />
      </div>
      {isUserLoading
        ? (
        <div className="flex w-full items-center justify-center p-8">
          <img src={Loader} width={35} height={35} />
        </div>
          )
        : user
          ? (
        <div className="container flex  justify-between p-3 phone:flex-col md:flex-row">
          <div className="flex flex-col items-center py-4 phone:mr-5  md:mr-10  lg:mr-20">
            {user.image
              ? (
              <div className="aspect-square w-[65px] overflow-hidden rounded-full">
                <img src={user.image} width={65} height={665} alt="avatar" />
              </div>
                )
              : null}
            <p className="mt-3">{user.name}</p>
          </div>
          <div className="flex flex-col items-center md:ml-5 lg:ml-10">
            <p className="mb-4 text-lg">{dictionary[selectedLanguage].records}</p>
            {isRecordsLoading
              ? (
              <div className="flex w-full items-center justify-center p-8">
                <img src={Loader} width={35} height={35} />
              </div>
                )
              : records?.length
                ? (
              <div className="scrollbar-visible max-h-[400px] overflow-y-scroll px-3 py-2 phone:mr-0 lg:mr-3">
                {records.map((record) => (
                  <div
                    key={record.id}
                    className="mb-3 rounded-md bg-cream px-3 py-2"
                  >
                    <p>{record[selectedLanguage]}</p>
                    <div className="container flex items-center">
                      <p>{`${record.date} ${dictionary[selectedLanguage].at as string} ${record.time.split(':').slice(0, 2).join(':')}`}</p>
                    </div>
                  </div>
                ))}
              </div>
                  )
                : (
              <p>{dictionary[selectedLanguage].recordsNotFound}</p>
                  )}
          </div>
        </div>
            )
          : (
        <p>{dictionary[selectedLanguage].userNotFound}</p>
            )}
    </div>
  );
};

export default UserModalContent;
