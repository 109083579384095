import React from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks/api';
import dictionary from 'src/utils/dictionary/dictionary';
import Close from 'src/assets/imgs/Close-icon.png';
import { changeSlotNumberForChanges, selectSlot } from 'src/reducers/services/servicesSlice';

interface Props {
  isHistoryShows: boolean;
  title?: number;
  onSwitch: () => void;
}

const Header: React.FC<Props> = ({ isHistoryShows, title, onSwitch }) => {
  const dispatch = useAppDispatch();
  const selectedLanguage = useAppSelector((state) => state.lang.lang);

  const close = () => {
    dispatch(selectSlot(null));
    dispatch(changeSlotNumberForChanges(null));
  };

  return (
    <h3 className="mb-[4px] flex justify-between">
    #{title}
    <button
      className="rounded-lg border border-lightBrown p-[2px] text-sm"
      onClick={onSwitch}
    >
      {isHistoryShows
        ? dictionary[selectedLanguage].info
        : dictionary[selectedLanguage].history}
    </button>
    <button onClick={close}>
      <img
        className="size-[20px] cursor-pointer"
        src={Close}
        alt=""
      />
    </button>
  </h3>
  );
};

export default Header;
