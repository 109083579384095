import dropdownArrow from '../../assets/imgs/dropdown-arrow.png';
import ruFlag from '../../assets/imgs/ru.png';
import enFlag from '../../assets/imgs/en.png';
import srFlag from '../../assets/imgs/sr.png';
import localStorageService from '../../services/local-storage/localStorage.service';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setStoreLanguage } from '../../reducers/language/languageSlice';

const LanguageSelector = () => {
  const dispatch = useDispatch();

  if (!localStorageService.getLanguage()) {
    localStorageService.setLanguage('en');
  }
  const selectedLanguage = localStorageService.getLanguage();

  const languages = [
    { name: 'Русский', symbols: 'ru', logo: ruFlag },
    { name: 'English', symbols: 'en', logo: enFlag },
    { name: 'Српски', symbols: 'sr', logo: srFlag }
  ];
  const [showDropdown, setShowDropdown] = useState(false);
  const languagesDropdown = languages.filter(
    (language) => language.symbols !== selectedLanguage
  );

  const handleClick = () => {
    setShowDropdown(!showDropdown);
  };

  const handleSelectLanguage = (language) => {
    setShowDropdown(!showDropdown);
    localStorageService.setLanguage(language.symbols);
    dispatch(setStoreLanguage(language.symbols));
  };

  return (
    <div className="absolute left-[25px] top-[25px] flex flex-col items-start justify-start rounded-lg border-2 border-gray bg-white p-[6px] max-md:left-[3px] max-md:top-[3px] max-md:p-[3px]">
      <div
        className="flex cursor-pointer items-center hover:opacity-70"
        onClick={handleClick}
      >
        <img
          className="mr-[4px] h-[20px] w-[28px]"
          src={
            languages.find((language) => language.symbols === selectedLanguage)
              ?.logo
          }
          alt=""
        />
        <p>
          {
            languages.find((language) => language.symbols === selectedLanguage)
              ?.name
          }
        </p>
        <img
          className={`ml-[10px] size-[14px] ${
            showDropdown ? 'rotate-180' : ''
          }`}
          src={dropdownArrow}
          alt=""
        />
      </div>
      {showDropdown
        ? languagesDropdown.map((language) => (
            <div
              key={language.symbols}
              className="flex cursor-pointer items-center hover:opacity-70"
              onClick={() => { handleSelectLanguage(language); }}
            >
              <img
                className="mr-[4px] h-[20px] w-[28px]"
                src={language.logo}
                alt=""
              />
              <p className="mr-[18px]">{language.name}</p>
            </div>
        ))
        : null}
    </div>
  );
};

export default LanguageSelector;
