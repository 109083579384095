/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React, { useEffect, useState } from 'react';
import dictionary from 'src/utils/dictionary/dictionary';
import { User as UserT } from 'src/services/users/types';
import User from './components/user/user';
import { STAFF_STATES, StuffState } from './constants';
import { useAppSelector } from 'src/hooks/api';
import localStorageService from 'src/services/local-storage/localStorage.service';
import { useGetStaffQuery } from 'src/services/users/users.services';
import Modal from 'src/components/calendar/common/modal/modal';

interface Props {
  isVisible: boolean;
  onClose: () => void;
  setUsers: (users: UserT[]) => void;
}

export const StaffModal: React.FC<Props> = ({
  isVisible,
  onClose,
  setUsers,
}) => {
  const { data: usersData } = useGetStaffQuery();
  const displayedUsersIds = localStorageService.getDisplayedUserIds();
  const [displayedUsers, setDisplayedUsers] = useState<UserT[]>([]);
  const [hiddenUsers, setHiddenUsers] = useState<UserT[]>([]);
  const selectedLanguage = useAppSelector((state) => state.lang.lang);

  useEffect(() => {
    if (usersData) {
      const sortedUsers = usersData.filter((user) =>
        displayedUsersIds.includes(user.id),
      );

      sortedUsers.sort((userA, userB) => {
        return (
          displayedUsersIds.indexOf(userA.id) -
          displayedUsersIds.indexOf(userB.id)
        );
      });

      setDisplayedUsers(sortedUsers);
      setHiddenUsers(
        usersData.filter((user) => !displayedUsersIds.includes(user.id)),
      );
    }
  }, [usersData]);

  const removeUserFromList = (id: number) => {
    setDisplayedUsers(displayedUsers.filter((user) => user.id !== id));
    const hiddenUser = displayedUsers.find((user) => user.id === id);

    if (hiddenUser) {
      setHiddenUsers((prevState) => [...prevState, hiddenUser]);
    }
  };

  const addUserToList = (id: number) => {
    const newUser = hiddenUsers.find((user) => user.id === id);

    if (newUser) {
      setDisplayedUsers((prevState) => [newUser, ...prevState]);
    }

    setHiddenUsers(hiddenUsers.filter((user) => user.id !== id));
  };

  const sortUsersByName = () => {
    if (displayedUsers.length > 1) {
      const isAscending =
        displayedUsers[0].name.localeCompare(displayedUsers[1].name) > 0;

      setDisplayedUsers((prevState) =>
        [...prevState].sort((userA, userB) => {
          return isAscending
            ? userA.name.localeCompare(userB.name) // A-Z
            : userB.name.localeCompare(userA.name); // Z-A
        }),
      );
    }
  };

  const sortUsersById = () => {
    if (displayedUsers.length > 1) {
      if (displayedUsers[0].id > displayedUsers[1].id) {
        setDisplayedUsers((prevState) =>
          [...prevState].sort((userA, userB) => userA.id - userB.id),
        );
      } else {
        setDisplayedUsers((prevState) =>
          [...prevState].sort((userA, userB) => userB.id - userA.id),
        );
      }
    }
  };

  const saveSettings = () => {
    localStorageService.setDisplayedUserIds(
      displayedUsers.map((user) => user.id),
    );
    setUsers(displayedUsers);
    onClose();
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Modal isVisible={isVisible} onClose={onClose}>
      <h2 className="mb-4">{dictionary[selectedLanguage].people}</h2>
      {STAFF_STATES.map((state) =>
        (state === StuffState.DISPLAYED ? displayedUsers : hiddenUsers)
          .length
          ? (
          <>
            <p key={`${state}-p`} className="mb-4 mt-2 text-brown">
              {dictionary[selectedLanguage][state]}
            </p>
            <div
              key={`${state}-div`}
              className="scrollbar-visible flex w-full items-center self-start overflow-x-scroll pb-6 phone:max-w-[200px] sm:max-w-[300px] md:max-w-[400px] lg:max-w-[700px]"
            >
              {(state === StuffState.DISPLAYED
                ? displayedUsers
                : hiddenUsers
              ).map((user, index, arr) => (
                <User
                  key={`${user.id}-${state}`}
                  isFirst={!index}
                  isLast={index === arr.length - 1}
                  user={user}
                  onRemoveUser={
                    state === StuffState.DISPLAYED
                      ? removeUserFromList
                      : addUserToList
                  }
                />
              ))}
            </div>
          </>
            )
          : null,
      )}

      <div className="mt-3 flex flex-row gap-3 self-start">
        <p className="mt-1">{dictionary[selectedLanguage].sortBy}</p>
        <div className="flex flex-row gap-3">
          <p
            className="cursor-pointer select-none rounded-md bg-cream px-2 py-1 hover:text-darkBrown"
            onClick={sortUsersByName}
          >
            {dictionary[selectedLanguage].userName}
          </p>
          <p
            className="cursor-pointer select-none rounded-md bg-cream px-2 py-1 hover:text-darkBrown"
            onClick={sortUsersById}
          >
            ID
          </p>
        </div>
      </div>

      <div className="flex flex-row gap-3">
        <button
          className="mt-5 select-none rounded-md bg-cream px-4 py-2"
          onClick={saveSettings}
        >
          {dictionary[selectedLanguage].save}
        </button>
        <button className="mt-5 select-none px-4 py-2" onClick={onClose}>
          {dictionary[selectedLanguage].cancel}
        </button>
      </div>
    </Modal>
  );
};
