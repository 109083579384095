import axios from 'axios';
import apiEndpoint from './constants';

const httpAuth = axios.create({
  baseURL: `${apiEndpoint}/api/`,
});

const authService = {
  getClientAuthCode: async (phone) => {
    const { data } = await httpAuth.post('clients/auth-code', { phone }, { headers: { 'Access-Control-Allow-Origin': '*' } });

    return data;
  },

  createNewClient: async (phone, userId) => {
    await httpAuth.post('clients/register', { phone, userId });
  },

  clientLogin: async (phone, code) => {
    const { data } = await httpAuth.post('clients/login', { phone, code });

    return data;
  },

  userLogin: async (phone, password) => {
    const { data } = await httpAuth.post('users/login', { phone, password });

    return data;
  },
};

export default authService;
