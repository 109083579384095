import dictionary from 'src/utils/dictionary/dictionary';
import { Columns } from './constants';
import { LanguageKeys } from 'src/utils/dictionary/types';
import { RecordApi } from 'src/services/records/types';

export const getColumns = (language: LanguageKeys) => [
  {
    status: Columns.CREATED,
    label: dictionary[language].created,
  },
  {
    status: Columns.COMPLETED,
    label:
      dictionary[language].completed,
  },
  {
    status: Columns.CANCELED,
    label:
      dictionary[language].canceled,
  },
  {
    status: Columns.ALL,
    label: dictionary[language].all,
  },
];

export const getRecordsByStatus = (status: Columns, records: RecordApi[]) => status === Columns.ALL
  ? records
  : records.filter((record) => record.status as Columns === status);
