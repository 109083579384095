import PropTypes from 'prop-types';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import TimeGraph from './time-graph/timeGraph';
import CrmCalendarBoardDay from './crmCalendarBoardDay';
import { transformDate } from '../../utils/date/transformDate';
import localStorageService from '../../services/local-storage/localStorage.service';
import fillDayWithAvailableSlots from '../../utils/fillDayWithAvailableSlots/fillDayWithAvailableSlots';
import apiEndpoint from '../../services/constants';

const CrmCalendarBoard = ({
  firstDay,
  selectedService,
  selectedUser,
  selectedSlot,
  selectedSlots,
  handleSelectedSlot,
  handleSelectSlots,
  complex,
  setSlotForChange
}) => {
  const userId = localStorageService.getUserId();
  const daysCount = window.innerWidth <= 767 ? 1 : 4;
  const [existingRecords, setExistingRecords] = useState({});

  useEffect(() => {
    for (const day of _.range(daysCount)) {
      const boardDayDate = transformDate(
        new Date(firstDay.getTime() + 1000 * 60 * 60 * 24 * day)
      );

      fetch(
        `${apiEndpoint}/api/records/get-available-crm/${userId}/${
          selectedUser ? selectedUser.id : userId
        }/${boardDayDate}`
      )
        .then(async (response) => await response.json())
        .then((data) => {
          const result = Object.keys(data).map((key) => data[key]);

          setExistingRecords((previousState) => ({
            ...previousState,
            [boardDayDate]: selectedService
              ? fillDayWithAvailableSlots(
                selectedService,
                result,
                boardDayDate,
                selectedUser.id,
                selectedSlots,
              )
              : result
          }));
        })
        .catch((error) => { console.error(error); });
    }
  }, [selectedService, daysCount, firstDay, selectedUser, userId]);

  const onSlotChange = (recordId) => {
    setSlotForChange(recordId);
  };

  const calendarBoardDays = _.range(daysCount).map((day) => (
    <CrmCalendarBoardDay
      date={new Date(firstDay.getTime() + 1000 * 60 * 60 * 24 * day)}
      selectedService={selectedService || 1}
      selectedUser={selectedUser}
      existingRecords={
        existingRecords[
          transformDate(
            new Date(firstDay.getTime() + 1000 * 60 * 60 * 24 * day)
          )
        ]
      }
      handleSelectSlots={handleSelectSlots}
      selectedSlot={selectedSlot}
      handleSelectSlot={handleSelectedSlot}
      onSlotChange={onSlotChange}
      complex={complex}
      key={day}
    />
  ));

  return (
    <>
      <TimeGraph/>
      <div className="relative w-full md:grid md:grid-cols-4">
        {calendarBoardDays}
      </div>
    </>
  );
};

CrmCalendarBoard.propTypes = {
  date: PropTypes.instanceOf(Date),
  users: PropTypes.array,
  selectedService: PropTypes.object,
  selectedUser: PropTypes.object,
  selectedSlot: PropTypes.object,
  selectedSlots: PropTypes.array,
  handleSelectedSlot: PropTypes.func,
  handleSelectSlots: PropTypes.func,
  handleSetDate: PropTypes.func,
  setSlotForChange: PropTypes.func,
  complex: PropTypes.bool
};

export default CrmCalendarBoard;
