import dictionary from 'src/utils/dictionary/dictionary';
import { LanguageKeys } from 'src/utils/dictionary/types';

export const getClientHeaders = (language: LanguageKeys) => [
  dictionary[language].master,
  dictionary[language].service,
  dictionary[language].price,
  dictionary[language].time,
] as string[];

export const getCrmHeaders = (language: LanguageKeys) => [
  dictionary[language].client,
  dictionary[language].service,
  dictionary[language].price,
  dictionary[language].time,
  dictionary[language].status,
] as string[];
