/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { HOURS } from './constants';
import Loader from 'src/assets/imgs/loader.gif';
import { getBorderClass, getRecordClassNames, getSlotData } from './helpers';
import { capitalize } from 'src/utils/text/text';
import { User } from 'src/services/users/types';
import { RecordType } from 'src/services/records/types';
import { UserMiniature } from './components/user-miniature/userMiniature';
import { Record } from './components/record/Record';
import { useAppDispatch, useAppSelector } from 'src/hooks/api';
import { transformDate } from 'src/utils/date/transformDate';
import {
  changeSlotNumberForChanges,
  increaseComplexNumber,
  selectSlot,
  selectSlots,
} from 'src/reducers/services/servicesSlice';
import { Slot } from 'src/reducers/services/types';
import {
  useDeleteBreakQuery,
  useGetAvailableCrmQuery,
} from 'src/services/records/records.service';
import fillDayWithAvailableSlots from 'src/utils/fillDayWithAvailableSlots/fillDayWithAvailableSlots';

interface Props {
  user: User;
  date: Date;
  openUserModal: (id: number) => void;
}

const BoardDay: React.FC<Props> = ({
  user,
  date,
  openUserModal,
}) => {
  const dispatch = useAppDispatch();
  const { selectedSlot, selectedSlots, selectedComplex, selectedService } =
    useAppSelector((state) => state.services);
  const [recordIdForRemoving, setRecordIdForRemoving] = useState<number>();
  const [onHoverRecordId, setOnHoverRecordId] = useState<number>();
  const {
    data: availableCrm,
    isLoading,
    isFetching,
    refetch: refreshCrmData,
  } = useGetAvailableCrmQuery({
    userId: user.id,
    date: transformDate(date),
  });

  const existingRecords = useMemo(() => {
    if (selectedService) {
      return fillDayWithAvailableSlots(
        selectedService,
        availableCrm,
        transformDate(date),
        user.id,
        selectedSlots,
      );
    }

    return availableCrm;
  }, [availableCrm, date, selectedService, selectedSlots, user.id]);

  const { isSuccess } = useDeleteBreakQuery(
    { recordId: recordIdForRemoving! },
    {
      skip: !recordIdForRemoving,
    },
  );

  const handleSelectSlot = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    slot: Slot,
  ) => {
    const clockedElement = e.target as HTMLElement;
    const isRemove = clockedElement.closest('span');

    if (!isRemove) {
      dispatch(selectSlot(slot));
      dispatch(changeSlotNumberForChanges(null));
    }
  };

  const handleSelectSlots = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    slot: Slot,
  ) => {
    slot.serviceId = selectedService?.id;
    const clockedElement = e.target as HTMLElement;
    const isRemove = clockedElement.closest('span');

    if (selectedComplex && selectedSlots && !isRemove) {
      dispatch(selectSlots(slot));
      dispatch(increaseComplexNumber());
    }
    changeSlotNumberForChanges(null);
  };

  const removeNewRecord = () => {
    dispatch(selectSlot(null));
  };

  useEffect(() => {
    if (isSuccess) {
      refreshCrmData();
    }
  }, [isSuccess]);

  return (
    <div className="relative flex flex-col">
      <UserMiniature
        name={user?.name}
        imageUrl={user?.image}
        openUserModal={() => openUserModal(user.id)}
      />
      {HOURS.map((hour, index) => (
        <div
          key={hour}
          className={classNames(
            `flex h-[60px] w-full items-center justify-center border-gray ${getBorderClass(hour)}`,
            {
              'border-y': index === 0,
              'border-b': index !== 0,
            },
          )}
        />
      ))}
      <div className="relative flex h-[60px] w-full items-center justify-center" />
      {isLoading || isFetching
        ? (
        <div className="absolute left-1/2 top-1/3 flex w-full -translate-x-1/2 items-center justify-center">
          <img src={Loader} width={20} height={20} />
        </div>
          )
        : (
            existingRecords?.map((record, index) => (
          <div
            key={`${String(date)}-${record.start}-${index}`}
            className={classNames(
              getRecordClassNames(
                Boolean(
                  selectedSlot &&
                    selectedSlot.slotId ===
                      `${String(date)}${record.start}${user.name}`,
                ),
                record.type,
              ),
            )}
            style={{
              top: `${record.start + 2 - 480}px`,
              height: `${record.duration - 4}px`,
            }}
            onClick={(e) => {
              record.type === RecordType.GREEN
                ? selectedComplex
                  ? handleSelectSlots(
                    e,
                    getSlotData(
                      date,
                      record,
                      user.name,
                      user.id,
                      transformDate(date),
                    ),
                  )
                  : handleSelectSlot(
                    e,
                    getSlotData(
                      date,
                      record,
                      user.name,
                      user.id,
                      transformDate(date),
                    ),
                  )
                : record.type === RecordType.BLUE ||
                    record.type === RecordType.YELLOW
                  ? dispatch(
                    changeSlotNumberForChanges(record.recordId ?? null),
                  )
                  : null;
            }}
            onMouseEnter={() => setOnHoverRecordId(record.recordId)}
            onMouseLeave={() => setOnHoverRecordId(undefined)}
          >
            {onHoverRecordId === record.recordId &&
              record.type === RecordType.GRAY && (
                <span
                  className="absolute right-5 top-5 cursor-pointer"
                  onClick={() => setRecordIdForRemoving(record.recordId)}
                >
                  X
                </span>
            )}

            {record.type === RecordType.GREEN && (
              <span
                className="absolute right-2 top-1 cursor-pointer"
                onClick={removeNewRecord}
              >
                X
              </span>
            )}
            <span
              className={classNames(
                `absolute left-0 top-[8px] ml-auto mr-auto rounded-r-lg w-[12px] bg-dark${capitalize(
                  record.type,
                )}`,
                {
                  'bg-darkBrown': record.type === RecordType.YELLOW,
                  'bg-gray': record.type === RecordType.GRAY,
                },
              )}
              style={{
                height: `${record.duration - 24}px`,
              }}
            />
            {
              <Record
                key={record.recordId ?? index}
                userName={user.name}
                record={record}
                selectedService={selectedService}
              />
            }
          </div>
            ))
          )}
    </div>
  );
};

export default BoardDay;
