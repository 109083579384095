import React from 'react';

interface Props {
  type: string;
  name: string;
  value?: string;
  label?: string;
  placeholder?: string;
  error?: string;
  isArea?: boolean;
  disabled?: boolean;
  onChange: (data: { name: string, value: string }) => void;
}

const TextField: React.FC<Props> = ({
  type,
  name,
  value = '',
  label,
  placeholder,
  error,
  isArea,
  disabled = false,
  onChange,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange({ name: e.target.name, value: e.target.value });
  };

  const renderLabel = () => (
    label && (
      <label className="mb-[8px] block text-lg text-brown max-md:text-sm" htmlFor={name}>
        {label}
      </label>
    )
  );

  return (
    <>
      {renderLabel()}
      {isArea
        ? (
        <textarea
          className="w-full rounded-lg border border-lightBrown px-3 py-2 text-lightBrown placeholder:text-lightBrown focus:outline-brown"
          id={name}
          name={name}
          onChange={handleChange}
          disabled={disabled}
          value={value}
        />
          )
        : (
        <input
          className={`w-full rounded-lg border border-lightBrown px-3 py-2 text-lightBrown placeholder:text-lightBrown focus:outline-brown ${
            error ? 'border-red focus:outline-red' : ''
          } ${type === 'file' ? 'cursor-pointer file:rounded-lg file:border file:border-lightBrown file:bg-white file:text-lightBrown file:outline-none' : ''}`}
          id={name}
          name={name}
          type={type}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          onChange={handleChange}
        />
          )}
      {error && <p className="text-sm text-red">{error}</p>}
    </>
  );
};

export default TextField;
