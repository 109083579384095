/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable multiline-ternary */
import React, { useEffect, useMemo, useState } from 'react';
import dictionary from 'src/utils/dictionary/dictionary';
import StatusButton from './components/status-button/statusButton';
import { useAppSelector } from 'src/hooks/api';
import { useGetRecordsQuery } from 'src/services/records/records.service';
import { useGetClientsQuery } from 'src/services/clients/clients.service';
import { useGetUsersQuery } from 'src/services/users/users.services';
import { User } from 'src/services/users/types';
import Header from './components/header/header';
import HistoryList from './components/history-list/historyList';
import UserInfo from './components/user-info/userInfo';
import { ButtonStatus } from './components/status-button/constants';
import { BUTTON_STATUS_LIST } from './constants';
import DetailsForm from './components/details-form/detailsForm';
import { RecordApi } from 'src/services/records/types';

interface Props {
  recordId: number;
  currentUser: User;
}

const DetailedRecordInfo: React.FC<Props> = ({
  recordId,
  currentUser,
}) => {
  const selectedLanguage = useAppSelector((state) => state.lang.lang);
  const [isHistoryShows, setIsHistoryShows] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<ButtonStatus>(
    ButtonStatus.COMPLETED,
  );
  const [record, setRecord] = useState<RecordApi>();
  const {
    data: recordsData,
    refetch: refetchRecordsData,
  } = useGetRecordsQuery();
  const { data: clientsData, isLoading: isClientsLoading } =
    useGetClientsQuery();
  const { data: usersData, isLoading: isUsersLoading } = useGetUsersQuery();

  const users = useMemo(() => {
    return usersData ?? null;
  }, [usersData]);

  const client = useMemo(() => {
    if (clientsData && record) {
      return clientsData?.find((client) => client.id === record.clientId);
    }
  }, [clientsData, record]);

  useEffect(() => {
    if (recordsData) {
      setRecord(recordsData.find((record) => record.id === recordId));
    }
  }, [recordsData, recordId]);

  useEffect(() => {
    if (record) {
      setSelectedStatus(record.status as ButtonStatus);
    }
  }, [record]);

  const onSubmit = () => {
    setRecord((previousState) => {
      if (previousState) {
        return {
          ...previousState,
          status: selectedStatus,
        };
      }
    });
    refetchRecordsData();
  };

  if (isClientsLoading || isUsersLoading) {
    return (
      <div className="flex size-full items-center justify-center">
        <p className="text-darkBrown">Loading...</p>
      </div>
    );
  }

  return (
    <div className="text-brown">
      <Header
        isHistoryShows={isHistoryShows}
        title={record?.id}
        onSwitch={() => setIsHistoryShows((previousState) => !previousState)}
      />
      {isHistoryShows ? (
        <HistoryList record={record} />
      ) : (
        <>
          <UserInfo currentUser={currentUser} client={client} />

          <p className="mb-[4px] font-thin">
            {dictionary[selectedLanguage].author}
          </p>
          <p className="mb-[16px] text-darkBrown">
            {record?.author ??
              String(
                dictionary[selectedLanguage].clientHimself,
              )}
          </p>
          <p className="mb-[4px] font-thin">
            {dictionary[selectedLanguage].status}:
            {` ${record?.status ?? ''}`}
          </p>
          <div className="flex w-full items-center justify-start pb-[12px]">
            {BUTTON_STATUS_LIST.map((status) => (
              <StatusButton
                key={status}
                type={status}
                onSelect={(status: ButtonStatus) => setSelectedStatus(status)}
                selectedStatus={selectedStatus}
              />
            ))}
          </div>

          <DetailsForm
            status={selectedStatus}
            currentUser={currentUser}
            users={users}
            isComeFromFieldVisible={
              !client?.cameFrom && selectedStatus === ButtonStatus.COMPLETED
            }
            record={record}
            client={client}
            onSubmit={onSubmit}
          />
        </>
      )}
    </div>
  );
};

export default DetailedRecordInfo;
