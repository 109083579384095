// date: Date => 'YYYY-MM-DD'
export const transformDate = (date: Date) => `${date.getFullYear()}-${
  date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)
}-${date.getDate() > 9 ? date.getDate() : '0' + date.getDate()}`;

// YYYY-MM-DD => DD.MM.YYYY:
export const transformDateOrder = (dateString: string) => {
  const [year, month, day] = dateString.split('-');

  return `${day}.${month}.${year}`;
};

// 'DD.MM.YYYY' => 'YYYY-MM-DD'
export const changeDateType = (dateString: string) => dateString.split('.').reverse().join('-');
