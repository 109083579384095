/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react';
import ContainerBox from 'src/components/ui/containerBox';
import { useNavigate, useParams } from 'react-router-dom';
import ClientPageRecords from 'src/components/clients/client-page-records/clientPageRecords';
import ClientPageComments from 'src/components/clients/client-page-comments/clientPageComments';
import localStorageService from 'src/services/local-storage/localStorage.service';
import ClientPageHeader from 'src/components/clients/client-page-header/clientPageHeader';
import { useGetClientByIdQuery } from 'src/services/clients/clients.service';
import { useGetUserByIdQuery } from 'src/services/users/users.services';

const ClientPage: React.FC = () => {
  const userId = localStorageService.getUserId();
  const { clientId } = useParams();

  const navigate = useNavigate();
  const { data: client, refetch } = useGetClientByIdQuery(Number(clientId), {
    skip: !clientId
  });
  const { data: user } = useGetUserByIdQuery(Number(userId));

  if (user) {
    if (user.isStaff || user.isAdmin) {
      return (
        <div className="relative mx-auto flex min-h-screen items-start justify-center bg-cream pb-[32px] text-lightBrown max-md:text-sm">
          <ContainerBox>
            <ClientPageHeader
              user={user}
              client={client}
              refetch={refetch}
            />
            <ClientPageRecords clientId={client?.id} />
            <ClientPageComments clientId={client?.id} />
          </ContainerBox>
        </div>
      );
    } else {
      navigate('/');
    }
  }
};

export default ClientPage;
