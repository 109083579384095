import React from 'react';
import { Client } from 'src/services/clients/types';
import Mute from 'src/assets/imgs/mute.png';
import telegramLogo from 'src/assets/imgs/telegram.png';
import phoneLogo from 'src/assets/imgs/Vector.png';
import { Link } from 'react-router-dom';
import { User } from 'src/services/users/types';

interface Props {
  currentUser: User;
  client?: Client;
}

const UserInfo: React.FC<Props> = ({ currentUser, client }) => (
  <div className="mt-1 flex items-center justify-between">
    <div>
      {client && (
        <Link to={'/crm/client/' + client.id}>
          <div className="mb-[4px] flex items-center justify-start">
            <p className="text-darkBrown">{client.name}</p>
            {client.communication
              ? null
              : (
              <img className="size-[18px]" src={Mute} alt="mute" />
                )}
          </div>
        </Link>
      )}

      {currentUser?.isAdmin
        ? (
        <>
          <p className="mb-[4px] flex items-center justify-start text-darkBrown">
            <a
              className="flex items-center justify-start"
              href={`http://www.t.me/${client?.telegram}`}
            >
              <img
                className="mr-[8px] size-[16px]"
                src={telegramLogo}
                alt=""
              />
              {client?.telegram}
            </a>
          </p>
          <p className="mb-[8px] flex items-center justify-start text-darkBrown">
            <img className="mr-[8px] size-[16px]" src={phoneLogo} alt="" />
            <a href={`tel:${client?.phone}`}>{client?.phone}</a>
          </p>
        </>
          )
        : null}
    </div>
    {client?.image
      ? (
      <img className="size-[75px] rounded-full" src={client.image} alt="" />
        )
      : null}
  </div>
);

export default UserInfo;
