/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Close from 'src/assets/imgs/plus-circle.png';
import { useAppSelector } from 'src/hooks/api';
import dictionary from 'src/utils/dictionary/dictionary';
import { Form, FormFields } from './types';
import { DEFAULT_FORM_VALUES } from './constants';
import { yupResolver } from '@hookform/resolvers/yup';
import yupSchema from './schema';
import { Client } from 'src/services/clients/types';
import { RecordApi } from 'src/services/records/types';
import { toast } from 'react-toastify';
import { useUpdateRecordMutation, useUploadRecordImageMutation } from 'src/services/records/records.service';
import { ButtonStatus } from 'src/components/calendar/common/detailed-record-info/components/status-button/constants';
import localStorageService from 'src/services/local-storage/localStorage.service';

interface Props {
  isVisible: boolean;
  record: RecordApi;
  records: RecordApi[],
  client?: Client;
  changeVisibility: () => void;
  updateData: () => void;
  updateRecords: (index: number) => void;
}

const RecordForm: React.FC<Props> = ({
  isVisible,
  record,
  records,
  client,
  changeVisibility,
  updateData,
  updateRecords
}) => {
  const userId = localStorageService.getUserId();
  const selectedLanguage = useAppSelector((state) => state.lang.lang);
  const [selectedImage, setSelectedImage] = useState<File>();
  const [selectedPreview, setSelectedPreview] = useState<string | null>(null);
  const [updateRecord] = useUpdateRecordMutation();
  const [uploadImage] = useUploadRecordImageMutation();
  const notify = () => toast.success('Сохранено');

  const { register, getValues } = useForm<Form>({
    mode: 'onChange',
    defaultValues: DEFAULT_FORM_VALUES,
    resolver: yupResolver(yupSchema),
  });

  const onImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    let url = '';

    if (file) {
      url = URL.createObjectURL(file);
    }

    setSelectedPreview(url);
    setSelectedImage(file);
  };

  const handleSubmit = async () => {
    const recordId = record.id;

    const formValues = getValues();

    await updateRecord({
      recordId,
      price: Number.parseFloat(formValues[FormFields.PRICE] ?? ''),
      status: formValues[FormFields.STATUS] as ButtonStatus,
      comment: formValues[FormFields.COMMENT] ?? '',
      cameFrom: formValues[FormFields.CAME_FROM] ?? '',
      userId: Number(userId)
    });

    if (selectedImage) {
      const formData = new FormData();

      formData.append('image', selectedImage);
      await uploadImage({ recordId, formData });
    }
    notify();
    changeVisibility();
    updateRecords(records.findIndex((record) => record.id === recordId));
    updateData();
  };

  return (
    <div
      className={
        isVisible
          ? 'absolute left-0 top-[-252px] z-[100] flex h-screen w-screen items-center justify-center bg-transparent backdrop-blur-lg max-md:top-[-278px]'
          : 'collapse absolute left-0 top-[-252px] z-[100] flex h-screen w-screen items-center justify-center bg-transparent backdrop-blur-lg max-md:top-[-278px]'
      }
    >
      <div className="w-[600px]">
        <div className="mb-[16px] flex w-full justify-between">
          <h3 className="text-base text-white">
            {dictionary[selectedLanguage].closeRecord}
          </h3>
          <img
            className="cursor-pointer"
            onClick={changeVisibility}
            src={Close}
            alt=""
          />
        </div>
        <label className="text-white" htmlFor={FormFields.PRICE}>
          {dictionary[selectedLanguage].finalPrice}
        </label>
        <input
          id={FormFields.PRICE}
          type="text"
          className="w-full rounded-lg border border-lightBrown px-[8px] py-[7px] text-lightBrown"
          {...register(FormFields.PRICE)}
        />
        <label className="mt-[16px] text-white" htmlFor={FormFields.COMMENT}>
          {dictionary[selectedLanguage].comment}
        </label>
        <textarea
          id={FormFields.COMMENT}
          className="w-full rounded-lg border border-lightBrown px-3 py-2 text-lightBrown placeholder:text-lightBrown focus:outline-brown"
          {...register(FormFields.COMMENT)}
        />

        {client?.cameFrom
          ? null
          : (
          <>
            <label
              className="mt-[16px] text-white"
              htmlFor={FormFields.CAME_FROM}
            >
              {dictionary[selectedLanguage].cameFrom}
            </label>
            <textarea
              id={FormFields.CAME_FROM}
              className="w-full rounded-lg border border-lightBrown px-3 py-2 text-lightBrown placeholder:text-lightBrown focus:outline-brown"
              {...register(FormFields.CAME_FROM)}
            />
          </>
            )}

        <input
          className="mb-[16px] block w-full cursor-pointer rounded-lg border border-lightBrown bg-white px-3 py-2 text-sm text-lightBrown file:cursor-pointer file:rounded-lg file:border file:border-lightBrown file:bg-cream file:text-brown file:outline-none placeholder:text-lightBrown file:hover:opacity-80 focus:outline-none"
          id="file"
          name="file"
          accept={window.innerWidth < 800 ? 'image/*, file/*' : 'image/*'}
          type="file"
          onChange={onImageChange}
        />
        {selectedPreview
          ? (
          <img
            src={selectedPreview}
            alt=""
            className="mx-auto my-[16px] w-full"
          />
            )
          : null}

        <button
          className="items-end rounded-lg bg-black px-[12px] py-[10px] text-white hover:opacity-80 max-md:w-full"
          onClick={handleSubmit}
        >
          {dictionary[selectedLanguage].send}
        </button>
      </div>
    </div>
  );
};

export default RecordForm;
