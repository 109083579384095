import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import apiEndpoint from '../constants';
import { Client, History, CurrentClient } from './types';

export const clientsApi = createApi({
  reducerPath: 'clientsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiEndpoint}/api/clients`,
  }),
  endpoints: (builder) => ({
    getClients: builder.query<Client[], void>({
      query: () => ''
    }),
    getClientById: builder.query<Client, number>({
      query: (id) => `client/${id}`
    }),
    getClientByPhone: builder.query<Client, string>({
      query: (phone) => `client/phone/${phone}`
    }),
    getClientByInstagram: builder.query<Client, string>({
      query: (instagram) => `client/instagram/${instagram}`
    }),
    getClientByTelegram: builder.query<Client, string>({
      query: (telegram) => `client/telegram/${telegram}`
    }),
    getClientsHistoryById: builder.query<History[], number>({
      query: (id) => `client/history/${id}`
    }),
    updateCurrentClient: builder.mutation<CurrentClient, CurrentClient>({
      query: (client) => ({
        url: `client/${client.clientId}`,
        method: 'PATCH',
        body: client
      })
    })
  })
});

export const {
  useGetClientsQuery,
  useGetClientByIdQuery,
  useGetClientByPhoneQuery,
  useGetClientByInstagramQuery,
  useGetClientByTelegramQuery,
  useGetClientsHistoryByIdQuery,
  useUpdateCurrentClientMutation
} = clientsApi;
