import React from 'react';
import { getClassNames } from './helpers';
import { times } from './constants';

const TimeGraph: React.FC = () => (
  <div className="flex flex-col text-center text-sm">
    <div className="h-[60px] w-[55px]"></div>
    {times.map(({ hour, label }) => (
      <div key={hour} className={getClassNames(hour)}>
        {label}
      </div>
    ))}
  </div>
);

export default TimeGraph;
