import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import apiEndpoint from '../constants';
import { Comment, NewComment, NewCommentResponse, UpdateComment } from './types';

export const commentsApi = createApi({
  reducerPath: 'commentsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiEndpoint}/api/comments`,
  }),
  endpoints: (builder) => ({
    getComments: builder.query<Comment[], void>({
      query: () => ''
    }),
    getCommentsByClientId: builder.query<Comment[], number>({
      query: (id) => `comments-by-client/${id}`
    }),
    createComment: builder.mutation<NewCommentResponse, NewComment>({
      query: (comment) => ({
        url: '',
        method: 'POST',
        body: comment
      })
    }),
    uploadCommentImage: builder.mutation<null, UpdateComment>({
      query: (comment) => ({
        url: `image/${comment.commentId}`,
        method: 'PATCH',
        body: comment.formData
      })
    })
  })
});

export const {
  useGetCommentsQuery,
  useGetCommentsByClientIdQuery,
  useCreateCommentMutation,
  useUploadCommentImageMutation
} = commentsApi;
