import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { RecordApi } from 'src/services/records/types';
import { Client } from 'src/services/clients/types';
import { useAppSelector } from 'src/hooks/api';
import { DAY_OFF_LIST } from 'src/constants/variables';
import RecordForm from './components/record-form/recordForm';

interface Props {
  record: RecordApi;
  records: RecordApi[];
  isLast: boolean;
  clients?: Client[];
  updateRecords: (index: number) => void;
  updateData: () => void;
}

const CrmRecordElement: React.FC<Props> = ({
  record,
  records,
  isLast,
  clients,
  updateRecords,
  updateData,
}) => {
  const selectedLanguage = useAppSelector((state) => state.lang.lang);
  const [isVisible, setIsVisible] = useState(false);
  const client = clients?.find((client) => client.id === record.clientId);

  if (DAY_OFF_LIST.includes(record?.service?.en)) {
    return null;
  }

  return (
    <>
      <div
        onClick={() => {
          if (record.status === 'created') {
            setIsVisible(!isVisible);
          }
        }}
        className={
          isLast
            ? 'grid cursor-pointer grid-cols-5 rounded-b-lg border border-gray hover:opacity-70'
            : 'grid cursor-pointer grid-cols-5 border border-gray hover:opacity-70'
        }
      >
        <Link to={`/crm/client/${client?.id}`}>
          <div className="px-[20px] py-[12px] max-md:px-[10px] max-md:py-[6px]">
            {client?.name}
          </div>
        </Link>
        <div className="px-[20px] py-[12px] max-md:px-[10px] max-md:py-[6px]">
          {record?.service?.[selectedLanguage]}
        </div>
        <div className="px-[20px] py-[12px] max-md:px-[10px] max-md:py-[6px]">
          {record?.service?.price}{' '}
          {record.status === 'completed' ? <b>({record?.price})</b> : null}
        </div>
        <div className="px-[20px] py-[12px] max-md:px-[10px] max-md:py-[6px]">
          {record.time.slice(0, 5)}
        </div>
        <div className="px-[20px] py-[12px] max-md:px-[10px] max-md:py-[6px]">
          {record.status}
        </div>
      </div>
      <RecordForm
        isVisible={isVisible}
        record={record}
        records={records}
        client={client}
        changeVisibility={() => setIsVisible(!isVisible)}
        updateData={updateData}
        updateRecords={updateRecords}
      />

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default CrmRecordElement;
