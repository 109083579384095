import { useSelector } from 'react-redux';
import dictionary from '../../utils/dictionary/dictionary';
import Image1 from '../../assets/imgs/base/team images/1.jpg';
import Image2 from '../../assets/imgs/base/team images/2.jpg';
import Image3 from '../../assets/imgs/base/team images/3.jpg';

const Team = () => {
  const selectedLanguage = useSelector((state) => state.lang.lang);

  return (
    <>
      <section className="relative mx-auto bg-[#F7F4EA] pb-[24px] font-serif max-[400px]:px-2">
        <section className="container mx-auto mb-[32px] lg:text-left">
          <h2
            id="Team"
            className="mb-12 text-center text-3xl font-bold text-[#9C783E] max-[400px]:text-2xl"
          >
            {dictionary[selectedLanguage].meet}{' '}
            <u>{dictionary[selectedLanguage].teamLow}</u>
          </h2>

          <div className="px-auto mx-auto grid justify-center gap-8 text-white md:grid-cols-3 xl:gap-x-12">
            <div className="mb-16 lg:mb-0">
              <div className="relative rounded-lg bg-[#b8a68a] p-4">
                <div className="flex-row items-center lg:flex">
                  <div className="w-full lg:w-5/12 lg:pr-6">
                    <img
                      src={Image1}
                      alt="Trendy Pants and Shoes"
                      className="mb-6 w-full rounded-md lg:mb-0"
                    />
                  </div>
                  <div className="w-full lg:w-7/12">
                    <h5 className="mb-2 text-lg font-bold">Arina Usoltseva</h5>
                    <p className="mb-4">{dictionary[selectedLanguage].cqs}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-6 lg:mb-0">
              <div className="relative rounded-lg bg-[#b8a68a] p-4">
                <div className="flex-row items-center lg:flex">
                  <div className="w-full lg:w-5/12 lg:pr-6">
                    <img
                      src={Image3}
                      alt=""
                      className="mb-6 w-full rounded-md lg:mb-0"
                    />
                  </div>
                  <div className="w-full lg:w-7/12">
                    <h5 className="mb-2 text-lg font-bold">
                      Valeriia Vyskrebova
                    </h5>
                    <p className="mb-4 text-white">
                      {dictionary[selectedLanguage].lm}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-6 lg:mb-0">
              <div className="relative rounded-lg bg-[#b8a68a] p-4">
                <div className="flex-row items-center lg:flex">
                  <div className="w-full lg:w-5/12 lg:pr-6">
                    <img
                      src={Image2}
                      alt="Admin"
                      className="mb-6 w-full rounded-md lg:mb-0"
                    />
                  </div>
                  <div className="my-auto size-full lg:w-7/12">
                    <h5 className="mb-2 text-lg font-bold">Katarina Ilijin</h5>
                    <p className="mb-4 text-white">
                      {dictionary[selectedLanguage].am}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default Team;
