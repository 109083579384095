import { useEffect, useState } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import TimeGraph from '../time-graph/timeGraph';
import BoardDay from './components/board-day/boardDay';
import Header from './components/header/header';
import { User } from 'src/services/users/types';

interface Props {
  date: Date;
  users: User[];
  openUserModal: (id: number) => void;
}

const AdminCalendarBoard: React.FC<Props> = ({
  date,
  users,
  openUserModal,
}) => {
  const [calendarStart, setCalendarStart] = useState(0);
  const [calendarEnd, setCalendarEnd] = useState(
    window.innerWidth <= 767
      ? 1
      : (users.length > 4
          ? 5
          : users.length),
  );

  useEffect(() => {
    setCalendarStart(0);
    setCalendarEnd(
      window.innerWidth <= 767
        ? 1
        : (users.length > 4
            ? 5
            : users.length),
    );
  }, [users.length]);

  const calenderBoardDays = users.map((user) => (
    <BoardDay
      key={user.id}
      user={user}
      date={date}
      openUserModal={openUserModal}
    />
  ));

  const calendarBoardDaysToRender = _.range(calendarStart, calendarEnd).map(
    (calendarIndex) => calenderBoardDays[calendarIndex],
  );

  const numberOfCols = calendarBoardDaysToRender.filter(
    (item) => item !== undefined,
  ).length;

  return (
    <>
      <TimeGraph />
      <div
        className={classNames(`grid grid-cols-${numberOfCols} relative w-full`)}
      >
        <Header
          isLeftErrorHidden={calendarStart === 0}
          isRightErrorHidden={
            users.length === numberOfCols + calendarStart
          }
          onChangeDate={(value: number) => {
            if (value !== 0) {
              setCalendarEnd((prev) => prev + value);
              setCalendarStart((prev) => prev + value);
            }
          }}
        />
        {calendarBoardDaysToRender}
      </div>
    </>
  );
};

export default AdminCalendarBoard;
