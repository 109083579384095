import React from 'react';
import AuthBackground from '../components/auth/authBackground';
import AuthCard from '../components/auth/authCard';
import UserLoginForm from '../components/auth/userLoginForm';

const ClientLoginPage = () => {
  return (
    <div className="container-fluid relative mx-auto flex h-screen items-center justify-center bg-cream max-md:text-sm">
      <AuthCard>
        <UserLoginForm></UserLoginForm>
      </AuthCard>

      <AuthBackground></AuthBackground>
    </div>
  );
};

export default ClientLoginPage;
