import React, { useState } from 'react';
import TextField from '../components/ui/text-field/textField';
import commentService from '../services/comment.service';
import dictionary from '../utils/dictionary/dictionary';
import { useSelector } from 'react-redux';

const FeedbackPage = () => {
  const selectedLanguage = useSelector((state) => state.lang.lang);
  const [data, setData] = useState({
    name: '',
    email: '',
    content: ''
  });
  const handleChange = (target) => {
    setData((previousState) => ({
      ...previousState,
      [target.name]: target.value
    }));
  };

  const handleSubmit = async () => {
    await commentService.createFeedback(data.name, data.email, data.content);
  };
  const windowWidth = window.innerWidth;

  return (
    <div className="container-fluid relative mx-auto flex h-screen items-start justify-center bg-cream text-brown">
      {windowWidth > 400
        ? (
        <div className="mx-auto grid w-full max-w-[1496px] grid-cols-2">
          <div className="h-screen p-[20px]">
            <iframe
              title={'googleMaps'}
              src="http://maps.google.com/maps?q=45.25878370498284, 19.81603151844797&z=14&output=embed"
              className="mb-[20px] h-[calc(50%)] w-full rounded-2xl"
            ></iframe>
            <p className="mb-[20px]">
              Илије Бирчанина 29, Нови Сад, Новис Сад 21000, Serbia
            </p>
            <a className="hover:opacity-80" href="tel:+381693005555">
              +381693005555
            </a>
          </div>
          <div className="p-[20px]">
            <TextField
              value={data.name}
              name="name"
              label="Имя"
              placeholder={dictionary[selectedLanguage].yourName}
              type="text"
              onChange={handleChange}
            ></TextField>
            <TextField
              value={data.email}
              name="email"
              label="E-mail"
              placeholder="E-mail"
              type="email"
              onChange={handleChange}
            ></TextField>
            <TextField
              value={data.content}
              name="content"
              label={dictionary[selectedLanguage].message}
              placeholder=""
              type="text"
              onChange={handleChange}
              isArea={true}
            ></TextField>
            <div className="flex justify-end">
              <button
                onClick={handleSubmit}
                className="mt-[24px] w-[50%] rounded-lg bg-brown py-[8px] text-center text-white hover:opacity-80"
              >
                {dictionary[selectedLanguage].confirm}
              </button>
            </div>
          </div>
        </div>
          )
        : (
        <div className="mx-auto w-full max-w-[1496px]">
          <div className="p-[20px] max-md:p-[5px]">
            <TextField
              value={data.name}
              name="name"
              label={dictionary[selectedLanguage].yourName}
              placeholder={dictionary[selectedLanguage].yourName}
              type="text"
              onChange={handleChange}
            ></TextField>
            <TextField
              value={data.email}
              name="email"
              label="E-mail"
              placeholder="E-mail"
              type="email"
              onChange={handleChange}
            ></TextField>
            <TextField
              value={data.content}
              name="content"
              label={dictionary[selectedLanguage].message}
              placeholder=""
              type="text"
              onChange={handleChange}
              isArea={true}
            ></TextField>
            <div className="flex justify-end">
              <button
                onClick={handleSubmit}
                className="mt-[24px] w-[50%] rounded-lg bg-brown py-[8px] text-center text-white hover:opacity-80"
              >
                {dictionary[selectedLanguage].confirm}
              </button>
            </div>
          </div>

          <div className="h-full p-[20px]">
            <iframe
              title={'googleMaps'}
              src="https://maps.google.com/maps?q=45.25878370498284, 19.81603151844797&z=14&output=embed"
              className="mb-[20px] h-[calc(50%)] w-full rounded-2xl max-md:h-[320px]"
            ></iframe>
            <p className="mb-[20px]">
              Илије Бирчанина 29, Нови Сад, Новис Сад 21000, Serbia
            </p>
            <a className="hover:opacity-80" href="tel:+381693005555">
              +381693005555
            </a>
          </div>
        </div>
          )}
    </div>
  );
};

export default FeedbackPage;
