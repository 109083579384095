import { combineReducers } from '@reduxjs/toolkit';
import authSlice from 'src/store/authSlice';
import recordSlice from 'src/store/recordSlice';
import userSlice from 'src/store/userSlice';
import clientSlice from 'src/store/clientSlice';
import languageReducer from 'src/reducers/language/languageSlice';
import servicesReducer from 'src/reducers/services/servicesSlice';
import { servicesApi } from 'src/services/services/services.service';
import { usersApi } from 'src/services/users/users.services';
import { recordsApi } from 'src/services/records/records.service';
import usersReducer from 'src/reducers/users/usersSlice';
import { clientsApi } from 'src/services/clients/clients.service';
import dateReducer from './date/dateSlice';
import { commentsApi } from 'src/services/comments/comments.service';

export const rootReducer = combineReducers({
  user: userSlice,
  client: clientSlice,
  auth: authSlice,
  record: recordSlice,
  // Updated reducers
  lang: languageReducer,
  date: dateReducer,
  // New reducers
  users: usersReducer,
  services: servicesReducer,
  [commentsApi.reducerPath]: commentsApi.reducer,
  [servicesApi.reducerPath]: servicesApi.reducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [recordsApi.reducerPath]: recordsApi.reducer,
  [clientsApi.reducerPath]: clientsApi.reducer
});
