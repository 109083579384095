import React from 'react';
import CommentElement from './components/comment-element/commentElement';
import dictionary from '../../../utils/dictionary/dictionary';
import { useAppSelector } from 'src/hooks/api';
import { useGetCommentsByClientIdQuery } from 'src/services/comments/comments.service';

interface Props {
  clientId?: number;
}

const ClientPageComments: React.FC<Props> = ({ clientId }) => {
  const selectedLanguage = useAppSelector((state) => state.lang.lang);
  const { data: comments } = useGetCommentsByClientIdQuery(Number(clientId));

  if (!comments) {
    return null;
  }

  if (!comments.length) {
    return (
      <>
        <h2 className="mt-[32px] text-2xl text-brown">
          {dictionary[selectedLanguage].commentsList}
        </h2>
        <p>{dictionary[selectedLanguage].noData}</p>
      </>
    );
  }

  return (
    <>
      <h2 className="mt-[32px] text-2xl text-brown">
        {dictionary[selectedLanguage].commentsList}
      </h2>

      {comments.map((comment) => (
        <CommentElement key={comment.createdAt} comment={comment} />
      ))}
    </>
  );
};

export default ClientPageComments;
