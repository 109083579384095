import { Slot } from 'src/reducers/services/types';
import { AvailableRecord, RecordType } from 'src/services/records/types';
import { capitalize } from 'src/utils/text/text';

const currentDate = new Date();
const currentHour = currentDate.getHours();

export const getBorderClass = (hour: number) => {
  if (currentHour === hour) {
    return 'border-y-red';
  } else if (currentHour === hour + 1) {
    return 'border-b-red';
  } else {
    return null;
  }
};

export const filterUniqueRecords = (records?: (Slot | AvailableRecord)[]) => {
  const seen = new Set();

  return records
    ? records.filter(item => {
      if (seen.has(item.clientId)) {
        return false;
      } else {
        seen.add(item.clientId);

        return true;
      }
    })
    : [];
};

export const getSlotData = (date: Date, record: Slot | AvailableRecord, userName: string, id: number, boardDate: string): Slot => ({
  slotId: `${String(date)}${record.start}${userName}`,
  start: record.start,
  end: record.end,
  duration: record.duration,
  type: record.type,
  date: boardDate,
  top: record.top ?? 0,
  recordId: record.recordId,
  clientId: record.clientId,
  userId: id
});

export const getRecordClassNames = (isRecordVisible: boolean, recordType: RecordType) => {
  let styleName = `absolute flex justify-center left-[3px] w-[calc(100%-6px)] items-center rounded-lg p-[3px] border text-xs text-center hover:opacity-100 bg-${
    recordType
  } text-dark${capitalize(recordType)} border-dark${capitalize(
    recordType
  )} hover:bg-opacity-100`;

  switch (recordType) {
    case RecordType.GREEN: {
      styleName += ' opacity-0 ';
      break;
    }
    case RecordType.YELLOW: {
      styleName += ' bg-cream text-darkBrown border-darkBrown';
      break;
    }
    case RecordType.GRAY: {
      styleName += 'bg-white text-black border-gray';
      break;
    }
  }

  return isRecordVisible || recordType !== RecordType.GREEN ? styleName + 'opacity-100' : styleName;
};
