/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useState } from 'react';
import { getFields } from './helpers';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import dictionary from 'src/utils/dictionary/dictionary';
import { useAppDispatch, useAppSelector } from 'src/hooks/api';
import { Form, FormFields, FormState as FormStateI } from './types';
import yupSchema from './schema';
import { DAY_OFF_LIST } from 'src/constants/variables';
import { DEFAULT_FORM_VALUES } from './constants';
import { toast } from 'react-toastify';
import localStorageService from 'src/services/local-storage/localStorage.service';
import { useGetUserByIdQuery } from 'src/services/users/users.services';
import {
  useCreateNewComplexMutation,
  useCreateNewComplexWithRegisterMutation,
  useCreateNewRecordMutation,
  useCreateNewRecordWithRegisterMutation,
} from 'src/services/records/records.service';
import {
  changeSlotNumberForChanges,
  removeSelectedSlots,
  selectComplex,
  selectService,
  selectSlot,
  setComplexNumber,
} from 'src/reducers/services/servicesSlice';
import { Client } from 'src/services/clients/types';

interface Props {
  client: Client | null;
  setIfFormValid: (value: boolean) => void;
  onChange: (data: FormStateI) => void;
  onFormSubmit: () => void;
}

const EditorForm: React.FC<Props> = ({
  client,
  setIfFormValid,
  onChange,
  onFormSubmit,
}) => {
  const userId = localStorageService.getUserId();
  const dispatch = useAppDispatch();
  const fields = getFields();
  const selectedLanguage = useAppSelector((state) => state.lang.lang);
  const { selectedService, selectedSlot, selectedSlots, selectedComplex } =
    useAppSelector((state) => state.services);
  const { data: selectedUser } = useGetUserByIdQuery(Number(userId));
  const [
    createRecord,
    { isSuccess: onNewRecordSuccess, isError: onNewRecordError },
  ] = useCreateNewRecordMutation();
  const [
    createRecordWithRegister,
    {
      isSuccess: onNewRecordRegisterSuccess,
      isError: onNewRecordRegisterError,
    },
  ] = useCreateNewRecordWithRegisterMutation();
  const [
    createComplex,
    { isSuccess: onNewComplexSuccess, isError: onNewComplexError },
  ] = useCreateNewComplexMutation();
  const [
    createComplexWithRegister,
    {
      isSuccess: onNewComplexWithRegisterSuccess,
      isError: onNewComplexWithRegisterError,
    },
  ] = useCreateNewComplexWithRegisterMutation();

  const successNotify = () =>
    toast.success(dictionary[selectedLanguage].success);
  const errorNotify = () => toast.error(dictionary[selectedLanguage].error);
  const noClientDataNotify = () =>
    toast.error('Заполните как минимум одно поле для создания нового клиента');

  const [isFormValid, setIsFormValid] = useState(false);

  const { formState, register, watch, getValues, reset } = useForm<Form>({
    mode: 'onChange',
    defaultValues: DEFAULT_FORM_VALUES,
    resolver: yupResolver(yupSchema),
  });

  const { errors, isValid } = formState;

  const phoneValue = watch(FormFields.PHONE);

  const setFormData = () => {
    onChange(getValues() as FormStateI);
  };

  useEffect(() => {
    setIfFormValid(isValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid]);

  useEffect(() => {
    if (
      DAY_OFF_LIST.includes(selectedService?.en ?? '') ||
      (isValid && selectedSlot) ||
      (isValid &&
        selectedSlots.length)
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [
    isValid,
    phoneValue,
    selectedComplex?.services?.length,
    selectedService?.en,
    selectedSlot,
    selectedSlots,
    selectedSlots.length,
    client,
  ]);

  useEffect(() => {
    if (
      onNewRecordSuccess ||
      onNewRecordRegisterSuccess ||
      onNewComplexSuccess ||
      onNewComplexWithRegisterSuccess
    ) {
      successNotify();
    }
    if (
      onNewRecordError ||
      onNewRecordRegisterError ||
      onNewComplexError ||
      onNewComplexWithRegisterError
    ) {
      errorNotify();
    }
  }, [
    onNewRecordSuccess,
    onNewRecordRegisterSuccess,
    onNewComplexSuccess,
    onNewComplexWithRegisterSuccess,
    onNewRecordError,
    onNewRecordRegisterError,
    onNewComplexError,
    onNewComplexWithRegisterError,
  ]);

  const isEditorFormValid = () => {
    const formValues = getValues();

    if (formValues[FormFields.PHONE] && formValues[FormFields.PHONE].length < 11 &&
        Object.values([formValues].map(({ phone, ...rest }) => rest)[0]).every(
          (value) => !value.length,
        )
    ) {
      noClientDataNotify();

      return false;
    }

    return true;
  };

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();

    if (DAY_OFF_LIST.includes(selectedService?.en ?? '')) {
      await createRecord({
        userId: selectedSlot?.userId,
        clientId: 1,
        serviceId: selectedService?.id,
        date: selectedSlot?.date,
        time: selectedSlot?.start,
        author: selectedUser?.name,
      });

      onFormSubmit();
      dispatch(selectSlot(null));
      dispatch(changeSlotNumberForChanges(null));
      dispatch(setComplexNumber(0));
      dispatch(selectComplex(null));
      dispatch(selectService(null));
      dispatch(removeSelectedSlots());

      return;
    }

    if (!client) {
      const isValid = isEditorFormValid();

      if (!isValid) {
        return;
      }
    }

    await (client
      ? createRecord({
        userId: selectedSlot?.userId,
        clientId: client?.id,
        serviceId: selectedService?.id,
        date: selectedSlot?.date,
        time: selectedSlot?.start,
        author: selectedUser?.name,
      })
      : createRecordWithRegister({
        userId: selectedSlot?.userId,
        serviceId: selectedService?.id,
        date: selectedSlot?.date,
        time: selectedSlot?.start,
        author: selectedUser?.name,
        phone: getValues()[FormFields.PHONE] || '',
        instagram: getValues()[FormFields.INSTAGRAM]!,
        telegram: getValues()[FormFields.TELEGRAM]!,
        name: getValues()[FormFields.NAME]!,
        authorId: Number(userId),
      }));
    onFormSubmit();
    dispatch(selectSlot(null));
    dispatch(changeSlotNumberForChanges(null));
    dispatch(setComplexNumber(0));
    dispatch(selectComplex(null));
    dispatch(selectService(null));
    dispatch(removeSelectedSlots());
    reset();
  };

  const handleMassSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    const records = selectedSlots?.map((slot) => {
      return {
        userId: slot.userId,
        serviceId: slot.serviceId,
        date: slot.date,
        time: slot.start,
      };
    });

    const serviceInfo = selectedSlots?.map((slot) => ({ userId: slot.userId, date: slot.date }));

    if (!client) {
      const isValid = isEditorFormValid();

      if (!isValid) {
        return;
      }
    }

    await (client
      ? createComplex({
        data: {
          author: selectedUser?.name,
          clientId: client?.id,
          records,
        },
        serviceInfo,
      })
      : createComplexWithRegister({
        data: {
          records,
          author: selectedUser?.name || '',
          phone: getValues()[FormFields.PHONE] ?? '',
          instagram: getValues()[FormFields.INSTAGRAM]!,
          telegram: getValues()[FormFields.TELEGRAM]!,
          name: getValues()[FormFields.NAME]!,
          authorId: Number(userId),
        },
        serviceInfo,
      }));

    onFormSubmit();
    dispatch(setComplexNumber(0));
    dispatch(selectComplex(null));
    dispatch(selectService(null));
    dispatch(selectSlot(null));
    dispatch(removeSelectedSlots());
    reset();
  };

  return (
    <form onChange={setFormData}>
      {fields.map((field) => (
        <div key={field.name}>
          {field.labelKey && (
            <p className="mb-[8px] font-thin">
              {dictionary[selectedLanguage][field.labelKey]}
            </p>
          )}
          <input
            id={field.name}
            type="text"
            placeholder={field.placeholder}
            className={`w-full rounded-lg border border-lightBrown px-3 py-2 text-lightBrown placeholder:text-lightBrown focus:outline-brown ${
              errors[field.name]?.message ? 'border-red focus:outline-red' : ''
            }`}
            {...register(field.name)}
          />

          {errors[field.name]?.message && (
            <p className="text-sm text-red">{errors[field.name]?.message}</p>
          )}
        </div>
      ))}

      <button
        className={`py-[12px] ${
          isFormValid
            ? 'border-darkBrown bg-cream text-brown'
            : 'border-gray bg-white text-black'
        } mt-[20px] w-full rounded-lg border text-center hover:opacity-80`}
        disabled={!isFormValid}
        onClick={selectedComplex ? handleMassSubmit : handleSubmit}
      >
        {dictionary[selectedLanguage].confirmRecord}
      </button>
    </form>
  );
};

export default EditorForm;
