import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/imgs/logo-cream.svg';
import { useSelector } from 'react-redux';
import localStorageService from '../../services/local-storage/localStorage.service';
import userService from '../../services/user.service';
import clientService from '../../services/client.service';

import LanguageSelector from './languageSelector';
import dictionary from '../../utils/dictionary/dictionary';

const NavBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const crmPage = location.pathname.startsWith('/crm');
  const clientPage = location.pathname.startsWith('/client');
  const isLoggedInAsClient = localStorageService.getClientAccessToken();
  const isLoggedInAsUser = localStorageService.getUserAccessToken();
  const selectedLanguage = useSelector((state) => state.lang.lang);

  const loadData = async () => {
    if (isLoggedInAsUser && crmPage) {
      const user = await userService.getUserById(
        localStorageService.getUserId()
      );

      if (!user) {
        localStorageService.removeUserAuthData();
        navigate('/crm/login');
      }
    }
    if (isLoggedInAsClient && !crmPage) {
      const client = await clientService.getClientById(
        localStorageService.getClientId()
      );

      if (!client) {
        localStorageService.removeClientAuthData();
        navigate('/client/login');
      }
    }
  };

  useEffect(() => {
    loadData();
  });

  const handleUserLogout = () => {
    localStorageService.removeUserAuthData();
    navigate('/crm/login');
  };

  const handleClientLogout = () => {
    localStorageService.removeClientAuthData();
    navigate('/client/login');
  };

  if (crmPage) {
    const phone = isLoggedInAsUser ? localStorageService.getUserPhone() : null;

    return (
      <div className="relative w-full bg-cream text-brown max-md:text-sm">
        <LanguageSelector></LanguageSelector>
        <nav className="mx-auto max-w-[1496px] items-center justify-between pb-[32px] pt-[16px] ">
          <img className="mx-auto mb-[20px]" src={logo} alt="" />
          <div className="flex items-center justify-center pb-[38px] max-md:flex-wrap">
            {isLoggedInAsUser
              ? (
              <>
                <Link
                  to="/crm"
                  className="mr-[52px] hover:opacity-80 max-md:mb-[10px] max-md:mr-[15px]"
                >
                  {dictionary[selectedLanguage].home}
                </Link>
                <Link
                  to="/crm/calendar"
                  className="mr-[52px] hover:opacity-80 max-md:mb-[10px] max-md:mr-[15px]"
                >
                  {dictionary[selectedLanguage].calendar}
                </Link>
                <Link
                  to="/crm/salary"
                  className="mr-[52px] hover:opacity-80 max-md:mb-[10px] max-md:mr-[15px]"
                >
                  {dictionary[selectedLanguage].salary}
                </Link>
                <Link
                  to="/crm/feedbacks"
                  className="mr-[52px] hover:opacity-80 max-md:mb-[10px] max-md:mr-[15px]"
                >
                  {dictionary[selectedLanguage].feedbacks}
                </Link>
                <Link
                  to="/crm/profile"
                  className="mr-[52px] hover:opacity-80 max-md:mb-[10px] max-md:mr-[15px]"
                >
                  {dictionary[selectedLanguage].you}
                  {phone}
                </Link>
                <button
                  className="hover:opacity-80  max-md:mb-[10px]"
                  onClick={handleUserLogout}
                >
                  {dictionary[selectedLanguage].logout}
                </button>
              </>
                )
              : (
              <Link
                to="/crm/login"
                className="hover:opacity-80 max-md:mb-[10px]"
              >
                {dictionary[selectedLanguage].login}
              </Link>
                )}
          </div>
          <hr className="mx-auto w-full border border-brown" />
        </nav>
      </div>
    );
  } else if (clientPage) {
    const phone = isLoggedInAsClient
      ? localStorageService.getClientPhone()
      : null;

    return (
      <div className="w-full bg-cream text-brown max-md:text-sm">
        <LanguageSelector></LanguageSelector>
        <nav className="mx-auto max-w-[1496px] items-center justify-between pb-[32px] pt-[16px] ">
          <img className="mx-auto mb-[20px]" src={logo} alt="" />
          <div className="flex items-center justify-center pb-[38px] max-md:flex-wrap">
            <Link
              to="/client"
              className="mr-[52px] hover:opacity-80 max-md:mb-[10px] max-md:mr-[15px]"
            >
              {dictionary[selectedLanguage].home}
            </Link>
            <Link
              to="/client/record"
              className="mr-[52px] hover:opacity-80 max-md:mb-[10px] max-md:mr-[15px]"
            >
              {dictionary[selectedLanguage].record}
            </Link>
            <Link
              to="/client/feedback"
              className="mr-[52px] hover:opacity-80 max-md:mb-[10px] max-md:mr-[15px]"
            >
              {dictionary[selectedLanguage].feedback}
            </Link>
            <Link
              to="/client/masters"
              className="mr-[52px] hover:opacity-80 max-md:mb-[10px] max-md:mr-[15px]"
            >
              {dictionary[selectedLanguage].masters}
            </Link>
            {isLoggedInAsClient
              ? (
              <>
                <Link
                  to="/client/profile"
                  className="mr-[52px] hover:opacity-80 max-md:mb-[10px] max-md:mr-[15px]"
                >
                  {dictionary[selectedLanguage].you}
                  {phone}
                </Link>
                <button
                  className="hover:opacity-80 max-md:mb-[10px]"
                  onClick={handleClientLogout}
                >
                  {dictionary[selectedLanguage].logout}
                </button>
              </>
                )
              : (
              <Link
                to="/client/login"
                className="hover:opacity-80 max-md:mb-[10px]"
              >
                {dictionary[selectedLanguage].login}
              </Link>
                )}
          </div>
          <hr className="mx-auto w-full border border-brown" />
        </nav>
      </div>
    );
  } else {
    return null;
  }
};

export default NavBar;
