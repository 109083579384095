import React from 'react';
import dictionary from 'src/utils/dictionary/dictionary';
import classNames from 'classnames';
import { RecordApi } from 'src/services/records/types';
import { useAppSelector } from 'src/hooks/api';
import { useGetClientsQuery } from 'src/services/clients/clients.service';
import { getClientHeaders, getCrmHeaders } from './helpers';
import ClientRecordElement from './components/client-record-element/clientRecordElement';
import CrmRecordElement from './components/crm-record-element/CrmRecordElement';

export enum Page {
  USER = 'user',
  CLIENT = 'client',
}

interface Props {
  records: RecordApi[];
  pageType?: Page;
  updateRecords: (index: number) => void;
  updateData: () => void;
}

const RecordsList: React.FC<Props> = ({
  records,
  pageType = Page.CLIENT,
  updateRecords,
  updateData,
}) => {
  const selectedLanguage = useAppSelector((state) => state.lang.lang);
  const { data: clients } = useGetClientsQuery();

  if (!records?.length) {
    return (
      <div className="pt-[32px] text-darkBrown">
        {dictionary[selectedLanguage].noData}
      </div>
    );
  }

  const renderHeader = (headers: string[]) => (
    <div
      className={classNames(
        `grid grid-cols-${headers.length} rounded-t-lg border border-gray bg-lightGray font-bold`,
      )}
    >
      {headers.map((header, index) => (
        <div
          key={index}
          className="px-[20px] py-[12px] max-md:px-[10px] max-md:py-[6px]"
        >
          {header}
        </div>
      ))}
    </div>
  );

  return (
    <div className="max-h-[800px] overflow-y-scroll pt-[32px] text-darkBrown max-md:text-xs">
      {pageType === Page.CLIENT
        ? renderHeader(getClientHeaders(selectedLanguage))
        : renderHeader(getCrmHeaders(selectedLanguage))}
      {records.map((record) =>
        pageType === Page.CLIENT
          ? (
      <ClientRecordElement
        key={record.id}
        record={record}
        isLast={records.at(-1) === record}
      />
            )
          : (
      <CrmRecordElement
        key={record.id}
        record={record}
        records={records}
        isLast={records.at(-1) === record}
        clients={clients}
        updateRecords={updateRecords}
        updateData={updateData}
      />
            )
      )}
    </div>
  );
};

export default RecordsList;
