export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const validatePhoneNumber = (phone: string) => {
  if (
    phone.startsWith('+3') &&
    !(/^\+3\d{10}$/.test(phone) || /^\+3\d{11}$/.test(phone))
  ) {
    return 'phone must contain 11-12 digits';
  }
  if (phone.startsWith('+7') && !/^\+7\d{10}$/.test(phone)) {
    return 'phone must contain 11 digits';
  }
  if (
    !phone.startsWith('+3') &&
    !phone.startsWith('+7') &&
    phone.length > 1
  ) {
    return "phone must start  with '7' or '3'";
  }
};
