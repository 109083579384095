export enum StorageKeys {
  CLIENT_TOKEN_KEY = 'jwt-token',
  CLIENT_EXPIRES_KEY = 'jwt-expires',
  CLIENT_ID_KEY = 'client-id',
  CLIENT_PHONE_KEY = 'client-phone',
  USER_TOKEN_KEY = 'user-jwt-token',
  USER_EXPIRES_KEY = 'user-jwt-expires',
  USER_ID_KEY = 'user-id',
  USER_PHONE_KEY = 'user-phone',
  LANGUAGE_KEY = 'language',
  CALENDAR_DATE = 'calendar-date',
  DISPLAYED_USER_IDS = 'displayed-user-ids',
}
