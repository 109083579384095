import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DateState } from './types';

const initialState: DateState = {
  date: `${new Date().toLocaleString('ru-ru', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  })}`
};

const dateSlice = createSlice({
  name: 'date',
  initialState,
  reducers: {
    setDate: (state, action: PayloadAction<string>) => {
      state.date = action.payload;
    }
  }
});

export const { setDate } = dateSlice.actions;

export default dateSlice.reducer;
