import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';

interface Props {
  isVisible: boolean;
  onClose: () => void;
}

const Modal: React.FC<PropsWithChildren<Props>> = ({
  children,
  isVisible,
  onClose,
}) => isVisible
  ? (
  <>
    <div
      className="absolute inset-0 bg-cream opacity-70"
      style={{ zIndex: 200, height: document.body.scrollHeight }}
      onClick={onClose}
    />
    <div
      className={classNames(
        'absolute h-max top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white border-2 rounded-md flex flex-col items-center justify-between p-4',
        {
          'opacity-0': !isVisible,
          'opacity-100': isVisible,
        },
      )}
      style={{ zIndex: 201 }}
    >
      {children}
    </div>
  </>
    )
  : null;

export default Modal;
