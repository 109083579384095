import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import apiEndpoint from '../constants';
import { Service, Complex } from './types';

export const servicesApi = createApi({
  reducerPath: 'servicesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${apiEndpoint}/api/services`,
  }),
  endpoints: (builder) => ({
    getServices: builder.query<Service[], void>({
      query: () => ''
    }),
    getComplexes: builder.query<Complex[], void>({
      query: () => 'complexes'
    })
  })
});

export const { useGetServicesQuery, useGetComplexesQuery } = servicesApi;
