import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ServicesState, Slot } from './types';
import { Complex, Service } from 'src/services/services/types';

const initialState: ServicesState = {
  selectedService: null,
  selectedComplex: null,
  complexes: [],
  complexNumber: 0,
  selectedSlot: null,
  selectedSlots: [],
  slotNumberForChanges: null
};

export const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    selectService: (state, action: PayloadAction<Service | null>) => {
      state.selectedService = action.payload;
    },
    selectComplex: (state, action: PayloadAction<Complex | null>) => {
      state.selectedComplex = action.payload;
      state.selectedService = action.payload?.services[state.complexNumber] ?? null;
    },
    setComplexes: (state, action: PayloadAction<Complex[]>) => {
      state.complexes = action.payload;
    },
    setComplexNumber: (state, action: PayloadAction<number>) => {
      state.complexNumber = action.payload;
      state.selectedService = state.selectedComplex?.services[action.payload] ?? null;
    },
    increaseComplexNumber: (state) => {
      state.complexNumber = state.complexNumber + 1;
      state.selectedService = state.selectedComplex?.services[state.complexNumber] ?? null;
    },
    selectSlot: (state, action: PayloadAction<Slot | null>) => {
      state.selectedSlot = action.payload;
    },
    selectSlots: (state, action: PayloadAction<Slot>) => {
      state.selectedSlots = [...state.selectedSlots, action.payload];
    },
    removeSelectedSlots: (state) => {
      state.selectedSlots = [];
    },
    changeSlotNumberForChanges: (state, action: PayloadAction<number | null>) => {
      state.slotNumberForChanges = action.payload;
    }
  }
});

export const {
  selectService,
  selectComplex,
  setComplexes,
  setComplexNumber,
  increaseComplexNumber,
  selectSlot,
  selectSlots,
  removeSelectedSlots,
  changeSlotNumberForChanges
} = servicesSlice.actions;

export default servicesSlice.reducer;
