import React from 'react';
import dictionary from 'src/utils/dictionary/dictionary';
import { useAppSelector } from 'src/hooks/api';
import { RecordApi } from 'src/services/records/types';
import { transformDateOrder } from 'src/utils/date/transformDate';

interface Props {
  isLast: boolean;
  record: RecordApi;
}

const RecordElement: React.FC<Props> = ({ record, isLast }) => {
  const selectedLanguage = useAppSelector((state) => state.lang.lang);

  return (
    <>
      <div
        className={
          isLast
            ? 'grid grid-cols-6 rounded-b-lg border border-gray max-md:grid-cols-12'
            : 'grid grid-cols-6 border border-gray max-md:grid-cols-12'
        }
      >
        <div className="px-[20px] py-[12px] max-md:col-span-2 max-md:p-[6px]">
          {record?.user?.name}
        </div>
        <div className="px-[20px] py-[12px] max-md:p-[6px]">{`${record.id}`}</div>
        <div className="px-[20px] py-[12px] max-md:col-span-2 max-md:p-[6px]">
          {record?.service[selectedLanguage]}
        </div>
        <div className="px-[20px] py-[12px] max-md:col-span-3 max-md:p-[6px] max-md:text-center">
          {record?.service?.price}
          {record.status === 'completed'
            ? (
            <div>
              <b>{record?.price}</b>
            </div>
              )
            : null}
        </div>
        <div className="px-[20px] py-[12px] max-md:col-span-2 max-md:p-[6px]">
          {`${record.time.slice(0, 5)} ${transformDateOrder(record.date)}`}
        </div>
        <div className="flex items-center justify-start px-[20px] py-[12px] max-md:items-start max-md:p-[6px]">
          {record.image
            ? (
            <a href={record.image} target="_blank" rel="noopener noreferrer">
              {dictionary[selectedLanguage].photo}
            </a>
              )
            : null}
        </div>
      </div>
    </>
  );
};

export default RecordElement;
