import React from 'react';
import PropTypes from 'prop-types';
import logo from '../../assets/imgs/logo.png';

export default function AuthCard ({ children }) {
  return (
    <div className="absolute z-10 mx-auto max-w-[402px] self-start rounded-lg border border-solid border-lightBrown bg-white px-[31px] py-[32px] max-md:max-w-[330px]">
      <img className="w-100 mb-[24px]" src={logo} alt="" />
      {children}
    </div>
  );
}

AuthCard.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};
