import React, { PropsWithChildren } from 'react';
import dropdownArrow from 'src/assets/imgs/dropdown-arrow.png';
import { useAppSelector } from 'src/hooks/api';
import dictionary from 'src/utils/dictionary/dictionary';

interface Props {
  value: string;
  isServiceTypeSelect: boolean;
  onChevronBarClick: () => void;
  onChange: (value: string) => void;
}

const SearchBar: React.FC<PropsWithChildren<Props>> = ({
  children,
  value,
  isServiceTypeSelect,
  onChevronBarClick,
  onChange,
}) => {
  const selectedLanguage = useAppSelector((state) => state.lang.lang);

  return (
  <div className="absolute left-0 top-full z-10 max-h-[300px] w-full overflow-y-scroll rounded-lg border border-gray bg-white opacity-100">
    <input
      value={value}
      type="text"
      name="search"
      placeholder="Search"
      className="w-full cursor-pointer rounded-t-lg border-b border-gray bg-white px-[16px] py-[7px] text-brown hover:text-lightBrown"
      onChange={(e) => {
        onChange(e.target.value);
      }}
    />
    <div
      className="flex cursor-pointer justify-between border-b border-gray bg-white px-[16px] py-[7px] text-brown first:rounded-t-lg last:rounded-b-lg last:border-none hover:text-lightBrown"
      onClick={onChevronBarClick}
    >
      <span className={`${isServiceTypeSelect ? 'order-last' : ''}`}>
        {isServiceTypeSelect
          ? dictionary[selectedLanguage].complex
          : dictionary[selectedLanguage].service}
      </span>
      <img
        className={`${isServiceTypeSelect ? '-rotate-90' : 'rotate-90'}`}
        src={dropdownArrow}
        alt=""
      />
    </div>
    {children}
  </div>
  );
};

export default SearchBar;
