import dictionary from '../../../utils/dictionary/dictionary';
import { LanguageKeys } from '../../../utils/dictionary/types';
import { Day } from './types';

export const getCustomLocale = (selectedLanguage: LanguageKeys) => ({
  months: dictionary[selectedLanguage].secondMonth,

  weekDays: [
    {
      name: 'Понедельник',
      short: dictionary[selectedLanguage].shortDays[0],
      isWeekend: true
    },
    {
      name: 'Вторник',
      short: dictionary[selectedLanguage].shortDays[1]
    },
    {
      name: 'Среда',
      short: dictionary[selectedLanguage].shortDays[2]
    },
    {
      name: 'Четверг',
      short: dictionary[selectedLanguage].shortDays[3]
    },
    {
      name: 'Пятница',
      short: dictionary[selectedLanguage].shortDays[4]
    },
    {
      name: 'Суббота',
      short: dictionary[selectedLanguage].shortDays[5]
    },
    {
      name: 'Воскресенье',
      short: dictionary[selectedLanguage].shortDays[6],
      isWeekend: true
    }
  ],

  weekStartingIndex: 0,

  getToday (day: Day) {
    return day;
  },

  toNativeDate (day: Day) {
    return new Date(day.year, day.month - 1, day.day);
  },

  getMonthLength (day: Day) {
    return new Date(day.year, day.month, 0).getDate();
  },

  transformDigit (digit: string | number) {
    return digit;
  },

  nextMonth: 'Следующий',
  previousMonth: 'Прошлый',
  openMonthSelector: 'Выбрать месяц',
  openYearSelector: 'Выбрать год',
  closeMonthSelector: 'Закрыть',
  closeYearSelector: 'Закрыть',
  defaultPlaceholder: 'Выбрать',

  // for input range value
  from: 'from',
  to: 'to',

  digitSeparator: '.',

  yearLetterSkip: 0,

  isRtl: false
});

export const transformDate = (date: Date) => (date
  ? {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear()
    }
  : undefined);
