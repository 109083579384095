export enum FormFields {
  PHONE = 'phone',
  INSTAGRAM = 'instagram',
  TELEGRAM = 'telegram',
  NAME = 'name',
}

export interface Form {
  [FormFields.PHONE]?: string;
  [FormFields.INSTAGRAM]?: string;
  [FormFields.TELEGRAM]?: string;
  [FormFields.NAME]?: string;
}

export interface FormState {
  [FormFields.PHONE]: string;
  [FormFields.INSTAGRAM]: string;
  [FormFields.TELEGRAM]: string;
  [FormFields.NAME]: string;
}
