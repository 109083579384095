import { type Dictionary, LanguageKeys } from './types';

const dictionary: Dictionary = {
  [LanguageKeys.RU]: {
    filter: 'Фильтр',
    people: 'Люди',
    displayed: 'Отображаемые',
    userNotFound: 'Пользователь не найден',
    recordsNotFound: 'Записи не найдены',
    records: 'Записи',
    at: 'в',
    hidden: 'Скрытые',
    sortBy: 'Сортировать по:',
    save: 'Сохранить',
    home: 'Главная',
    calendar: 'График',
    salary: 'Зарплата',
    feedbacks: 'Отзывы',
    you: 'Вы - ',
    login: 'Войти',
    record: 'Записаться',
    feedback: 'Обратная связь',
    masters: 'Мастера',
    dayOff: 'Перерыв',
    booked: 'Забронировано',
    service: 'Услуга',
    editBooking: 'Редактирование слота',
    clientFound: 'Клиент найден',
    clientsPhone: 'Номер телефона клиента',
    clientsInstagram: 'Инстаграм клиента',
    clientsTelegram: 'Телеграм клиента',
    confirmRecord: 'Записать',
    registerClient: 'Создать клиента',
    success: 'Сохранено',
    phoneRequired: 'Телефон обязателен',
    registered: 'Зарегистрирован',
    weekdays: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
    confirm: 'Подтверждение',
    smsCode: 'Введите код из смс для подтверждения',
    enter: 'Вход',
    phoneToLogin: 'Укажите свой номер телефона для входа',
    phoneNumber: 'Номер телефона',
    serviceList: 'Список услуг',
    name: 'Название',
    userName: 'Имя',
    price: 'Стоимость',
    time: 'Время',
    time_date: 'Время и дата',
    status: 'Статус',
    noData: 'Данные не найдены',
    leaveFeedback: 'Оставить отзыв',
    closeRecord: 'Закрытие записи',
    finalPrice: 'Итоговая стоимость',
    comment: 'Комментарий',
    send: 'Отправить',
    toClientsList: 'Вернуться к списку клиентов',
    recordHistory: 'История заказов',
    master: 'Мастер',
    recordNumber: 'Номер заказа ',
    photo: 'Фото',
    edit: 'Редактировать',
    commentsList: 'Список отзывов',
    client: 'Клиент',
    staff: 'Сотрудник',
    yourProfile: 'Ваш профиль',
    profileEdit: 'Редактирование профиля',
    yourName: 'Ваше имя',
    birthday: 'Дата рождения',
    rating: 'Рейтинг',
    rateClient: 'Оставить отзыв клиенту',
    giveFeedback: 'Оставить отзыв',
    back: 'Назад',
    selectRecord: 'Выбирите запись для отзыва',
    theRecord: 'Запись',
    date: 'Дата',
    cancel: 'Отменить',
    author: 'Автор',
    clientHimself: 'Сам клиент',
    position: 'Должность',
    experience: 'Опыт',
    message: 'Сообщение',
    toUsersList: 'Вернуться к списку cотрудников',
    phoneNumberWithPassword: 'Укажите номер телефона и пароль для входа',
    enterPassword: 'Введите пароль',
    password: 'Пароль',
    logout: 'Выход',
    close: 'Закрыть',
    clientsName: 'Имя клиента',
    firstMonth: [
      'января',
      'февраля',
      'марта',
      'апреля',
      'мая',
      'июня',
      'июля',
      'августа',
      'сентября',
      'октября',
      'ноября',
      'декабря'
    ],
    secondMonth: [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь'
    ],
    calendarLanguage: 'ru-ru',
    shortDays: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    expShort: 'Опыт',
    feedbackClient: 'Отзыв',
    portfolio: 'Портфолио',
    about: 'О нас',
    team: 'Команда',
    contact: 'Контакты',
    unlock: 'Откройте великолепие своей привлекательности',
    unlockLg: 'Откройте силу вечной красоты с нашими изысканными услугами. Почувствуйте роскошное омоложение и божественное очарование, отправляясь в путешествие открытия истинной себя. Каждый момент раскроет ковер изящества, где каждая деталь отражает совершенство, пребывающее внутри. Наши вечные процедуры красоты поднимут вас на вершину изящного великолепия.',
    portfolioTitle: 'Наш проффесионализм',
    aboutTitle: 'Разблокируйте Вечную Роскошь: Когда Красота Превышает Время',
    aboutDescr: 'ЗДОРОВЫЕ НОГТИ',
    aboutDescrLg:
      'Почувствуйте вершину роскошной красоты и омоложения во Вечной Красоте. Отправьтесь в путешествие, чтобы раскрыть свою внутреннюю грацию и показать вечную элегантность, пребывающую внутри вас. Превратите свои ногти в изысканные произведения искусства с нашими роскошными услугами нейл-арт. Во Вечной Красоте мы приоритетно относимся к здоровью и жизненной силе ваших ногтей.',
    meet: 'Встречайте нашу ',
    teamLow: 'команду',
    cqs: 'Специалист и директор по качеству',
    lm: 'Старший мастер и руководитель смены',
    am: 'Управляющий администратор',
    contacts: 'Контакты',
    passwordRecovery: 'Восстановить пароль',
    passwordRecoveryDescrcription:
      'Введите номер телефона, пароль придет в виде смс',
    recover: 'Восстановить',
    all: 'Все',
    mine: 'Мои',
    created: 'Созданые',
    completed: 'Завершенные',
    canceled: 'Отмененные',
    error: 'Неудача',
    cameFrom: 'Откуда узнали',
    complex: 'Комплекс',
    currentSelection: 'Текущий выбор',
    confirmComplex: 'Подтвердите запись',
    communication: 'Коммуникация',
    notFound: 'Ой... Страница не найдена',
    history: 'История',
    info: 'Детали',
    fullHistory: 'Вся история',
    monthHistory: 'История за месяц'
  },
  [LanguageKeys.EN]: {
    filter: 'Filter',
    people: 'People',
    displayed: 'Displayed',
    hidden: 'Hidden',
    sortBy: 'Sort by:',
    save: 'Save',
    home: 'Home',
    userNotFound: 'User not found',
    recordsNotFound: 'Records not found',
    records: 'Records',
    at: 'at',
    calendar: 'Calendar',
    salary: 'Salary',
    feedbacks: 'Feedbacks',
    you: 'You - ',
    login: 'Login',
    record: 'Record',
    feedback: 'Feedback',
    masters: 'Masters',
    dayOff: 'Break',
    booked: 'Reserved',
    service: 'Service',
    editBooking: 'Edit booking',
    clientFound: 'Client found',
    clientsPhone: "Client's phone",
    clientsInstagram: "Client's instagram",
    clientsTelegram: "Client's telegram",
    confirmRecord: 'Confirm record',
    registerClient: 'Register client',
    success: 'Success',
    phoneRequired: 'Phone required',
    registered: 'Registered',
    weekdays: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    confirm: 'Confirm',
    smsCode: 'Enter code from sms',
    enter: 'Enter',
    phoneToLogin: 'Enter your phone to login',
    phoneNumber: 'Phone number',
    serviceList: 'Service list',
    name: 'Name',
    userName: 'Name',
    price: 'Price',
    time: 'Time',
    time_date: 'Time and Date',
    status: 'Status',
    noData: 'No data found',
    leaveFeedback: 'Leave feedback',
    closeRecord: 'Close record',
    finalPrice: 'Final price',
    comment: 'Comment',
    send: 'Send',
    toClientsList: 'To users list',
    recordHistory: 'Record history',
    master: 'Master',
    recordNumber: 'Record number ',
    photo: 'Photo',
    edit: 'Edit',
    commentsList: 'Comment list',
    client: 'Client',
    staff: 'Staff',
    yourProfile: 'Your profile',
    profileEdit: 'Edit profile',
    yourName: 'Your name',
    birthday: 'Birthday',
    rating: 'Rating',
    rateClient: 'Leave feedback to client',
    giveFeedback: 'Leave feedback',
    back: 'Back',
    selectRecord: 'Select record to rate',
    theRecord: 'Record',
    date: 'Date',
    cancel: 'Cancel',
    author: 'Author',
    clientHimself: 'Client by himself',
    position: 'Position',
    experience: 'Experience',
    message: 'Message',
    toUsersList: 'To users list',
    phoneNumberWithPassword: 'Enter phone number and password',
    enterPassword: 'Enter password',
    password: 'Password',
    logout: 'Logout',
    close: 'Close',
    clientsName: "Client's name",
    firstMonth: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ],
    secondMonth: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ],
    calendarLanguage: 'en-us',
    shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    expShort: 'Exp.',
    feedbackClient: 'Feedback',
    portfolio: 'Portfolio',
    about: 'About',
    team: 'Team',
    contact: 'Contect',
    unlock: 'Unlock Your Glamour',
    unlockLg: `Unlock the power of timeless beauty with our exquisite services.
    Experience luxurious rejuvenation and divine allure as you embark on
    a journey to discover the ultimate you. Each moment will unfold a
    tapestry of elegance, where every detail reflects the perfection
    that resides within. Our timeless beauty treatments will elevate you
    to the pinnacle of graceful splendor.`,
    portfolioTitle: 'Nails Proffesionals',
    aboutTitle: 'Unlock Timeless Luxury: Where Beauty Transcends Time',
    aboutDescr: 'Healthy Nails',
    aboutDescrLg:
      'Experience the pinnacle of luxurious beauty and rejuvenation at Timeless Beauty. Embark on a journey to unlock your inner grace and reveal the timeless elegance that resides within. Transform your nails into exquisite works of art with our luxurious nail artistry services. At Timeless Beauty, we prioritize the health and vitality of your nails.',
    meet: 'Meet the ',
    teamLow: 'team',
    cqs: 'Chief Quality Specialist',
    lm: 'Lead Master',
    am: 'Administrative Manager',
    contacts: 'Contacts',
    passwordRecovery: 'Password recovery',
    passwordRecoveryDescrcription:
      'Enter your phone, password will be sent to you with sms',
    recover: 'Recover',
    all: 'All',
    mine: 'Mine',
    created: 'Created',
    completed: 'Completed',
    canceled: 'Canceled',
    error: 'Error',
    cameFrom: 'Came from',
    complex: 'Complex',
    currentSelection: 'Current selection',
    confirmComplex: 'Confirm complex',
    communication: 'Communication',
    notFound: 'Oops... Page not found',
    history: 'History',
    info: 'Info',
    fullHistory: 'Full History',
    monthHistory: 'Month History'
  },
  [LanguageKeys.SR]: {
    filter: 'Filter',
    people: 'People',
    displayed: 'Displayed',
    hidden: 'Hidden',
    sortBy: 'Sort by:',
    save: 'Save',
    home: 'Glavna',
    userNotFound: 'User not found',
    recordsNotFound: 'Records not found',
    at: 'at',
    records: 'Records',
    calendar: 'Raspored',
    salary: 'Plata',
    feedbacks: 'Utisci',
    you: 'Vi - ',
    login: 'Ući',
    record: 'Upisati se',
    feedback: 'Povratna informacija',
    masters: 'Majstori',
    dayOff: 'Slobodan dan',
    booked: 'Upisano',
    service: 'Usluga',
    editBooking: 'Izmeniti termin',
    clientFound: 'Klijent pronađen',
    clientsPhone: 'Broj telefona klijenta',
    clientsInstagram: 'Instagram klijenta',
    clientsTelegram: 'Telegram klijenta',
    confirmRecord: 'Upisati se',
    registerClient: 'Kreirati klijenta',
    success: 'Sačuvano',
    phoneRequired: 'Telefon obavezan',
    registered: 'Registrovan',
    weekdays: ['Pon', 'Uto', 'Sre', 'Čet', 'Pet', 'Sub', 'Ned'],
    confirm: 'Potvrda',
    smsCode: 'Unesite kod koji je stigao putem sms za potvrdu',
    enter: 'Ulaz',
    phoneToLogin: 'Upišite svoj broj telefona kako biste ušli',
    phoneNumber: 'Broj telefona',
    serviceList: 'Spisak usluga',
    name: 'Naziv',
    userName: 'Ime',
    price: 'Cena',
    time: 'Vreme',
    time_date: 'Vreme i Data',
    status: 'Status',
    noData: 'Podaci nisu pronađeni',
    leaveFeedback: 'Podaci nisu pronađeni',
    closeRecord: 'Podaci nisu pronađeni',
    finalPrice: 'Ukupna suma',
    comment: 'Komentari',
    send: 'Poslati',
    toClientsList: 'Vratite se na spisak klijenata',
    recordHistory: 'Istorija upisanih klijenata',
    master: 'Majstor',
    recordNumber: 'Redni broj termina ',
    photo: 'Fotografija',
    edit: 'Izmeniti',
    commentsList: 'Spisak utisaka',
    client: 'Klijent',
    staff: 'Zaposeni',
    yourProfile: 'Vaš profil',
    profileEdit: 'Izmeniti profil',
    yourName: 'Vaše ime',
    birthday: 'Datum rođenja',
    rating: 'Rejting',
    rateClient: 'Napišite utisak klijentu',
    giveFeedback: 'Napišite utisak',
    back: 'Nazad',
    selectRecord: 'Izaberite slot za utisak',
    theRecord: 'Upisati',
    date: 'Datum',
    cancel: 'Otkazati',
    author: 'Autor',
    clientHimself: 'Klijent',
    position: 'Dužnost',
    experience: 'Iskustvo',
    message: 'Poruka',
    toUsersList: 'Vratiti se na spisak zaposlenih',
    phoneNumberWithPassword: 'Unesite broj telefona i lozinku',
    enterPassword: 'Unesite lozinku',
    password: 'Lozinka',
    logout: 'Izlaz',
    close: 'Zatvoriti',
    clientsName: 'Ime klijenta',
    firstMonth: [
      'Januara',
      'Februara',
      'Marta',
      'Aprila',
      'Maja',
      'Juna',
      'Jula',
      'Avgusta',
      'Septembra',
      'Octobra',
      'Novembra',
      'Decembra'
    ],
    secondMonth: [
      'Januar',
      'Februar',
      'Mart',
      'April',
      'Maj',
      'Jun',
      'Jul',
      'Avgust',
      'Septembar',
      'Octobar',
      'Novembar',
      'Decembar'
    ],
    calendarLanguage: 'en-us',
    shortDays: ['Pon', 'Uto', 'Sre', 'Čet', 'Pet', 'Sub', 'Ned'],
    expShort: 'Isk.',
    feedbackClient: 'Utisak',
    portfolio: 'Portfolio',
    about: 'O nama',
    team: 'Tim',
    contact: 'Kontakt',
    unlock: 'Otkrijte sjaj svoje lepote',
    unlockLg: `Unlock the power of timeless beauty with our exquisite services.
    Experience luxurious rejuvenation and divine allure as you embark on
    a journey to discover the ultimate you. Each moment will unfold a
    tapestry of elegance, where every detail reflects the perfection
    that resides within. Our timeless beauty treatments will elevate you
    to the pinnacle of graceful splendor.`,
    portfolioTitle: 'Naš profesionalizam',
    aboutTitle: 'Otkrijte večnu raskoš: Kada je lepota izvan vremena',
    aboutDescr: 'ZDRAVI NOKTI',
    aboutDescrLg:
      'Doživite vrhunac luksuzne lepote u Timeless Beauty. Krenite na putovanje u kom ćete osloboditi svoju unutrašnju gracioznost i otkriti bezvremensku eleganciju koja se nalazi u Vama. Pretvorite svoje nokte u izuzetna umetnička dela uz naše luksuzne usluge nail - art. U Timeless Beauty salonu prioritet je zdravlje Vaših noktiju.',
    meet: 'Upoznajte naš ',
    teamLow: 'tim',
    cqs: 'Specijalista i direktor kvaliteta',
    lm: 'Stariji majstor i rukovodilac smene',
    am: 'Glavni administrator',
    contacts: 'Kontakt',
    passwordRecovery: 'Resetujte lozinku',
    passwordRecoveryDescrcription:
      'Unesite broj telefona, lozinka će Vam stići putem SMS poruke',
    recover: 'Resetovati',
    all: 'Sve',
    mine: 'Moji',
    created: 'Kreirani',
    completed: 'Završeni',
    canceled: 'Otkazani',
    error: 'Greška',
    cameFrom: 'Kako ste čuli za nas',
    complex: 'Raspon',
    currentSelection: 'Trenutni izbor',
    confirmComplex: 'Potvrda respon',
    communication: 'Communication',
    notFound: 'Упс... Страница није пронађена',
    history: 'History',
    info: 'Info',
    fullHistory: 'Full History',
    monthHistory: 'Month History'
  }
};

export default dictionary;
