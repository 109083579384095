import React from 'react';
import { useAppSelector } from 'src/hooks/api';
import dictionary from 'src/utils/dictionary/dictionary';

interface Props {
  isVisible: boolean;
  isUserStatus: boolean;
  onSelectMine: () => void;
  onSelectAll: () => void;
}

const AdminButtons: React.FC<Props> = ({
  isVisible,
  isUserStatus,
  onSelectMine,
  onSelectAll,
}) => {
  const selectedLanguage = useAppSelector((state) => state.lang.lang);

  if (!isVisible) {
    return null;
  }

  return (
    <div className="mr-[24px] flex items-center max-md:mb-[10px] max-md:mr-0">
      <span
        className={`mr-[5px] cursor-pointer rounded-lg border border-darkBrown px-[8px] py-[4px] text-darkBrown ${
          isUserStatus ? 'bg-cream' : ''
        }`}
        onClick={onSelectMine}
      >
        {dictionary[selectedLanguage].mine}
      </span>
      <span
        className={`cursor-pointer rounded-lg border border-darkBrown px-[8px] py-[4px] text-darkBrown ${
          isUserStatus ? '' : 'bg-cream'
        }`}
        onClick={onSelectAll}
      >
        {dictionary[selectedLanguage].all}
      </span>
    </div>
  );
};

export default AdminButtons;
