import { transformDate } from 'src/utils/date/transformDate';
import { StorageKeys } from './constants';

const setClientTokens = ({
  clientId,
  clientPhone,
  clientToken,
  clientExpiresIn = 60 * 60 * 24 * 30,
}: {
  clientId: string;
  clientPhone: string;
  clientToken: string;
  clientExpiresIn?: number | undefined;
}) => {
  const expiresDate = Date.now() + clientExpiresIn * 1000;

  localStorage.setItem(StorageKeys.CLIENT_ID_KEY, clientId);
  localStorage.setItem(StorageKeys.CLIENT_TOKEN_KEY, clientToken);
  localStorage.setItem(StorageKeys.CLIENT_EXPIRES_KEY, String(expiresDate));
  localStorage.setItem(StorageKeys.CLIENT_PHONE_KEY, clientPhone);
};

const setUserTokens = ({
  userId,
  userPhone,
  userToken,
  userExpiresIn = 60 * 60 * 24 * 30,
}: {
  userId: string;
  userPhone: string;
  userToken: string;
  userExpiresIn?: number | undefined;
}) => {
  const expiresDate = Date.now() + userExpiresIn * 1000;

  localStorage.setItem(StorageKeys.USER_ID_KEY, userId);
  localStorage.setItem(StorageKeys.USER_TOKEN_KEY, userToken);
  localStorage.setItem(StorageKeys.USER_EXPIRES_KEY, String(expiresDate));
  localStorage.setItem(StorageKeys.USER_PHONE_KEY, userPhone);
};

const removeClientAuthData = () => {
  localStorage.removeItem(StorageKeys.CLIENT_ID_KEY);
  localStorage.removeItem(StorageKeys.CLIENT_TOKEN_KEY);
  localStorage.removeItem(StorageKeys.CLIENT_EXPIRES_KEY);
  localStorage.removeItem(StorageKeys.CLIENT_PHONE_KEY);
};

const removeUserAuthData = () => {
  localStorage.removeItem(StorageKeys.USER_ID_KEY);
  localStorage.removeItem(StorageKeys.USER_TOKEN_KEY);
  localStorage.removeItem(StorageKeys.USER_EXPIRES_KEY);
  localStorage.removeItem(StorageKeys.USER_PHONE_KEY);
};

const getClientAccessToken = () => {
  return localStorage.getItem(StorageKeys.CLIENT_TOKEN_KEY);
};

const getUserAccessToken = () => {
  return localStorage.getItem(StorageKeys.USER_TOKEN_KEY);
};

const getClientExpiresDate = () => {
  return localStorage.getItem(StorageKeys.CLIENT_EXPIRES_KEY);
};

const getUserExpiresDate = () => {
  return localStorage.getItem(StorageKeys.USER_EXPIRES_KEY);
};

const getClientId = () => {
  return localStorage.getItem(StorageKeys.CLIENT_ID_KEY);
};

const getUserId = () => {
  return localStorage.getItem(StorageKeys.USER_ID_KEY);
};

const getClientPhone = () => {
  return localStorage.getItem(StorageKeys.CLIENT_PHONE_KEY);
};

const getUserPhone = () => {
  return localStorage.getItem(StorageKeys.USER_PHONE_KEY);
};

const setClientPhone = (clientPhone: string) => {
  localStorage.setItem(StorageKeys.CLIENT_PHONE_KEY, clientPhone);
};

const getLanguage = () => {
  return localStorage.getItem(StorageKeys.LANGUAGE_KEY);
};

const setLanguage = (language: string) => {
  localStorage.setItem(StorageKeys.LANGUAGE_KEY, language);
};

// Arg format: '2024-05-06'
const setCalendarDate = (date: string) => {
  localStorage.setItem(StorageKeys.CALENDAR_DATE, date);
};

// Return value format: '2024-05-06'
const getCalendarDate = () => {
  const calendarDate = localStorage.getItem(StorageKeys.CALENDAR_DATE);

  if (!calendarDate) {
    const transformCurrentDate = transformDate(new Date());

    localStorage.setItem(StorageKeys.CALENDAR_DATE, transformCurrentDate);

    return transformCurrentDate;
  }

  return calendarDate;
};

const setDisplayedUserIds = (ids: number[]) => {
  localStorage.setItem(StorageKeys.DISPLAYED_USER_IDS, JSON.stringify(ids));
};

const getDisplayedUserIds = (): number[] => {
  const ids = localStorage.getItem(StorageKeys.DISPLAYED_USER_IDS);

  return ids ? JSON.parse(ids) as number[] : [] as number[];
};

const localStorageService = {
  setClientTokens,
  setUserTokens,
  removeClientAuthData,
  removeUserAuthData,
  getClientAccessToken,
  getUserAccessToken,
  getClientId,
  getUserId,
  getClientExpiresDate,
  getUserExpiresDate,
  getClientPhone,
  getUserPhone,
  setClientPhone,
  getLanguage,
  setLanguage,
  setCalendarDate,
  getCalendarDate,
  setDisplayedUserIds,
  getDisplayedUserIds
};

export default localStorageService;
