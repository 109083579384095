import * as Yup from 'yup';
import { FormFields } from './types';

const yupSchema = Yup.object().shape({
  [FormFields.PHONE]: Yup.string()
    .test('phone-check', 'phone must contain 11-12 digits', (value?: string) => {
      if (!value) return true;
      if (value.startsWith('+3')) {
        return /^\+3\d{10}$/.test(value) || /^\+3\d{11}$/.test(value);
      }

      return true;
    })
    .test('phone-check', 'phone must contain 11 digits', (value?: string) => {
      if (!value) return true;
      if (value.startsWith('+7')) {
        return /^\+7\d{10}$/.test(value);
      }

      return true;
    })
    .test('phone-check', "phone must start with '7' or '3'", (value?: string) => {
      if (!value) return true;

      return !(!value.startsWith('+3') && !value.startsWith('+7') && value.length > 1);
    }),
  [FormFields.INSTAGRAM]: Yup.string(),
  [FormFields.TELEGRAM]: Yup.string(),
  [FormFields.NAME]: Yup.string(),
});

export default yupSchema;
