/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import React from 'react';
import { AvailableRecord, RecordType } from 'src/services/records/types';
import { useAppSelector } from 'src/hooks/api';
import { Service } from 'src/services/services/types';
import dictionary from 'src/utils/dictionary/dictionary';
import { useGetClientByIdQuery } from 'src/services/clients/clients.service';
import { Slot } from 'src/reducers/services/types';

interface Props {
  userName: string;
  record: Slot | AvailableRecord;
  selectedService: Service | null
}

export const Record: React.FC<Props> = ({ userName, record, selectedService }) => {
  const selectedLanguage = useAppSelector((state) => state.lang.lang);
  const { data: client } = useGetClientByIdQuery(record.clientId!, {
    skip: !record.clientId
  });
  const recordTime = `${Math.floor(record.start / 60)}:${
    record.start - Math.floor(record.start / 60) * 60 > 9
      ? record.start - Math.floor(record.start / 60) * 60
      : `0${record.start - Math.floor(record.start / 60) * 60}`
  }`;

  switch (record.type) {
    case RecordType.PINK: {
      return dictionary[selectedLanguage].booked;
    }
    case RecordType.GREEN: {
      return record.duration <= 30
        ? (
            recordTime
          )
        : (
          <div>
            <p>
              {selectedService
                ? selectedService[selectedLanguage]
                : dictionary[selectedLanguage].freeSlot}
            </p>
            <p>{recordTime}</p>
            <p>{userName}</p>
          </div>
          );
    }
    case RecordType.BLUE:
    case RecordType.YELLOW: {
      return record.duration <= 30
        ? (
            recordTime
          )
        : (
          <div>
            <p>{record[selectedLanguage as unknown as keyof (Slot | AvailableRecord)]}</p>
            <p>{recordTime}</p>
            <p>{client?.name}</p>
          </div>
          );

      break;
    }
    case RecordType.GRAY: {
      return dictionary[selectedLanguage].dayOff;
    }
  }

  if (!client) {
    return null;
  }
};
