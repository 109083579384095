import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import dictionary from '../utils/dictionary/dictionary';

const NotFoundPage = () => {
  const selectedLanguage = useSelector((state) => state.lang.lang);
  const navigate = useNavigate();

  return (
    <div className="flex h-screen w-screen flex-col items-center justify-center bg-cream">
      <h2 className="text-2xl font-bold text-darkBrown">404</h2>
      <h3 className="mb-[16px] text-darkBrown">
        {dictionary[selectedLanguage].notFound}
      </h3>
      <button
        className="text-darkBrown hover:text-brown"
        onClick={() => { navigate(-1); }}
      >
        {dictionary[selectedLanguage].back}
      </button>{' '}
    </div>
  );
};

export default NotFoundPage;
