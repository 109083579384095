/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppSelector } from 'src/hooks/api';
import dictionary from 'src/utils/dictionary/dictionary';
import { useForm } from 'react-hook-form';
import { Form, FormFields } from './types';
import { Client } from 'src/services/clients/types';
import yupSchema from './schema';
import { useUpdateCurrentClientMutation } from 'src/services/clients/clients.service';
import { toast } from 'react-toastify';

interface Props {
  client?: Client;
  refetch: () => void;
}

const AdmitForm: React.FC<Props> = ({ client, refetch }) => {
  const selectedLanguage = useAppSelector((state) => state.lang.lang);
  const [updateCurrentClient, { isSuccess, isError }] =
    useUpdateCurrentClientMutation();

  const { register, getValues } = useForm<Form>({
    mode: 'onChange',
    defaultValues: {
      [FormFields.NAME]: client?.name ?? '',
      [FormFields.EMAIL]: client?.email ?? null,
      [FormFields.TELEGRAM]: client?.telegram ?? '',
      [FormFields.INSTAGRAM]: client?.instagram ?? '',
      [FormFields.BIRTHDAY]: client?.birthday ?? null,
      [FormFields.COMMUNICATION]: client?.communication ?? true,
    },
    resolver: yupResolver(yupSchema),
  });

  const submit = async () => {
    const formValues = getValues();

    await updateCurrentClient({
      clientId: client?.id,
      name: formValues.name,
      email: formValues.email ?? null,
      telegram: formValues.telegram,
      instagram: formValues.instagram,
      birthday: formValues.birthday ?? null,
      communication: formValues.communication,
    });

    refetch();
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success('Сохранено');
    } else if (isError) {
      toast.error('Ошибка');
    }
  }, [isSuccess, isError]);

  return (
    <div className="md:grid md:grid-cols-2">
      <div className="p-[10px]">
        <label
          className="mb-[8px] block text-lg text-brown max-md:text-sm"
          htmlFor="name"
        >
          {dictionary[selectedLanguage].userName}
        </label>
        <input
          id="name"
          type="text"
          className="w-full rounded-lg border border-lightBrown px-3 py-2 text-lightBrown placeholder:text-lightBrown focus:outline-brown"
          {...register(FormFields.NAME)}
        />
        <label
          className="mb-[8px] block text-lg text-brown max-md:text-sm"
          htmlFor="birthday"
        >
          {dictionary[selectedLanguage].birthday}
        </label>
        <input
          id="birthday"
          type="text"
          onFocus={(e) => (e.target.type = 'date')}
          placeholder="DD.MM.YYYY"
          className="mb-[8px] w-full rounded-lg border border-lightBrown px-[8px] py-[7px] text-lightBrown"
          {...register(FormFields.BIRTHDAY)}
        />
        <button
          className="mx-auto mt-[30px] w-full rounded-lg bg-brown py-[8px] text-center text-white hover:opacity-80 max-md:mt-[22px] max-md:hidden"
          onClick={submit}
        >
          {dictionary[selectedLanguage].confirm}
        </button>
      </div>
      <div className="p-[10px]">
        <label
          className="mb-[8px] block text-lg text-brown max-md:text-sm"
          htmlFor="telegram"
        >
          Telegram
        </label>
        <input
          id="telegram"
          type="text"
          className="mb-[8px] w-full rounded-lg border border-lightBrown px-[8px] py-[7px] text-lightBrown"
          {...register(FormFields.TELEGRAM)}
        />
        <label
          className="mb-[8px] block text-lg text-brown max-md:text-sm"
          htmlFor="instagram"
        >
          Instagram
        </label>
        <input
          id="instagram"
          type="text"
          className="mb-[8px] w-full rounded-lg border border-lightBrown px-[8px] py-[7px] text-lightBrown"
          {...register(FormFields.INSTAGRAM)}
        />
        <label
          className="mb-[8px] block text-lg text-brown max-md:text-sm"
          htmlFor="email"
        >
          E-mail
        </label>
        <input
          id="email"
          type="text"
          className="mb-[8px] w-full rounded-lg border border-lightBrown px-[8px] py-[7px] text-lightBrown"
          {...register(FormFields.EMAIL)}
        />
        <div className="mt-[8px]">
          <label
            className="mr-[6px] text-lg text-darkBrown"
            htmlFor="communication"
          >
            {dictionary[selectedLanguage].communication}
          </label>
          <input
            id="communication"
            type="checkbox"
            className="border-radius-xl border border-lightBrown"
            {...register(FormFields.COMMUNICATION)}
          />
        </div>
        <button
          className="mx-auto mt-[30px] w-full rounded-lg bg-brown py-[8px] text-center text-white hover:opacity-80 max-md:mt-[22px] md:hidden"
          onClick={submit}
        >
          {dictionary[selectedLanguage].confirm}
        </button>
      </div>
    </div>
  );
};

export default AdmitForm;
