export enum FormFields {
  PRICE = 'price',
  DAY = 'day',
  TIME = 'time',
  CAME_FROM = 'cameFrom',
  COMMENT = 'comment',
}

export interface Form {
  [FormFields.PRICE]?: number;
  [FormFields.DAY]?: string;
  [FormFields.TIME]?: string;
  [FormFields.CAME_FROM]?: null | string;
  [FormFields.COMMENT]?: string;
}

export interface Master {
  id: number;
  name?: string;
}
