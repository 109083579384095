import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ClientRecordElement from './records-list/components/client-record-element/clientRecordElement';
import CrmRecordElement from './records-list/components/crm-record-element/CrmRecordElement';
import clientService from '../../services/client.service';
import serviceService from '../../services/service.service';
import dictionary from '../../utils/dictionary/dictionary';
import { useSelector } from 'react-redux';

const RecordsList = ({ filteredRecords, pageType, setReset }) => {
  const selectedLanguage = useSelector((state) => state.lang.lang);
  const [clients, setClients] = useState(null);
  const [services, setServices] = useState(null);
  const loadData = async () => {
    setClients(await clientService.getClients());
    setServices(await serviceService.getServices());
  };

  useEffect(() => {
    loadData();
  }, []);

  const recordsElements =
    pageType === 'client'
      ? (filteredRecords && filteredRecords.length > 0
          ? filteredRecords.map((record) => (
            <ClientRecordElement
              record={record}
              services={services}
              lastEl={filteredRecords.at(-1) === record}
              key={record.id}
            ></ClientRecordElement>
          ))
          : null)
      : (filteredRecords && filteredRecords.length > 0
          ? filteredRecords.map((record) => (
          <CrmRecordElement
            record={record}
            lastEl={filteredRecords.at(-1) === record}
            services={services}
            clients={clients}
            records={filteredRecords}
            key={record.id}
            setReset={setReset}
          ></CrmRecordElement>
          ))
          : null);

  return recordsElements
    ? (
        pageType === 'client'
          ? (
      <div className="max-h-[800px] overflow-y-scroll pt-[32px] text-darkBrown max-md:text-xs">
        <div className="grid grid-cols-4 rounded-t-lg border border-gray bg-lightGray font-bold">
          <div className="px-[20px] py-[12px] max-md:px-[10px] max-md:py-[6px]">
            {dictionary[selectedLanguage].master}
          </div>
          <div className="px-[20px] py-[12px] max-md:px-[10px] max-md:py-[6px]">
            {dictionary[selectedLanguage].service}
          </div>
          <div className="px-[20px] py-[12px] max-md:px-[10px] max-md:py-[6px]">
            {dictionary[selectedLanguage].price}
          </div>
          <div className="px-[20px] py-[12px] max-md:px-[10px] max-md:py-[6px]">
            {dictionary[selectedLanguage].time}
          </div>
        </div>
        {recordsElements}
      </div>
            )
          : (
      <div className="max-h-[800px] overflow-y-scroll pt-[32px] text-darkBrown max-md:text-xs">
        <div className="grid grid-cols-5 rounded-t-lg border border-gray bg-lightGray font-bold">
          <div className="px-[20px] py-[12px] max-md:px-[10px] max-md:py-[6px]">
            {dictionary[selectedLanguage].client}
          </div>
          <div className="px-[20px] py-[12px] max-md:px-[10px] max-md:py-[6px]">
            {dictionary[selectedLanguage].service}
          </div>
          <div className="px-[20px] py-[12px] max-md:px-[10px] max-md:py-[6px]">
            {dictionary[selectedLanguage].price}
          </div>
          <div className="px-[20px] py-[12px] max-md:px-[10px] max-md:py-[6px]">
            {dictionary[selectedLanguage].time}
          </div>
          <div className="px-[20px] py-[12px] max-md:px-[10px] max-md:py-[6px]">
            {dictionary[selectedLanguage].status}
          </div>
        </div>
        {recordsElements}
      </div>
            )
      )
    : (
    <div className="pt-[32px] text-darkBrown">
      {dictionary[selectedLanguage].noData}
    </div>
      );
};

RecordsList.propTypes = {
  filteredRecords: PropTypes.array,
  pageType: PropTypes.string.isRequired,
  setReset: PropTypes.func
};

export default RecordsList;
