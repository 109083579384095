import { Slot } from 'src/reducers/services/types';
import { AvailableRecord, RecordType } from 'src/services/records/types';
import { Service } from 'src/services/services/types';

const fillDayWithAvailableSlots = (
  selectedService: Service | null,
  userRecords: AvailableRecord[] | undefined,
  boardDayDate: string,
  userId: number,
  selectedSlots?: Slot[],
) => {
  const existingRecordsWithSlots: (AvailableRecord | Slot)[] = structuredClone(userRecords) ?? [];

  if (selectedSlots) {
    selectedSlots.forEach((slot) => {
      if (slot.date === boardDayDate && slot.userId === userId) {
        existingRecordsWithSlots.push({
          ...slot,
          type: RecordType.PINK
        });
      }
    });
  }

  for (
    let start = 540;
    start + Number(selectedService?.duration) <= 1320;
    start += 10
  ) {
    let conflict = false;

    for (const record of existingRecordsWithSlots) {
      if (
        (record.start <= start &&
        start < record.end &&
        record.type !== RecordType.GREEN) || (
          record.start < start + Number(selectedService?.duration) &&
          start + Number(selectedService?.duration) < record.end &&
          record.type !== RecordType.GREEN
        ) || (
          start < record.start &&
          record.start < start + Number(selectedService?.duration) &&
          record.type !== RecordType.GREEN
        ) || (start % 30 > 0)
      ) {
        conflict = true;
      }
    }

    if (!conflict) {
      existingRecordsWithSlots.push({
        start,
        end: start + Number(selectedService?.duration),
        duration: Number(selectedService?.duration),
        type: RecordType.GREEN,
        date: boardDayDate,
        top: start - 480,
      });
    }
  }

  return existingRecordsWithSlots;
};

export default fillDayWithAvailableSlots;
