import React, { useEffect, useState } from 'react';
import ContainerBox from '../components/ui/containerBox';
import TextField from '../components/ui/text-field/textField';
import userService from '../services/user.service';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import localStorageService from '../services/local-storage/localStorage.service';
import { useNavigate } from 'react-router-dom';
import dictionary from '../utils/dictionary/dictionary';
import { useSelector } from 'react-redux';

const CrmProfilePage = () => {
  const selectedLanguage = useSelector((state) => state.lang.lang);
  const notify = () => toast.success(dictionary[selectedLanguage].success);
  const navigate = useNavigate();
  const userId = localStorageService.getUserId();

  if (!userId) {
    navigate('/crm/login');
  }
  const windowWidth = window.innerWidth;

  const [user, setUser] = useState(null);
  const [selectedImage, setSelectedImage] = useState();
  const [password, setPassword] = useState('');
  const [selectedPreview, setSelectedPreview] = useState(user?.image);
  const [data, setData] = useState({
    name: user ? user.name : '',
    position: user ? user.email : '',
    experience: user ? user.telegram : '',
    birthday: user ? user.birthdate : null,
    telegram: user ? user.telegram : null
  });

  const loadData = async (userId, user) => {
    setUser(await userService.getUserById(userId));
  };

  useEffect(() => {
    loadData(userId);
  }, [userId]);

  useEffect(() => {
    setSelectedPreview(user?.image);
    setData({
      name: user?.name,
      position: user?.position,
      experience: user?.experience,
      birthday: user?.birthdate,
      telegram: user?.telegram
    });
  }, [user]);

  const handleChange = (target) => {
    setData((previousState) => ({
      ...previousState,
      [target.name]: target.value
    }));
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);

    setSelectedPreview(url);
    setSelectedImage(file);
  };

  const handleSubmit = async () => {
    const userId = user.id;

    await userService.UpdateCurrentUser({
      userId,
      name: data.name,
      position: data.position,
      experience: data.experience,
      birthday: data.birthday,
      password,
      telegram: data.telegram
    });
    if (selectedImage) {
      const formData = new FormData();

      formData.append('image', selectedImage);
      await userService.UploadUserImage(userId, formData);
    }
    notify();
  };

  if (user) {
    if (user.isStaff || user.isAdmin) {
      return (
        <div className="container-fluid relative mx-auto flex h-[calc(100vh-252px)] items-start justify-center bg-cream max-md:text-sm">
          <ContainerBox>
            <div className="flex justify-between">
              <div className="max-md:flex max-md:w-full max-md:flex-row-reverse max-md:items-center max-md:justify-between">
                <h2 className="text-2xl text-darkBrown max-md:mr-[6px] max-md:text-lg">
                  {dictionary[selectedLanguage].yourProfile}
                </h2>
                <p className="mb-[24px] text-brown max-md:mb-0 max-md:text-lg max-md:text-darkBrown">
                  {user ? user.phone : ''}
                </p>
              </div>
            </div>

            {user
              ? (
              <div className="mx-auto w-full md:flex md:flex-col">
                <p className="mb-[24px] text-xl text-brown max-md:mb-0 max-md:text-lg">
                  {dictionary[selectedLanguage].profileEdit}
                </p>
                <div className="md:grid md:grid-cols-3">
                  <div className="p-[10px] max-md:py-0">
                    <TextField
                      name={'name'}
                      type={'text'}
                      placeholder={dictionary[selectedLanguage].yourName}
                      label={dictionary[selectedLanguage].userName}
                      value={data.name}
                      onChange={handleChange}
                    ></TextField>
                    <label
                      className="mb-[8px] block text-lg text-brown max-md:text-sm"
                      htmlFor={'birthday'}
                    >
                      {dictionary[selectedLanguage].birthday}
                    </label>
                    <input
                      type="date"
                      name="birthday"
                      value={data.birthday ?? user?.birthdate}
                      onChange={(e) => {
                        setData((previousState) => ({
                          ...previousState,
                          birthday: e.target.value
                        }));
                      }
                      }
                      className="w-full rounded-lg border border-lightBrown px-[8px] py-[7px] text-lightBrown"
                    />
                    <label
                      className="mb-[8px] block text-lg text-brown max-md:text-sm"
                      htmlFor={'password'}
                    >
                      {dictionary[selectedLanguage].password}
                    </label>
                    <input
                      type="password"
                      name="password"
                      onChange={(e) => { setPassword(e.target.value); }}
                      className="mb-[8px] w-full rounded-lg border border-lightBrown px-[8px] py-[7px] text-lightBrown"
                    />
                  </div>
                  <div className="p-[10px]">
                    <TextField
                      name={'position'}
                      type={'text'}
                      placeholder={dictionary[selectedLanguage].master}
                      label={dictionary[selectedLanguage].position}
                      value={data.position}
                      onChange={handleChange}
                    ></TextField>
                    <TextField
                      name={'experience'}
                      type={'text'}
                      placeholder={'1'}
                      label={dictionary[selectedLanguage].experience}
                      value={data.experience}
                      onChange={handleChange}
                    ></TextField>
                    <TextField
                      name={'telegram'}
                      type={'text'}
                      placeholder={''}
                      label="Telegram"
                      value={data.telegram}
                      onChange={handleChange}
                    ></TextField>
                  </div>
                  <div className="p-[10px]">
                    <img
                      src={selectedPreview}
                      alt=""
                      className="mx-auto size-[155px] rounded-s"
                    />
                    <label
                      className="mb-[8px] block text-lg text-brown"
                      htmlFor={'file'}
                    >
                      {'Фото'}
                    </label>
                    <input
                      className="block w-full cursor-pointer rounded-lg border border-lightBrown bg-white px-3 py-2 text-sm text-lightBrown file:cursor-pointer file:rounded-lg file:border file:border-lightBrown file:bg-cream file:text-brown file:outline-none placeholder:text-lightBrown file:hover:opacity-80 focus:outline-none"
                      id={'file'}
                      name={'file'}
                      type={'file'}
                      accept={windowWidth < 800 ? 'image/*, file/*' : 'image/*'}
                      placeholder={''}
                      onChange={handleImageChange}
                    />
                  </div>
                </div>

                <button
                  className="mx-auto mt-[24px] w-[25%] rounded-lg bg-brown py-[8px] text-center text-white hover:opacity-80"
                  onClick={handleSubmit}
                >
                  {dictionary[selectedLanguage].confirm}
                </button>
              </div>
                )
              : (
                  'loading'
                )}
          </ContainerBox>
          <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </div>
      );
    } else {
      navigate('/crm/login');
    }
  }
};

export default CrmProfilePage;
