import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ContainerBox from '../components/ui/containerBox';
import Calendar from '../components/ui/calendar/calendar';
import UserPageHeader from '../components/master/masterPageHeader';
import UserPageRecords from '../components/master/masterPageRecords';
import UserPageComments from '../components/master/masterPageComments';
import userService from '../services/user.service';

const MasterPage = () => {
  const { userId } = useParams();
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [user, setUser] = useState([]);
  const loadData = async (userId) => {
    setUser(await userService.getUserById(userId));
  };

  useEffect(() => {
    loadData(userId);
  }, [userId]);

  let date = useSelector((state) => state.date.date).split('.');

  date = `${date[2]}-${Number(date[1]) > 9 ? date[1] : '0' + Number(date[1])}-${
    Number(date[0]) > 9 ? date[0] : '0' + Number(date[0])
  }`;

  return (
    <div className="container-fluid relative mx-auto flex h-full items-start justify-center bg-cream text-lightBrown max-md:text-sm">
      <ContainerBox>
        <div className="my-[10px]">
        <Calendar currentDate={calendarDate} onSelectDate={setCalendarDate} />
        </div>
        <UserPageHeader user={user} />
        <UserPageRecords userId={user.id} date={date} />
        <UserPageComments userId={user.id} />
      </ContainerBox>
    </div>
  );
};

export default MasterPage;
