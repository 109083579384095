/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import 'src/index.css';
import RecordsList, {
  Page,
} from 'src/components/home/records-list/recordsList';
import React, { useEffect, useMemo, useState } from 'react';
import ContainerBox from 'src/components/ui/containerBox';
import Calendar from 'src/components/ui/calendar/calendar';
import localStorageService from 'src/services/local-storage/localStorage.service';
import { useNavigate } from 'react-router-dom';
import dictionary from '../../utils/dictionary/dictionary';
import { useAppSelector } from 'src/hooks/api';
import { changeDateType, transformDate } from 'src/utils/date/transformDate';
import { useGetUserByIdQuery } from 'src/services/users/users.services';
import { Columns } from './constants';
import { useGetRecordsByDateQuery } from 'src/services/records/records.service';
import { getColumns, getRecordsByStatus } from './helpers';
import AdminButtons from './components/admin-buttons/adminButtons';
import { RecordApi } from 'src/services/records/types';
import { ButtonStatus } from 'src/components/calendar/common/detailed-record-info/components/status-button/constants';

const CrmMainPage: React.FC = () => {
  const selectedLanguage = useAppSelector((state) => state.lang.lang);
  const navigate = useNavigate();
  const dateState = useAppSelector((state) => state.date.date);
  const date = changeDateType(dateState);
  const userId = localStorageService.getUserId();
  const { data: recordsData, refetch: refetchRecords } =
    useGetRecordsByDateQuery(date);
  const { data: user, refetch: refetchUsers } = useGetUserByIdQuery(
    Number(userId),
  );
  const [calendarDate, setCalendarDate] = useState(new Date(localStorageService.getCalendarDate()));
  const [isUserStatus, setIsUserStatus] = useState(true);
  const [records, setRecords] = useState<RecordApi[]>([]);
  const [recordTypeStatus, setRecordTypeStatus] = useState<Columns>(
    Columns.CREATED,
  );

  useEffect(() => {
    if (recordsData) {
      setRecords(recordsData);
    }
  }, [recordsData]);

  const recordsByRecordStatus = useMemo(() => {
    return getRecordsByStatus(recordTypeStatus, records);
  }, [recordTypeStatus, records]);

  const userRecords = useMemo(() => {
    return (user?.isAdmin && user?.isStaff && isUserStatus) || !user?.isAdmin
      ? recordsByRecordStatus.filter(
        (record) => record.userId === Number(userId),
      )
      : recordsByRecordStatus;
  }, [
    recordsByRecordStatus,
    user?.isAdmin,
    user?.isStaff,
    userId,
    isUserStatus,
  ]);

  const updateRecords = (index: number) => {
    setRecords((prevState) => {
      return prevState.map((record, recordIndex) => {
        if (recordIndex === index) {
          return { ...record, status: ButtonStatus.COMPLETED };
        }

        return record;
      });
    });
  };

  const setDate = (date: Date) => {
    setCalendarDate(date);
    localStorageService.setCalendarDate(transformDate(date));
  };

  if (!userId) {
    navigate('/crm/login');
  }

  if (!user) {
    return null;
  }

  if (user.isStaff || user.isAdmin) {
    return (
      <div className="relative mx-auto flex h-[calc(100vh-252px)] items-start justify-center bg-cream ">
        <ContainerBox>
          <h2 className="text-2xl text-brown max-md:text-lg">
            {dictionary[selectedLanguage].serviceList}
          </h2>
          <Calendar currentDate={calendarDate} onSelectDate={setDate} />
          <div className="flex items-center justify-center max-md:flex-col max-md:text-xs">
            <AdminButtons
              isVisible={user.isAdmin && user.isStaff}
              isUserStatus={isUserStatus}
              onSelectMine={() => {
                setIsUserStatus(true);
              }}
              onSelectAll={() => {
                setIsUserStatus(false);
              }}
            />
            <div className="flex items-center justify-center">
              {getColumns(selectedLanguage).map((option) => (
                <span
                  key={option.status}
                  className={`mr-[5px] cursor-pointer rounded-lg border border-darkBrown px-[8px] py-[4px] text-darkBrown ${
                    recordTypeStatus === option.status ? 'bg-cream' : ''
                  }`}
                  onClick={() => setRecordTypeStatus(option.status)}
                >
                  {option.label}
                </span>
              ))}
            </div>
          </div>
          <RecordsList
            records={userRecords}
            pageType={Page.USER}
            updateRecords={updateRecords}
            updateData={() => {
              refetchRecords();
              refetchUsers();
            }}
          />
        </ContainerBox>
      </div>
    );
  } else {
    navigate('/crm/login');
  }
};

export default CrmMainPage;
