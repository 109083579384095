import React from 'react';
import PropTypes from 'prop-types';

export default function AuthImage ({ img, middle }) {
  return (
    <img
      className={
        middle
          ? 'w-100 h-100 middle-auth-img overflow-hidden rounded-full opacity-50'
          : 'w-100 h-100 middle-auth-img mt-[500px] overflow-hidden rounded-full opacity-50'
      }
      src={img}
      alt=""
    />
  );
}

AuthImage.propTypes = {
  img: PropTypes.string.isRequired,
  position: PropTypes.number
};
