import PropTypes from 'prop-types';
import starFull from '../../assets/imgs/Star 6.png';
import _ from 'lodash';
import dictionary from '../../utils/dictionary/dictionary';
import { useSelector } from 'react-redux';

const MasterPageCommentElement = ({ comment }) => {
  const selectedLanguage = useSelector((state) => state.lang.lang);

  return (
    <>
      <div className="mb-[16px] flex w-full flex-col rounded-lg border border-gray px-[16px] py-[12px] text-brown">
        <p className="mb-[16px] text-darkBrown">{`Номер - №${comment.recordId}`}</p>
        <div className="flex">
          <img
            className="mr-[6px] size-[50px] rounded-full"
            src={comment.authorImage}
            alt=""
          />
          <div>
            <p className="mb-[6px] text-darkBrown">
              {comment.authorName}
              {comment.authorType === 'user'
                ? ` (${dictionary[selectedLanguage].staff})`
                : ` (${dictionary[selectedLanguage].client})`}
            </p>
            <p className="mb-[16px]">{comment.content}</p>
          </div>
        </div>
        {comment.image
          ? (
          <a href={comment.image} target="_blank" rel="noopener noreferrer">
            {dictionary[selectedLanguage].photo}
          </a>
            )
          : null}
        <div className="flex items-center justify-end ">
          {_.range(Number(comment.rating)).map((x) => (
            <img className="mb-[3px] size-[20px]" src={starFull} alt="" />
          ))}
          <p className="ml-[16px] text-xs">{comment.createdAt}</p>
        </div>
      </div>
    </>
  );
};

MasterPageCommentElement.propTypes = {
  comment: PropTypes.object
};
export default MasterPageCommentElement;
