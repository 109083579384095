import { ButtonStatus } from 'src/components/calendar/common/detailed-record-info/components/status-button/constants';
import { User } from '../users/types';

export interface RecordApi {
  id: number;
  date: string;
  time: string;
  clientId: number;
  status: string;
  price: number;
  ru: string;
  en: string;
  sr: string;
  comment: string | null;
  image: string | null;
  serviceId: number;
  service: Service;
  history: Record<string, string>;
  userId: number;
  user: User,
  author: string;
}

interface Service {
  id: number;
  ru: string;
  en: string;
  sr: string;
  price: number;
  duration: number;
  active: boolean;
}

export interface AvailableRecord {
  start: number;
  end: number;
  duration: number;
  type: RecordType;
  serviceId: number;
  ru: string;
  en: string;
  sr: string;
  clientId: number;
  recordId: number;
  top?: number;
}

export enum RecordType {
  PINK = 'pink',
  GREEN = 'green',
  BLUE = 'blue',
  YELLOW = 'yellow',
  GRAY = 'gray',
}

export interface NewRecord {
  clientId: number;
  date?: string;
  time?: number;
  author?: string,
  userId?: number;
  serviceId?: number;
}

export type NewRecordWithRegister = Omit<NewRecord, 'clientId'> & {
  phone: string;
  instagram: string;
  telegram: string;
  name: string;
  authorId: number;
}

export interface NewComplex {
  records: ComplexRecord[];
  author?: string;
  clientId?: number;
}

export interface NewComplexWithRegister {
  records: ComplexRecord[];
  author?: string;
  phone: string;
  instagram: string;
  telegram: string;
  name: string;
  authorId?: number,
}

interface ComplexRecord {
  userId?: number;
  serviceId?: number;
  date: string;
  time: number;
}

export interface UpdateRecord {
  recordId?: number;
  price: number;
  comment: string;
  status?: ButtonStatus;
  time?: string;
  date?: string;
  userId?: number;
  masterId?: number;
  cameFrom: string | null;
}

export interface UpdateImage {
  recordId: number;
  formData: FormData;
}
