import { configureStore } from '@reduxjs/toolkit';
import { rootReducer } from 'src/reducers/reducers';
import { clientsApi } from 'src/services/clients/clients.service';
import { commentsApi } from 'src/services/comments/comments.service';
import { recordsApi } from 'src/services/records/records.service';
import { servicesApi } from 'src/services/services/services.service';
import { usersApi } from 'src/services/users/users.services';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([servicesApi.middleware,
    usersApi.middleware, recordsApi.middleware, clientsApi.middleware, commentsApi.middleware])
});

export default store;
