import * as Yup from 'yup';
import { FormFields } from './types';

const yupSchema = Yup.object().shape({
  [FormFields.PRICE]: Yup.number(),
  [FormFields.DAY]: Yup.string(),
  [FormFields.TIME]: Yup.string(),
  [FormFields.COMMENT]: Yup.string(),
  [FormFields.CAME_FROM]: Yup.string().nullable(),
});

export default yupSchema;
