import React, { useState } from 'react';
import { useAppSelector } from 'src/hooks/api';
import dictionary from 'src/utils/dictionary/dictionary';
import { StaffModal } from './components/staff-modal/staffModal';
import { User } from 'src/services/users/types';

interface Props {
  setUsers: (users: User[]) => void;
}

export const Filter: React.FC<Props> = ({ setUsers }) => {
  const selectedLanguage = useAppSelector((state) => state.lang.lang);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isStaffModalVisible, setIsStaffModalVisible] = useState(false);

  return (
    <>
      <div
        className="flex px-4"
        onMouseEnter={() => setIsMenuVisible(true)}
        onMouseLeave={() => setIsMenuVisible(false)}
      >
        <div
          id="1"
          className="relative cursor-pointer rounded-md border-2 px-6"
        >
          {dictionary[selectedLanguage].filter}
          {isMenuVisible
            ? (
            <div className="absolute inset-x-0 -bottom-px z-10 translate-y-full cursor-pointer rounded-b-md border bg-white p-3">
              <p className="rounded-md p-2 hover:bg-lightCream" onClick={() => setIsStaffModalVisible(true)}>Люди</p>
            </div>
              )
            : null}
        </div>
      </div>
      <StaffModal
        isVisible={isStaffModalVisible}
        setUsers={setUsers}
        onClose={() => setIsStaffModalVisible(false)}
      />
    </>
  );
};
