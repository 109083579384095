import React, { PropsWithChildren } from 'react';
import dropdownArrow from 'src/assets/imgs/dropdown-arrow.png';
import SearchBar from './components/search-bar/searchBar';
import { useAppSelector } from 'src/hooks/api';

interface Props {
  search: string;
  isServiceTypeSelect: boolean;
  isSearchVisible: boolean;
  setIsSearchVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onChevronBarClick: () => void;
  onChange: (value: string) => void;
}

export const ServiceSelectionList: React.FC<PropsWithChildren<Props>> = ({
  children,
  search,
  isServiceTypeSelect,
  isSearchVisible,
  setIsSearchVisible,
  onChevronBarClick,
  onChange,
}) => {
  const selectedLanguage = useAppSelector((state) => state.lang.lang);
  const { selectedService, selectedComplex } = useAppSelector(
    (state) => state.services,
  );

  return (
    <div className="relative mb-[16px] flex cursor-pointer items-center justify-between rounded-lg border border-lightBrown px-[16px] py-[14px] text-lightBrown">
      <div
        className={'flex w-full items-center justify-between'}
        onClick={() => setIsSearchVisible((previousState) => !previousState)}
      >
        <span className="hover:opacity-80">
          {selectedComplex
            ? selectedComplex[selectedLanguage]
            : selectedService?.[selectedLanguage]}
        </span>
        <img
          className={isSearchVisible ? 'size-[16px] rotate-180' : 'size-[16px]'}
          src={dropdownArrow}
          alt=""
        />
      </div>
      {isSearchVisible
        ? (
        <SearchBar
          value={search}
          isServiceTypeSelect={isServiceTypeSelect}
          onChevronBarClick={onChevronBarClick}
          onChange={onChange}
        >
          {children}
        </SearchBar>
          )
        : null}
    </div>
  );
};
