export enum FormFields {
  PRICE = 'price',
  COMMENT = 'comment',
  STATUS = 'status',
  CAME_FROM = 'cameFrom',
}

export interface Form {
  [FormFields.PRICE]?: string;
  [FormFields.COMMENT]?: string;
  [FormFields.STATUS]?: string;
  [FormFields.CAME_FROM]?: string | null;
}

export interface FormState {
  [FormFields.PRICE]: string;
  [FormFields.COMMENT]: string;
  [FormFields.STATUS]: string;
  [FormFields.CAME_FROM]: string | null;
}
